import React, { forwardRef } from "react";
import { isDevelopmentBuild } from "src/utils";

import { BoxBasedComponentProps } from "../Box";
import { Flex } from "../Flex";

type TagSize = "sm" | "md" | "lg";

type TagProps = BoxBasedComponentProps<
  "span",
  {
    size?: TagSize;
  },
  "as"
>;

export const Tag = forwardRef<HTMLSpanElement, TagProps>(
  ({ size = "md", ...props }, ref) => {
    const sizeStyles = sizes[size];

    return (
      <Flex
        color={"neutral.500"}
        bg={"primary.100"}
        {...props}
        {...sizeStyles}
        ref={ref}
        as={"span"}
        alignItems={"center"}
        borderRadius={"round"}
      />
    );
  }
);

if (isDevelopmentBuild) {
  Tag.displayName = "Tag";
}

const sizes = {
  sm: {
    minHeight: "1.25rem",
    minWidth: "1.25rem",
    textStyle: "body-small-light",
    px: 2
  },
  md: {
    minHeight: "1.5rem",
    minWidth: "1.5rem",
    textStyle: "body-small-light",
    px: 2
  },
  lg: {
    minHeight: 8,
    minWidth: 8,
    textStyle: "body-large-light",
    px: 3
  }
} as Record<TagSize, TagProps>;
