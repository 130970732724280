import React, { useState } from "react";
import Flex from "components/flex";
import Box from "components/box";
import LoadingIndicator from "components/loading-indicator";
import Panel from "components/panel";
import MobileTabs from "components/mobile-tabs";
import { ViewMode } from "utils/view-mode";
import { UserRole } from "utils/user-role";
import AgencyProfileTab from "components/agency-profile-tab";
import AgencyInfo from "components/agency-info";
import styled from "styled-components";
import { height, padding } from "styled-system";
import ProfileHeader from "components/agency-profile-header";
import ProfileTitle from "components/agency-profile-title";
import AgencyFeedContainer from "src/containers/agency-feed";
import AgencyQuarterlyReport from "components/agency-quarterly-report";
import MessageBar from "components/message-bar";

const ContentTabs = ({ isMobileScreen, selectedTab, setSelectedTab }) => {
  if (!isMobileScreen) {
    return <></>;
  }

  const StyledMobileTabs = styled(Flex)`
    align-items: center;
    border-bottom: ${props => props.theme.borders[1]};
    display: flex;
    flex-shrink: 0;
    ${height}
    ${padding}
  `;

  return (
    <Flex width={"100%"} height={"48px"} backgroundColor={"white"}>
      <StyledMobileTabs width={1} flexDirection={"row"} height={"48px"}>
        <AgencyProfileTab
          className={selectedTab === "posts" ? "tab-active" : ""}
          title={"Posts"}
          onClick={() => setSelectedTab("posts")}
        />
        <AgencyProfileTab
          className={selectedTab === "agency-info" ? "tab-active" : ""}
          title={"Agency Info"}
          onClick={() => setSelectedTab("agency-info")}
        />
      </StyledMobileTabs>
    </Flex>
  );
};

const ProfileContent = ({
  id,
  isMobileScreen,
  agencyData,
  config,
  quarterlySummary,
  quarterlySummaryPeriod
}) => {
  const [selectedTab, setSelectedTab] = useState("posts");
  let postsContainer = (
    <Flex
      flexDirection={"column"}
      width={{ sm: "100%", md: "100%", lg: "503px", xl: "639px" }}
      paddingLeft={{ sm: "24px", lg: 0 }}
      paddingRight={{ sm: "24px", lg: 0 }}
      backgroundColor={"unset"}
    >
      <AgencyFeedContainer id={id} />
    </Flex>
  );
  let agencyInfo = (
    <Flex
      flexDirection={"column"}
      width={{ sm: "100%", md: "100%", lg: "417px" }}
      paddingLeft={{ sm: "24px", md: "24px", lg: 0 }}
      paddingRight={{ sm: "24px", lg: 0 }}
      backgroundColor={"unset"}
      marginLeft={{ sm: 0, lg: "24px" }}
    >
      <AgencyInfo
        agencyData={agencyData}
        isMobileScreen={isMobileScreen}
        config={config}
      />
      <AgencyQuarterlyReport
        agencyData={agencyData}
        isMobileScreen={isMobileScreen}
        config={config}
        quarterlySummary={quarterlySummary}
        quarterlySummaryPeriod={quarterlySummaryPeriod}
      />
    </Flex>
  );

  if (isMobileScreen) {
    postsContainer = selectedTab === "posts" ? postsContainer : null;
    agencyInfo = selectedTab === "agency-info" ? agencyInfo : null;
  }

  return (
    <>
      <ContentTabs
        isMobileScreen={isMobileScreen}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Box
        width={"100%"}
        height={"200px"}
        backgroundColor={"lightGrey"}
        borderTopColor={"offWhite"}
        borderTopWidth={"1px"}
        borderTopStyle={"solid"}
      >
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          backgroundColor={"lightGrey"}
          width={"100%"}
          margin={{ sm: 0, lg: "0 auto" }}
          marginLeft={{ sm: 0, lg: "auto" }}
          marginRight={{ sm: 0, lg: "auto" }}
          maxWidth={{ sm: "unset", lg: "944px", xl: "1080px" }}
          paddingTop={"24px"}
        >
          {postsContainer}
          {agencyInfo}
        </Flex>
      </Box>
    </>
  );
};

const AgencyProfile = ({
  id,
  agencyType,
  isMobileScreen,
  agencyData,
  name,
  config,
  quarterlySummary,
  quarterlySummaryPeriod
}) => {
  const [displayMessageBar, setDisplayMessageBar] = useState(false);
  return (
    <Flex flexDirection={"column"} width={"100%"}>
      <MessageBar
        message={"Link Copied"}
        active={displayMessageBar}
        closeAction={() => setDisplayMessageBar(false)}
      />
      <ProfileHeader agencyType={agencyType} />
      <ProfileTitle name={name} setDisplayMessageBar={setDisplayMessageBar} />
      <ProfileContent
        id={id}
        isMobileScreen={isMobileScreen}
        agencyData={agencyData}
        config={config}
        quarterlySummary={quarterlySummary}
        quarterlySummaryPeriod={quarterlySummaryPeriod}
      />
    </Flex>
  );
};

const Agency = ({
  id,
  agencyData,
  children,
  createdAt,
  isLoading,
  name,
  isMobileScreen,
  currentUser,
  viewMode,
  config,
  onHandleRequestPage,
  quarterlySummary,
  quarterlySummaryPeriod
}) => {
  if (config.ENABLE_NEW_AGENCY_PROFILE === "true") {
    const handleScroll = ({ target: { scrollHeight, scrollTop } }) => {
      const PREFETCH_THRESHOLD = 2000; // pixels before reaching the end that will trigger nextPage()
      if (scrollTop >= scrollHeight - PREFETCH_THRESHOLD) {
        onHandleRequestPage();
      }
    };

    return (
      <Flex
        width={"100%"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        flexGrow={1}
        minHeight={0}
        backgroundColor={"lightGrey"}
        overflow={"auto"}
        onScroll={handleScroll}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <AgencyProfile
            id={id}
            config={config}
            isMobileScreen={isMobileScreen}
            agencyData={agencyData}
            name={name}
            agencyType={agencyData.agencyType}
            quarterlySummary={quarterlySummary}
            quarterlySummaryPeriod={quarterlySummaryPeriod}
          />
        )}
      </Flex>
    );
  }

  const showPanelNavigation = isMobileScreen => {
    return !isMobileScreen;
  };

  const showTabNavigation = isMobileScreen => {
    return isMobileScreen;
  };

  const showUsageReportNavigation =
    currentUser &&
    currentUser.role === UserRole.ADMIN &&
    viewMode === ViewMode.ADMIN;

  return (
    <Flex
      width={"100%"}
      flexDirection={{ sm: "column", md: "row" }}
      justifyContent={"flex-start"}
      flexGrow={1}
      minHeight={0}
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Panel
            createdAt={createdAt}
            name={name}
            showNavigation={showPanelNavigation(isMobileScreen)}
            agencyData={agencyData}
            showUsageReportNavigation={showUsageReportNavigation}
          />
          {showTabNavigation(isMobileScreen) && (
            <MobileTabs
              agencyUuid={agencyData.uuid}
              agencyType={agencyData.agencyType}
              showUsageReportNavigation={showUsageReportNavigation}
            />
          )}
          {children}
        </>
      )}
    </Flex>
  );
};

export default Agency;
