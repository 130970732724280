import moment from "moment";
import Flex from "components/flex";
import Text from "components/text";
import React from "react";
import Card from "src/components/card";
import Link from "components/link";

const AgencyUsageReports = ({ configVars, agency, reports }) => {
  let mostRecentUsageReport = null;
  let pastUsageReports = [];

  if (reports.length > 0) {
    mostRecentUsageReport = reports[0];
  }

  if (reports.length > 1) {
    pastUsageReports = reports.slice(1);
  }

  return (
    <Flex
      bg={{ sm: "white", md: "lightGrey" }}
      flexBasis={1}
      flexDirection={"column"}
      flexGrow={1}
      height={"100%"}
      overflow={"auto"}
      px={{ sm: 0, md: "40px" }}
      py={{ sm: 0, md: "24px" }}
      width={{ sm: 1, md: "auto" }}
    >
      {reports.length === 0 ? (
        <Flex bg={"transparent"} ml={{ sm: 3, md: 0 }} mt={{ mt: 3, md: 0 }}>
          <Text
            color={"neutral500"}
          >{`There are no usage reports available.`}</Text>
        </Flex>
      ) : null}
      {mostRecentUsageReport ? (
        <>
          <Flex
            bg={"transparent"}
            borderBottom={{ sm: 1, md: 0 }}
            pt={"24px"}
            px={{ sm: "24px", md: 0 }}
            width={1}
          >
            <Text color={"neutral300"} fontSize={1} fontWeight={2} mb={2}>
              {`Most Recent Usage Report`}
            </Text>
          </Flex>
          <UsageReport
            configVars={configVars}
            agency={agency}
            report={mostRecentUsageReport}
          />
        </>
      ) : null}
      {pastUsageReports.length ? (
        <>
          <Flex
            bg={"transparent"}
            borderBottom={{ sm: 1, md: 0 }}
            pt={"24px"}
            px={{ sm: "24px", md: 0 }}
            width={1}
          >
            <Text color={"neutral300"} fontSize={1} fontWeight={2} mb={2}>
              {`Past Usage Reports`}
            </Text>
          </Flex>
        </>
      ) : null}
      {pastUsageReports.map((report, i) => (
        <UsageReport
          key={`report-${i}`}
          configVars={configVars}
          agency={agency}
          report={report}
        />
      ))}
    </Flex>
  );
};

const UsageReportDownloadLink = ({ configVars, agency, report }) => {
  return (
    <Text lineHeight={"24px"}>
      <Link
        to={`${configVars.NPSS_API_ORIGIN}/v2/agencies/${agency.uuid}/reports/${report.report_uuid}/download`}
        download={true}
        fontSize={1}
        color={"primary400"}
      >
        {"Download"}
      </Link>
    </Text>
  );
};

const UsageReport = ({ configVars, agency, report }) => {
  const startDate = moment(report.period.start_date).utc();
  const endDate = moment(report.period.end_date).utc();

  const action = (
    <UsageReportDownloadLink
      configVars={configVars}
      agency={agency}
      report={report}
    />
  );

  return (
    <Card title={`${startDate.format("MMMM Y")}`} action={action}>
      <Text color={"neutral300"} fontSize={1} lineHeight={"24px"}>
        {`${startDate.format("M/D")}`}
        {` - `}
        {`${endDate.format("M/D")}`}
      </Text>
    </Card>
  );
};

export default AgencyUsageReports;
