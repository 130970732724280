import React, { useMemo } from "react";

import { Box, BoxProps } from "../Box";
import {
  EventCardBody,
  EventCardBodyProps,
  EventCardDescriptionProps,
  EventCardSolved,
  useEventCardState
} from "../EventCard";
import { Link, linkStyles } from "../Link";

type FeedEventCardBodyProps = Omit<
  EventCardBodyProps,
  "px" | "titleProps" | "descriptionProps"
> & {
  readMoreDisabled?: boolean;
};

export const FeedEventCardBody = (props: FeedEventCardBodyProps) => {
  const {
    event: { is_resolved, id }
  } = useEventCardState();

  const descriptionProps = useMemo<EventCardDescriptionProps>(
    () => ({
      mb: 4,
      clamp: 3,
      ellipsis: (
        <Box display={"inline"}>
          {"..."}
          {props.readMoreDisabled ? (
            <Box {...linkStyles} textStyle={"textlink-large-medium"}>
              {" "}
              {"Read More"}
            </Box>
          ) : (
            <Link
              textStyle={"textlink-large-medium"}
              href={`/event/${id}`}
              ml={1}
            >
              {"Read More"}
            </Link>
          )}
        </Box>
      ),
      textStyle: "body-normal-light"
    }),
    [id]
  );

  const { infoProps } = props;

  return (
    <EventCardBody
      {...props}
      p={6}
      titleProps={titleProps}
      descriptionProps={descriptionProps}
      infoProps={infoProps}
    >
      {is_resolved && <EventCardSolved />}
    </EventCardBody>
  );
};

const titleProps = {
  textStyle: "heading-small-medium"
} as const;
