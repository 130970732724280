import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const QuestionMark = ({
  fill = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <mask
      id={"question-mark-mask0"}
      style={{ maskType: "alpha" }}
      maskUnits={"userSpaceOnUse"}
      x={"0"}
      y={"0"}
      width={"24"}
      height={"24"}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C5.3724 24 0 18.6276 0 12ZM11.9998 1.19999C17.965 1.19999 22.7998 6.03479 22.7998 12C22.7998 17.9652 17.965 22.8 11.9998 22.8C6.03462 22.8 1.19982 17.9652 1.19982 12C1.19982 6.03479 6.03462 1.19999 11.9998 1.19999ZM12.6645 14.202C12.6645 14.2716 12.6669 14.3412 12.6717 14.4V14.4696L10.9221 14.49C10.8921 14.3076 10.8765 14.1192 10.8765 13.9272C10.8765 12.8652 11.3577 11.9148 12.1197 11.2788C13.2957 10.2948 13.8237 9.5868 13.8237 8.6868C13.8237 7.4748 13.0317 6.9948 11.8221 6.9948C10.7529 7.0452 9.78688 7.4928 9.07168 8.1948H8.99968L8.99008 6.006C9.88888 5.2668 11.0337 4.8168 12.2877 4.7988C14.7717 4.7988 16.1997 5.9268 16.2117 8.2188C16.2117 9.5268 15.7437 10.5948 13.8033 12.0048C13.1145 12.492 12.6645 13.2948 12.6645 14.202ZM13.2006 17.7092C13.2041 17.6703 13.2064 17.6257 13.2064 17.5812C13.2064 16.7928 12.5668 16.1532 11.7784 16.1532C10.99 16.1532 10.3504 16.7928 10.3504 17.5812C10.3504 17.6268 10.3528 17.6724 10.3564 17.718C10.3516 17.7516 10.3504 17.7972 10.3504 17.8428C10.3504 18.6312 10.99 19.2708 11.7784 19.2708C12.5668 19.2708 13.2064 18.6312 13.2064 17.8428C13.2064 17.7983 13.2041 17.7537 13.2006 17.7092Z"
        }
        fill={"white"}
      />
    </mask>
    <g mask={"url(#question-mark-mask0)"}>
      <rect
        x={"-2.39966"}
        y={"-2.40039"}
        width={"28.8"}
        height={"28.8"}
        fill={"#424242"}
      />
    </g>
  </StyledSvg>
);

export default QuestionMark;
