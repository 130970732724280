import Flex from "components/flex";
import Text from "components/text";
import React from "react";
import styled from "styled-components";
import { width, maxWidth } from "styled-system";
import { FeedEventCard } from "src/nhweb-shared/components/FeedEventCard";
import { Loading } from "src/nhweb-shared/components/Loading";
import {
  EventCategoriesProvider,
  ThemeProvider
} from "src/nhweb-shared/context";
import { theme as nhTheme } from "src/nhweb-shared/theme";
import { Tooltip } from "src/nhweb-shared/components/Tooltip";

const PREFETCH_THRESHOLD = 2000; // pixels before reaching the end that will trigger nextPage()

const LinkDiv = styled.div`
  text-decoration: none;
  outline: none;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  ${width}
  ${maxWidth}
`;

const AgencyFeed = ({
  categories,
  posts,
  isLoadingPosts,
  onRequestPage,
  config
}) => {
  const eventAreaCenter = [-118.4725557, 34.0281278];

  const handleScroll = ({ target: { scrollHeight, scrollTop } }) => {
    if (scrollTop >= scrollHeight - PREFETCH_THRESHOLD) {
      onRequestPage();
    }
  };

  const handleStyledNativeLinkClick = (e, alertUrl) => {
    const maxNumIterations = 6;
    var isVideoPlayerPlayPauseClick = false;
    var isVideoPlayerControlsClick = false;
    var isGalleryNavigationLeftRightClick = false;
    var isGalleryNavigationThumbnailClick = false;

    const dfs = (node, i) => {
      if (!node || i === maxNumIterations) {
        return;
      }

      if (e.target?.nodeName === "VIDEO") {
        isVideoPlayerControlsClick = true;
        return;
      }

      if (
        node?.className?.includes?.("image-video-slide") &&
        (e.target?.nodeName === "svg" || e.target?.nodeName === "path")
      ) {
        isVideoPlayerPlayPauseClick = true;
        return;
      }

      if (
        node?.className?.includes?.("image-gallery-icon") &&
        (e.target?.nodeName === "svg" || e.target?.nodeName === "path")
      ) {
        isGalleryNavigationLeftRightClick = true;
        return;
      }

      if (
        (node?.className?.includes?.("image-gallery-thumbnail") ||
          node?.className?.includes?.("image-gallery-thumbnails")) &&
        (e.target?.nodeName === "BUTTON" || e.target?.nodeName === "DIV")
      ) {
        isGalleryNavigationThumbnailClick = true;
        return;
      }

      dfs(node.parentNode, i + 1);
    };

    dfs(e?.target, 1);

    if (
      isVideoPlayerPlayPauseClick ||
      isVideoPlayerControlsClick ||
      isGalleryNavigationLeftRightClick ||
      isGalleryNavigationThumbnailClick
    ) {
      e.preventDefault();
      return false;
    }

    window.open(alertUrl, "_blank");
    return true;
  };

  const enableNewAgencyProfile = config.ENABLE_NEW_AGENCY_PROFILE === "true";

  return (
    <Flex
      alignItems={{ sm: "center", md: "flex-start" }}
      bg={"lightGrey"}
      flexBasis={1}
      flexDirection={"column"}
      flexGrow={1}
      flexShrink={0}
      height={enableNewAgencyProfile ? null : "100%"}
      onScroll={handleScroll}
      overflow={enableNewAgencyProfile ? null : "auto"}
      px={enableNewAgencyProfile ? null : { sm: "12px", md: "40px" }}
      py={enableNewAgencyProfile ? null : "24px"}
      width={enableNewAgencyProfile ? "100%" : { sm: 1, md: "auto" }}
    >
      <ThemeProvider key={"test"} theme={nhTheme}>
        <EventCategoriesProvider value={categories}>
          <Tooltip id={"timeagoTooltip"} />
          {posts?.map((event, index) => (
            <LinkDiv
              className={"postsLinkDiv"}
              onClick={e => handleStyledNativeLinkClick(e, event.alertUrl)}
              flexShrink={0}
              key={index}
              maxWidth={{
                s: "100%",
                m: enableNewAgencyProfile ? "100%" : "500px"
              }}
              width={"100%"}
            >
              <FeedEventCard
                event={event}
                eventAreaCenter={eventAreaCenter}
                width={"100%"}
                readMoreDisabled={true}
                eventCardEventInfoProps={{
                  timeSinceCreatedProps: {
                    fontSize: "12px",
                    color: "#424242",
                    lineHeight: "20px"
                  }
                }}
              />
            </LinkDiv>
          ))}
          {!isLoadingPosts && posts?.length === 0 && (
            <Flex
              flexDirection={"column"}
              textAlign={"left"}
              backgroundColor={"inherit"}
              width={"100%"}
              pl={{ sm: "12px", md: "0px" }}
            >
              <Text fontSize={2} fontWeight={"medium"} lineHeight={"24px"}>
                {"This agency has not shared any posts."}
              </Text>
              <Text
                fontSize={1}
                fontWeight={"light"}
                lineHeight={"24px"}
                pb={"24px"}
              >
                {
                  "Agencies just like this help neighborhood safety by sharing posts that keep the community informed."
                }
              </Text>
            </Flex>
          )}
          {isLoadingPosts ? (
            <Flex
              maxWidth={{ s: "100%", m: "500px" }}
              width={"100%"}
              justifyContent={"center"}
              backgroundColor={"inherit"}
            >
              <Loading mt={2} />
            </Flex>
          ) : (
            <Text
              fontSize={0}
              fontWeight={"light"}
              lineHeight={"24px"}
              fontStyle={"italic"}
              textAlign={"left"}
              width={"100%"}
              pl={{ sm: "12px", md: "0px" }}
            >
              {
                "This list will only display posts published after January 2021."
              }
            </Text>
          )}
        </EventCategoriesProvider>
      </ThemeProvider>
    </Flex>
  );
};

export default AgencyFeed;
