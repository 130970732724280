const REQUEST_FOR_ASSISTANCE = "publicassistance";
const CRIME = "crime";
const SAFETY = "safety";
const LOST_PETS = "lostpets";
const ENVIRONMENTAL = "environmental";
const COMMUNITY = "community";
const TRANSPORTATION_TRAFFIC = "94e492c0-907b-11ee-b9d1-0242ac120002";
const WILDLIFE = "b19c222a-907b-11ee-b9d1-0242ac120002";

export interface Category {
  readonly id: string;
  readonly title: string;
  readonly color: string;
  readonly text: string;
  readonly deleted: boolean;
}

export const getCategories = config => {
  const enableNewPostCategories = config.ENABLE_NEW_POST_CATEGORIES === "true";

  return {
    [REQUEST_FOR_ASSISTANCE]: {
      id: REQUEST_FOR_ASSISTANCE,
      title: "Request For Assistance",
      color: "#10628A",
      text: "Local public safety agencies seeking assistance with an investigation within a specific area.",
      deleted: enableNewPostCategories
    },
    [CRIME]: {
      id: CRIME,
      title: "Crime",
      color: "#E7372D",
      text: "Criminal activity involving theft, damage, illegal entry, or violence.",
      deleted: enableNewPostCategories
    },
    [SAFETY]: {
      id: SAFETY,
      title: enableNewPostCategories ? "Safety and Security" : "Safety",
      color: "#83C284",
      text: "General environmental awareness and potentially dangerous incidents.",
      deleted: false
    },
    [LOST_PETS]: {
      id: LOST_PETS,
      title: enableNewPostCategories ? "Lost And Found Pets" : "Animals",
      color: "#AFDBF1",
      text: "Lost (and found) pets and wild animals that pose a safety risk.",
      deleted: false
    },
    [ENVIRONMENTAL]: {
      id: ENVIRONMENTAL,
      title: enableNewPostCategories ? "Weather" : "Environmental",
      color: "#FF9900",
      text: "Severe weather and local environmental conditions that present safety concerns, such as wildfires, floods, or air quality.",
      deleted: false
    },
    [COMMUNITY]: {
      id: COMMUNITY,
      title: enableNewPostCategories ? "Community Engagement" : "Community",
      color: "#9013FE",
      text: "Community building moments, events, and acts of kindness.",
      deleted: false
    },
    [TRANSPORTATION_TRAFFIC]: {
      id: TRANSPORTATION_TRAFFIC,
      title: "Transportation and Traffic",
      color: "#FEDD00",
      text: "Transportation hazards and closures.",
      deleted: !enableNewPostCategories
    },
    [WILDLIFE]: {
      id: WILDLIFE,
      title: "Wildlife and Environmental issues",
      color: "#AFDBF1",
      text: "Wild animal sightings and widespread environmental hazards like floods, wildfires, and earthquakes.",
      deleted: !enableNewPostCategories
    }
  };
};
