import React, { ReactElement } from "react";

import { Box, BoxBasedComponentProps } from "../Box";
import { Flex } from "../Flex";
import { Icon } from "../Icon";

const formatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 1,
  notation: "compact",
  compactDisplay: "short"
});

const iconSize = 20;

type EventCardFooterActionProps = BoxBasedComponentProps<
  "div",
  {
    isActive?: boolean;
    icon: ReactElement;
    children?: number;
  }
>;

const EventCardFooterAction = ({
  isActive,
  icon,
  children,
  ...props
}: EventCardFooterActionProps) => {
  const formatted = children ? formatter.format(children) : undefined;

  return (
    <Flex
      {...props}
      alignItems={"center"}
      color={isActive ? "primary.500" : "neutral.300"}
      cursor={"pointer"}
      _hover={{ color: "primary.500" }}
      textStyle={"body-normal-light"}
    >
      {icon}
      {formatted && (
        <Box as={"span"} mt={1 / 2}>
          {formatted}
        </Box>
      )}
    </Flex>
  );
};

type EventCardFooterUpvoteActionProps = Omit<
  EventCardFooterActionProps,
  "icon"
>;

export const EventCardFooterUpvoteAction = (
  props: EventCardFooterUpvoteActionProps
) => {
  return (
    <EventCardFooterAction
      {...props}
      mr={4}
      icon={<Icon name={"thanks_neighborhoods"} mr={2} size={iconSize} />}
    />
  );
};

export const EventCardFooterCommentAction = (
  props: EventCardFooterUpvoteActionProps
) => {
  return (
    <EventCardFooterAction
      {...props}
      icon={<Icon name={"chat_bubble"} mr={2} size={iconSize} />}
    />
  );
};

type EventCardFooterShareActionProps = Omit<
  EventCardFooterActionProps,
  "icon" | "ml" | "children"
>;

export const EventCardFooterShareAction = (
  props: EventCardFooterShareActionProps
) => {
  return (
    <EventCardFooterAction
      {...props}
      icon={<Icon name={"share-2"} size={iconSize} />}
      ml={"auto"}
    />
  );
};
