import { isNil, reject } from "ramda";
import React, { useCallback, useState } from "react";

import { AspectRatio } from "../AspectRatio";
import { Box, BoxProps } from "../Box";
import { CardMedia, CardMediaProps } from "../Card";

export type EventMediaType = "image" | "video";

interface EventMediaMap {
  image: "img";
  video: "video";
}

interface EventCardMediaOwnProps<T extends EventMediaType = "image">
  extends Pick<BoxProps<"video">, "controls" | "preload" | "objectFit"> {
  type: T;
  src?: string | null;
}

export type EventCardMediaProps<T extends EventMediaType = "image"> =
  CardMediaProps<EventMediaMap[T], EventCardMediaOwnProps<T>, "as">;

export const EventCardMedia = <T extends EventMediaType>({
  src,
  type,
  borderRadius,
  m,
  margin,
  mt,
  marginTop,
  mr,
  marginRight,
  mb,
  marginBottom,
  ml,
  marginLeft,
  mx,
  marginX,
  my,
  marginY,
  p,
  padding,
  pt,
  paddingTop,
  pr,
  paddingRight,
  pb,
  paddingBottom,
  pl,
  paddingLeft,
  px,
  paddingX,
  py,
  paddingY,
  ...props
}: EventCardMediaProps<T>) => {
  const [error, setError] = useState(false);

  const handleError = useCallback(() => {
    setError(true);
  }, []);

  // TODO: for reason sprading the raw `space` object doesn't always work
  const space = rejectNil({
    m,
    margin,
    mt,
    marginTop,
    mr,
    marginRight,
    mb,
    marginBottom,
    ml,
    marginLeft,
    mx,
    marginX,
    my,
    marginY,
    p,
    padding,
    pt,
    paddingTop,
    pr,
    paddingRight,
    pb,
    paddingBottom,
    pl,
    paddingLeft,
    px,
    paddingX,
    py,
    paddingY
  });

  /**
   * return null if
   *   there's no type
   *   there isn't a valid src
   *   the media errored while loading
   */
  if (!type || !src || error) {
    return (
      <AspectRatio {...space} bg={"black"} borderRadius={borderRadius}>
        <Box height={500} width={1} />
      </AspectRatio>
    );
  }

  switch (type) {
    case "image":
      return (
        <AspectRatio {...space} bg={"black"} borderRadius={borderRadius}>
          <CardMedia {...props} as={"img"} src={src} onError={handleError} />
        </AspectRatio>
      );
    case "video":
      return (
        <AspectRatio {...space} bg={"black"} borderRadius={borderRadius}>
          <CardMedia
            controls
            preload={"auto"}
            {...props}
            as={"video"}
            disablePictureInPicture
            controlsList={"nodownload"}
            src={src}
            onError={handleError}
          />
        </AspectRatio>
      );
  }

  return null;
};

const rejectNil = reject(isNil);
