import { hideOthers, Undo } from "aria-hidden";
import { RefObject, useEffect } from "react";

/**
 * Modal hook to polyfill `aria-modal`.
 *
 * It applies `aria-hidden` to elements behind the modal
 * to indicate that they're `inert`.
 *
 * @param ref React ref of the node
 * @param shouldHide whether `aria-hidden` should be applied
 */
export const useAriaHidden = (
  ref: RefObject<HTMLElement>,
  shouldHide: boolean
) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let undo: Undo | undefined;

    if (shouldHide && ref.current) {
      undo = hideOthers(ref.current);
    }

    return () => {
      if (shouldHide) {
        undo?.();
      }
    };
  }, [shouldHide, ref]);
};
