import axios from "axios";
import { adapter } from "axios/lib/defaults";
import createError from "axios/lib/core/createError";
import uuid from "uuid-browser";

import logger from "utils/datadog-logger";
import moment from "moment";

const CORRELATION_ID_HEADER = "X-Ring-Correlation-Id";
const START_TIME_HEADER = "X-Ring-Request-Start-Time";

const cloudFrontRemappingAdapter = async config => {
  const response = await adapter(config);

  const isMasked404 =
    response.status == 200 &&
    response.headers["content-type"] &&
    response.headers["content-type"].startsWith("text/html");

  if (isMasked404) {
    response.status = 404;
    response.headers["content-type"] = config.headers["Accept"];

    throw createError(
      "Request failed with status code " + response.status,
      response.config,
      null,
      response.request,
      response
    );
  }

  return response;
};

export default (baseURL = "/") => {
  const instance = axios.create({
    baseURL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    withCredentials: true,
    adapter: cloudFrontRemappingAdapter
  });

  instance.applyRequestResponseInterceptor = () => {
    instance.interceptors.request.use(request => {
      request.headers[
        CORRELATION_ID_HEADER
      ] = `RingNwAgencyProfileUI:${uuid.v4()}`;
      request.headers[START_TIME_HEADER] = `${Date.now() * 1000000}`;

      request.metadata = { start: moment() };
      return request;
    });

    instance.interceptors.response.use(
      response => {
        const { config, data = {}, status } = response;
        const start = config.metadata.start;
        const end = moment();
        const duration = end.diff(start, "milliseconds");
        const url = config.url;
        const correlationId = config.headers[CORRELATION_ID_HEADER];

        const log = {
          responseSize: JSON.stringify(data).length,
          serverStatus: status,
          time: duration,
          url: url,
          correlationId
        };

        logger.info(`API_REQUEST ${url} ${status} ${duration}ms`, log);

        return response;
      },
      error => {
        const { config, response } = error;

        if (!config) {
          return Promise.reject(error);
        }

        const start = config.metadata.start;
        const end = moment();
        const duration = end.diff(start, "milliseconds");
        const url = config.url;
        const correlationId = config.headers[CORRELATION_ID_HEADER];

        const status = response ? response.status : 0;
        const responseSize = response
          ? JSON.stringify(response.data).length
          : 0;
        const jsonApiErrors = response && response.data && response.data.errors;

        let canonicalError = "Unknown error";

        if (jsonApiErrors) {
          canonicalError = jsonApiErrors;
        } else if (error.message) {
          canonicalError = error.message;
        }

        const log = {
          error: canonicalError,
          responseSize: responseSize,
          serverStatus: status,
          time: duration,
          url: url,
          correlationId
        };

        if (log.time > 0) {
          logger.error(`API_REQUEST ${url} ${status} ${duration}ms`, log);
        }

        return Promise.reject(error);
      }
    );
  };

  return instance;
};
