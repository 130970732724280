import React from "react";
import styled from "styled-components";
import {
  borders,
  color,
  flexbox,
  layout,
  height,
  space,
  width
} from "styled-system";

const StyledButton = styled.button`
  align-items: center;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: ${({ theme: { colors } }) => colors.primary500};
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  outline: none;
  width: 160px;
  ${borders}
  ${color}
  ${flexbox}
  ${layout}
  ${height}
  ${space}
  ${width}
`;

const Button = ({ children, disabled = false, loading = false, ...props }) => (
  <StyledButton disabled={disabled || loading} {...props}>
    {children}
  </StyledButton>
);

export default Button;
