import { Location, Maybe } from "src/utils/gql-schema";
import equals from "ramda/es/equals";

const defaultCoordsTuple = [0, 0] as [number, number];

export const getCoordinatesTuple = (
  location: Maybe<Location> | undefined
): [number, number] => {
  if (!location?.pin) {
    return defaultCoordsTuple;
  }

  const { longitude, latitude } = location.pin;

  return [longitude, latitude];
};

export const equalsDefaultCoordsTuple = equals(defaultCoordsTuple);
