import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Plus = ({
  fill = "none",
  viewBox = "0 0 24 24",
  height = "24",
  width = "24",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M12.5 11.5V2.5C12.5 2.22386 12.2761 2 12 2C11.7239 2 11.5 2.22386 11.5 2.5V11.5H2.5C2.22386 11.5 2 11.7239 2 12C2 12.2761 2.22386 12.5 2.5 12.5H11.5V21.5C11.5 21.7761 11.7239 22 12 22C12.2761 22 12.5 21.7761 12.5 21.5V12.5H21.5C21.7761 12.5 22 12.2761 22 12C22 11.7239 21.7761 11.5 21.5 11.5H12.5Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Plus;
