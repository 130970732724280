import React from "react";
import { CardBody, CardBodyProps } from "../Card";
import {
  EventCardDescription,
  EventCardDescriptionProps
} from "./EventCardDescription";
import {
  EventCardEventInfo,
  EventCardEventInfoProps
} from "./EventCardEventInfo";
import { EventCardTags } from "./EventCardTags";
import { EventCardTitle, EventCardTitleProps } from "./EventCardTitle";
import { EventCardLink } from "./EventCardLink";

export interface EventCardBodyProps extends CardBodyProps {
  infoProps?: EventCardEventInfoProps;
  titleProps?: EventCardTitleProps;
  descriptionProps?: EventCardDescriptionProps;
}

export const EventCardBody = ({
  infoProps,
  titleProps,
  descriptionProps,
  children,
  ...props
}: EventCardBodyProps) => {
  return (
    <CardBody {...props}>
      <EventCardEventInfo {...infoProps} />
      <EventCardTitle {...titleProps} />
      <EventCardDescription {...descriptionProps} />
      <EventCardLink />
      <EventCardTags mb={children ? 3 : undefined} />
      {children}
    </CardBody>
  );
};
