import React, { useEffect } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";

export const Tooltip = ({
  effect = "solid",
  place = "top",
  ...props
}: TooltipProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return <ReactTooltip {...props} effect={effect} place={place} />;
};
