const MOBILE_BREAKPOINT = 899;
// const MOBILE_BREAKPOINT = 1023;

export default () => {
  const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const onInit = callback => {
    callback(mql.matches);
  };

  const onBreakpoint = callback => {
    mql.addListener(e => callback(e.matches));
  };

  return { onInit, onBreakpoint };
};
