import React, { forwardRef } from "react";
import { Button as ButtonVariant, Radii } from "src/nhweb-shared/theme";
import { isDevelopmentBuild } from "src/utils";

import { Box, BoxBasedComponentProps } from "../Box";

type ButtonType = "a" | "button";

export type ButtonProps<T extends ButtonType = "button"> =
  BoxBasedComponentProps<
    T,
    {
      block?: boolean;
      shape?: Radii;
      size?: "sm" | "md" | "lg" | number;
      variant?: ButtonVariant | false;
      disabled?: boolean;
    }
  >;

export const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps<"a"> | ButtonProps<"button">
>(
  (
    {
      as = "button",
      block = false,
      disabled = false,
      shape = "round",
      size = "md",
      sx,
      variant = "primary",
      ...props
    },
    ref
  ) => {
    const asProps = {
      disabled: as === "button" ? disabled : undefined
    };

    const sizeStyles = getSizeStyles(size);

    const borderRadius = (variant === "empty" ? "regular" : shape) as Radii;

    return (
      <Box
        {...asProps}
        {...sizeStyles}
        appearance={"none"}
        display={"inline-block"}
        textAlign={"center"}
        lineHeight={"inherit"}
        textDecoration={"none"}
        {...props}
        ref={ref}
        tx={"buttons"}
        as={as}
        variant={variant ? variant : undefined}
        outline={"none"}
        cursor={"pointer"}
        borderRadius={borderRadius}
        _focus={{
          outline: "none"
        }}
        sx={[
          sx,
          block && { display: "block", width: "100%" },
          disabled && {
            bg: "primary.200",
            pointerEvents: "none"
          }
        ]}
      />
    );
  }
);

if (isDevelopmentBuild) {
  Button.displayName = "Button";
}

const getSizeStyles = (size: ButtonProps["size"]) => {
  switch (size) {
    case "lg":
      return { padding: ".5rem 2rem", fontSize: "1.125rem" };
    case "md":
      return { padding: ".375rem .75rem", fontSize: "1rem" };
    case "sm":
      return { padding: ".25rem 5rem", fontSize: ".875rem" };
    default:
      return { size };
  }
};
