export const navHeight = "72px";
export const pageHeight = `calc(100vh - ${navHeight})`;

export enum DateType {
  "90d" = "90 DAYS",
  "60d" = "60 DAYS",
  "30d" = "30 DAYS",
  "14d" = "14 DAYS",
  "7d" = "7 DAYS",
  "24h" = "24 HOURS"
}
