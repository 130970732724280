import React from "react";
import { ReactImageGalleryItem } from "react-image-gallery";
import ReactPlayer from "react-player";

import { AspectRatio, AspectRatioProps } from "../AspectRatio";
import { Box } from "../Box";
import { EventCardMedia, EventMediaType } from "../EventCard";
import { Icon } from "../Icon";
import { useMediaGalleryState, useMediaGalleryUpdate } from "./context";

export interface EventGalleryMediaItemProps
  extends ReactImageGalleryItem,
    AspectRatioProps {}

export const EventGalleryMediaItem = ({
  description,
  original,
  borderRadius,
  ...props
}: EventGalleryMediaItemProps) => {
  const { isPlaying } = useMediaGalleryState();
  const { toggleIsPlaying } = useMediaGalleryUpdate();

  const mediaType = description as EventMediaType;

  if (mediaType !== "video") {
    return (
      <EventCardMedia
        src={original}
        type={mediaType}
        borderRadius={borderRadius}
      />
    );
  }

  return (
    <AspectRatio
      {...props}
      bg={"black"}
      className={"image-video-slide"}
      cursor={"pointer"}
    >
      {/* NPSS Manual Change, this should be parameterized */}
      <Box height={"100%"} width={"100%"} position={"absolute"}>
        <PlayVideoButton />
        <ReactPlayer
          url={original}
          /* NPSS Manual Change, this should be parameterized */
          style={{ zIndex: 9 }}
          width={"auto"}
          height={"100%"}
          playing={isPlaying}
          controls={isPlaying}
          onPlay={() => toggleIsPlaying(true)}
          onPause={() => toggleIsPlaying(false)}
        />
      </Box>
    </AspectRatio>
  );
};

const PlayVideoButton = () => {
  const { isPlaying } = useMediaGalleryState();
  const { toggleIsPlaying } = useMediaGalleryUpdate();

  if (isPlaying) {
    return null;
  }

  return (
    <Box
      left={"50%"}
      onClick={toggleIsPlaying}
      position={"absolute"}
      top={"50%"}
      transform={"translate(-50%, -50%)"}
      zIndex={10}
    >
      <Icon
        color={"whiteAlpha.900"}
        name={isPlaying ? "pause" : "play"}
        size={32}
      />
    </Box>
  );
};
