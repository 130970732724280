import moment from "moment";
import { lensProp, set, view, mergeDeepRight } from "ramda";
import { handleActions } from "redux-actions";
import { ViewMode } from "utils/view-mode";
import { pipe } from "rxjs";
import AgencyType from "../constants/agency-type";

import {
  agencyDetailsDataSuccess,
  agencyMetricsDataSuccess,
  agencyPostsDataSuccess,
  currentUserDataSuccess,
  viewModeDataSuccess,
  agencyReportsDataSuccess
} from "actions";

import { getCategories, createQuarterlySummaryFromMetrics } from "../domain";

const initialState = {
  currentUser: {},
  agency: {},
  nextAgencyPostCursor: null,
  lastAgencyPostReached: false,
  quarterlySummary: {},
  quarterlySummaryPeriod: {
    start_date: moment()
      .utc()
      .subtract(1, "Q")
      .startOf("quarter")
      .toISOString(),
    end_date: moment().utc().subtract(1, "Q").endOf("quarter").toISOString()
  },
  viewMode: ViewMode.PUBLIC,
  reports: []
};

const agencyLens = lensProp("agency");
const quarterlySummaryLens = lensProp("quarterlySummary");
const quarterlySummaryPeriodLens = lensProp("quarterlySummaryPeriod");
const nextAgencyPostCursorLens = lensProp("nextAgencyPostCursor");
const lastAgencyPostReachedLens = lensProp("lastAgencyPostReached");
const reportsLens = lensProp("reports");
const currentUserLens = lensProp("currentUser");
const viewModeLens = lensProp("viewMode");

export const getAgency = view(agencyLens);
export const getQuarterlySummary = view(quarterlySummaryLens);
export const getQuarterlySummaryPeriod = view(quarterlySummaryPeriodLens);
export const getNextAgencyPostCursor = view(nextAgencyPostCursorLens);
export const getLastAgencyPostReached = view(lastAgencyPostReachedLens);
export const getAgencyReports = view(reportsLens);
export const getCurrentUser = view(currentUserLens);
export const getViewMode = view(viewModeLens);

export default handleActions(
  {
    [agencyDetailsDataSuccess]: (state, { payload }) => ({
      ...state,
      agency: mergeDeepRight(state.agency, payload)
    }),
    [agencyMetricsDataSuccess]: (state, { payload }) => {
      let categories = getCategories(payload.config);

      // For now if agency type !== FD/LE hide rfa count from report
      if (
        state.agency &&
        state.agency.agency_type !== AgencyType.LAW_ENFORCEMENT &&
        state.agency.agency_type !== AgencyType.FIRE_DEPARTMENT
      ) {
        delete categories.publicassistance;
      }

      const quarterlySummary = createQuarterlySummaryFromMetrics(
        payload.data.metrics,
        categories
      );

      return pipe(
        set(
          quarterlySummaryLens,
          mergeDeepRight(state.quarterlySummary, quarterlySummary)
        ),
        set(quarterlySummaryPeriodLens, payload.data.period)
      )(state);
    },
    [currentUserDataSuccess]: (state, { payload: currentUser }) => ({
      ...state,
      currentUser
    }),
    [viewModeDataSuccess]: (state, { payload: { viewMode } }) => ({
      ...state,
      viewMode
    }),
    [agencyPostsDataSuccess]: (
      state,
      { payload: { posts, nextAgencyPostCursor } }
    ) => {
      const newState = {
        ...state,
        nextAgencyPostCursor
      };
      if (!newState.agency) newState.agency = {};
      if (!newState.agency.posts) {
        newState.agency.posts = [];
      }

      if (posts.length === 0 || nextAgencyPostCursor === null) {
        newState.lastAgencyPostReached = true;
      }

      newState.agency.posts = newState.agency.posts.concat(posts);

      return newState;
    },
    [agencyReportsDataSuccess]: (state, { payload: { reports } }) => ({
      ...state,
      reports
    })
  },
  initialState
);
