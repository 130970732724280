import React from "react";
import styled from "styled-components";
import {
  color,
  display,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  lineHeight,
  space,
  textAlign,
  textStyle,
  width
} from "styled-system";

const StyledText = styled.div`
  ${({ pointer }) => pointer && "cursor: pointer;"}
  ${fontStyle}
  ${color}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${lineHeight}
  ${display}
  ${space}
  ${textAlign}
  ${textStyle}
  ${width}
  ${({ overflowWrap }) => overflowWrap && `overflow-wrap: ${overflowWrap};`}
`;

const Text = ({
  color = "neutral500",
  display = "block",
  fontFamily = "Equip",
  fontSize = 2,
  fontStyle = undefined,
  fontWeight = 1,
  space = undefined,
  textAlign = undefined,
  overflowWrap = undefined,
  ...props
}) => (
  <StyledText
    color={color}
    display={display}
    fontFamily={fontFamily}
    fontSize={fontSize}
    fontStyle={fontStyle}
    fontWeight={fontWeight}
    space={space}
    textAlign={textAlign}
    overflowWrap={overflowWrap}
    {...props}
  />
);

export default Text;
