import plur from "plur";

export function plural(count: number, word: string, inclusive = true) {
  const result = plur(word, count);

  if (!inclusive) {
    return result;
  }

  return `${count} ${result}`;
}
