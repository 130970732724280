const theme = {
  breakpoints: ["0px", "900px", "1200px", "1800px"],
  // breakpoints: ["0px", "768px", "1024px", "1366px"],
  borders: [0, "1px solid #dee5ec"],
  buttons: {},
  colors: {
    white: "#fff",
    offWhite: "#e5e5e5",
    neutral300: "#6e6e6e",
    neutral500: "#424242",
    lightGrey: "#fafbfc",
    primary100: "#eff8fc",
    primary200: "#AFDBF1",
    primary400: "#1476AD",
    primary500: "#1998d5"
  },
  colorStyles: {
    modal: {
      backgroundColor: "rgba(0,0,0,0.5)"
    },
    blockingView: {
      backgroundColor: "rgba(255,255,255,1)"
    }
  },
  fonts: ["Equip"],
  fontSizes: [12, 14, 16, 18, 20, 24, 32],
  fontWeights: [100, 300, 500, 800],
  radii: [0, "4px", "8px", "50%"],
  shadows: [
    0,
    "0 -2px 4px rgba(0, 0, 0, 0.15)",
    "0 4px 4px 0 rgba(0, 0, 0, 0.05)",
    "0 8px 12px 0 rgba(0, 0, 0, 0.15)"
  ],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  textStyles: {
    underline: {
      textDecoration: "underline"
    },
    overflow: {
      textOverflow: "ellipsis"
    },
    preline: {
      whiteSpace: "pre-line"
    },
    noUnderline: {
      textDecoration: "none"
    }
  }
};

// @ts-ignore
theme.breakpoints.sm = theme.breakpoints[0];
// @ts-ignore
theme.breakpoints.md = theme.breakpoints[1];
// @ts-ignore
theme.breakpoints.lg = theme.breakpoints[2];
// @ts-ignore
theme.breakpoints.xl = theme.breakpoints[3];

export default theme;
