// https://ring.gitbook.io/ring-design-system/foundations/styles#spacings

// We define our standard spacing scale and then define some extra keys on that array for styling
// convenience (negative paddings, margins, etc)
// Note this won't modify the array lenght, so don't rely on it (you shouldn't have to)

const baseSpace = [
  0, // 0
  4, // 1
  8, // 2
  12, // 3
  16, // 4
  20, // 5
  24, // 6
  32, // 7
  40, // 8
  48, // 9
  56, // 10
  64, // 11
  72 // 12
];

export const space = baseSpace.reduce((acc, v, i) => {
  if (i) {
    acc[-i] = -v;
  }

  return acc;
}, baseSpace);
