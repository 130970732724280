import { createActions } from "redux-actions";

export const AGENCY_DETAILS_DATA_FETCH = "AGENCY_DETAILS_DATA_FETCH";
export const AGENCY_DETAILS_DATA_SUCCESS = "AGENCY_DETAILS_DATA_SUCCESS";
export const AGENCY_DETAILS_DATA_FAILURE = "AGENCY_DETAILS_DATA_FAILURE";

export const AGENCY_METRICS_DATA_FETCH = "AGENCY_METRICS_DATA_FETCH";
export const AGENCY_METRICS_DATA_SUCCESS = "AGENCY_METRICS_DATA_SUCCESS";
export const AGENCY_METRICS_DATA_FAILURE = "AGENCY_METRICS_DATA_FAILURE";

export const CURRENT_USER_DATA_FETCH = "CURRENT_USER_DATA_FETCH";
export const CURRENT_USER_DATA_SUCCESS = "CURRENT_USER_DATA_SUCCESS";
export const CURRENT_USER_DATA_FAILURE = "CURRENT_USER_DATA_FAILURE";

export const VIEW_MODE_DATA_SUCCESS = "VIEW_MODE_DATA_SUCCESS";
export const VIEW_MODE_DATA_FAILURE = "VIEW_MODE_DATA_FAILURE";

export const AGENCY_POSTS_DATA_FETCH = "AGENCY_POSTS_DATA_FETCH";
export const AGENCY_POSTS_DATA_SUCCESS = "AGENCY_POSTS_DATA_SUCCESS";
export const AGENCY_POSTS_DATA_FAILURE = "AGENCY_POSTS_DATA_FAILURE";

export const AGENCY_REPORTS_DATA_FETCH = "AGENCY_REPORTS_DATA_FETCH";
export const AGENCY_REPORTS_DATA_SUCCESS = "AGENCY_REPORTS_DATA_SUCCESS";
export const AGENCY_REPORTS_DATA_FAILURE = "AGENCY_REPORTS_DATA_FAILURE";

export const LOAD_CONFIG_VARIABLES_FETCH = "LOAD_CONFIG_VARIABLES_FETCH";
export const LOAD_CONFIG_VARIABLES_SUCCESS = "LOAD_CONFIG_VARIABLES_SUCCESS";
export const LOAD_CONFIG_VARIABLES_FAILURE = "LOAD_CONFIG_VARIABLES_FAILURE";

export const ON_FEED_REQUEST_NEXT_PAGE = "ON_FEED_REQUEST_NEXT_PAGE";

export const {
  agencyDetailsDataFetch,
  agencyDetailsDataSuccess,
  agencyDetailsDataFailure,
  agencyMetricsDataFetch,
  agencyMetricsDataSuccess,
  agencyMetricsDataFailure,
  currentUserDataFetch,
  currentUserDataSuccess,
  currentUserDataFailure,
  viewModeDataSuccess,
  viewModeDataFailure,
  agencyPostsDataFetch,
  agencyPostsDataSuccess,
  agencyPostsDataFailure,
  agencyReportsDataFetch,
  agencyReportsDataSuccess,
  agencyReportsDataFailure,
  loadConfigVariablesFetch,
  loadConfigVariablesSuccess,
  loadConfigVariablesFailure,
  onFeedRequestNextPage
} = createActions(
  {},
  AGENCY_DETAILS_DATA_FETCH,
  AGENCY_DETAILS_DATA_SUCCESS,
  AGENCY_DETAILS_DATA_FAILURE,
  AGENCY_METRICS_DATA_FETCH,
  AGENCY_METRICS_DATA_SUCCESS,
  AGENCY_METRICS_DATA_FAILURE,
  CURRENT_USER_DATA_FETCH,
  CURRENT_USER_DATA_SUCCESS,
  CURRENT_USER_DATA_FAILURE,
  VIEW_MODE_DATA_SUCCESS,
  VIEW_MODE_DATA_FAILURE,
  AGENCY_POSTS_DATA_FETCH,
  AGENCY_POSTS_DATA_SUCCESS,
  AGENCY_POSTS_DATA_FAILURE,
  AGENCY_REPORTS_DATA_FETCH,
  AGENCY_REPORTS_DATA_SUCCESS,
  AGENCY_REPORTS_DATA_FAILURE,
  LOAD_CONFIG_VARIABLES_FETCH,
  LOAD_CONFIG_VARIABLES_SUCCESS,
  LOAD_CONFIG_VARIABLES_FAILURE,
  ON_FEED_REQUEST_NEXT_PAGE
);
