import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Mail = ({
  fill = "none",
  height = "32",
  viewBox = "0 0 32 32",
  width = "32",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M28.0013 6.6665H4.0013C3.2653 6.6665 2.66797 7.26384 2.66797 7.99984V23.9998C2.66797 24.7358 3.2653 25.3332 4.0013 25.3332H28.0013C28.7373 25.3332 29.3346 24.7358 29.3346 23.9998V7.99984C29.3346 7.26384 28.7373 6.6665 28.0013 6.6665ZM26.508 7.99984L16.0013 15.1865L5.49464 7.99984H26.508ZM4.0013 23.9998V8.59984L16.0013 16.8132L28.0013 8.59984V23.9998H4.0013Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Mail;
