import styled from "styled-components";

export const AvatarImg = styled.img`
  border-radius: 50%;
  height: 44px;
  width: 44px;
`;

export const LogoImg = styled.img`
  height: 20px;
  width: 20px;
`;
