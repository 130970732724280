enum AgencyType {
  LAW_ENFORCEMENT = 0,
  FIRE_DEPARTMENT = 1,
  NEWS_TEAM = 2,
  LOCAL_GOVERNMENT = 3,
  LOCAL_NEWS = 4,
  HUMANE_SOCIETY = 5,
  EMERGENCY_MANAGEMENT = 6
}

export default AgencyType;
