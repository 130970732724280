import React from "react";
import PropTypes from "prop-types";

import Flex from "components/flex";
import Text from "components/text";

const NotFound = () => (
  <Flex
    alignItems={"center"}
    flexDirection={"column"}
    height={"100%"}
    justifyContent={"center"}
    width={1}
  >
    <Text
      color={"grey"}
      fontSize={6}
      fontWeight={2}
      mb={1}
      textAlign={"center"}
    >
      {"Agency Not Found"}
    </Text>
    <Text fontWeight={2} mb={3} mx={3} textAlign={"center"}>
      {"404 - We couldn't find the agency you were looking for."}
    </Text>
  </Flex>
);

NotFound.contextTypes = {
  t: PropTypes.func
};

export default NotFound;
