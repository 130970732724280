import React from "react";

import { BoxBasedComponentProps } from "../Box";
import { Link } from "../Link";
import { Flex } from "../Flex";
import { Icon } from "../Icon";
import Text from "components/text";
import { AvatarImg, LogoImg } from "../Image";

export type OwnerHeaderBaseProps = BoxBasedComponentProps<
  "div",
  {
    agencyId: string;
    avatarUrl: string | undefined;
    description: string | undefined;
    logoUrl: string | undefined;
    isOwned: boolean;
    ownerType: string;
  }
>;

export const OwnerHeaderBase = ({
  agencyId,
  avatarUrl,
  description,
  logoUrl,
  isOwned,
  ownerType,
  children,
  ...props
}: OwnerHeaderBaseProps) => {
  const verified = isVerifiedType(ownerType);
  const color = verified || isOwned ? "primary.500" : "neutral.500";

  return (
    <Flex
      alignItems={"center"}
      justifyContent={"space-between"}
      {...props}
      width={"100%"}
    >
      <Flex
        {...props}
        alignItems={"center"}
        textStyle={"body-normal-medium"}
        color={color}
      >
        {avatarUrl && (
          <Link
            href={`/agencies/${agencyId}`}
            target={"_blank"}
            height={"44px"}
          >
            <AvatarImg
              src={avatarUrl}
              onClick={() => window.open(`/agencies/${agencyId}`)}
            />
          </Link>
        )}
        {avatarUrl === undefined && verified && (
          <Icon
            name={"verified"}
            size={20}
            color={"primary.500"}
            mr={2}
            mb={0}
          />
        )}
        <Flex flexDirection={"column"} ml={2} color={"primary.400"}>
          {children}
          {description && (
            <Link href={`/agencies/${agencyId}`} target={"_blank"}>
              <Text
                fontWeight={100}
                fontSize={13}
                lineHeight={1}
                color={"#6E6E6E"}
              >
                {description}
              </Text>
            </Link>
          )}
        </Flex>
      </Flex>
      {logoUrl && (
        <Flex alignItems={"flex-end"} mr={3} data-testid={"x-logo"}>
          <LogoImg src={logoUrl} />
        </Flex>
      )}
    </Flex>
  );
};

const isVerifiedType = (type: string | null | undefined) => {
  return type === "admin" || type === "police";
};
