import React from "react";
import { Layer, Source } from "react-map-gl";
import theme from "theme";

export interface MapPolygonProps {
  polygon: object;
}

/**
 * MapPolygon is used to display a shape on a map.
 */
export const MapPolygon = ({ polygon }: MapPolygonProps) => {
  if (!polygon) {
    return null;
  }

  return (
    <>
      <Source id={"map-polygon-source"} type={"geojson"} data={polygon} />
      <Source
        id={"map-polygon-bounds-source"}
        type={"geojson"}
        data={polygon}
      />
      <Layer
        id={"map-polygon"}
        source={"map-polygon-source"}
        type={"fill"}
        paint={{
          "fill-color": theme.colors.primary500,
          "fill-outline-color": theme.colors.primary500,
          "fill-opacity": 0.35,
          "fill-antialias": true
        }}
      />
      <Layer
        id={"map-polygon-bounds"}
        source={"map-polygon-bounds-source"}
        type={"line"}
        paint={{
          "line-color": theme.colors.primary500,
          "line-width": 2
        }}
      />
    </>
  );
};
