import type { EventCategory } from "src/utils/gql-schema";
import React, { ReactElement, ReactNode } from "react";
import { useEventCategory } from "src/nhweb-shared/context";
import { useThemeStyles } from "src/nhweb-shared/utils";

import { Box, BoxProps } from "../Box";
import { Flex } from "../Flex";

interface EventCategoryDotChildrenProps extends Pick<EventCategory, "title"> {
  dot: ReactElement;
  offset: string;
}

export interface EventCategoryDotProps
  extends Omit<BoxProps, "children" | "size"> {
  size?: number;
  category: string;
  dotBackgroundColor?: string;
  dotMr?: number;
  children?: ReactNode | ((props: EventCategoryDotChildrenProps) => ReactNode);
}

export const EventCategoryDot = ({
  category,
  children,
  dotBackgroundColor,
  dotMr = 2,
  size = 8,
  ...props
}: EventCategoryDotProps) => {
  const margin = useThemeStyles(({ space }) => space[dotMr]);
  const { color, title } = useEventCategory(category);

  const dot = (
    <Box
      backgroundColor={dotBackgroundColor ?? color}
      borderColor={color}
      borderWidth={"2px"}
      borderStyle={"solid"}
      size={size}
      mr={dotMr}
      borderRadius={"circle"}
    />
  );

  if (typeof children === "function") {
    const offset = `${size + margin}px`;

    return children({ dot, title, offset });
  }

  return (
    <Flex {...props} alignItems={"center"}>
      {dot}
      {title}
      {children}
    </Flex>
  );
};
