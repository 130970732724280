import React from "react";
import styled from "styled-components";
import { color, layout, space } from "styled-system";

import Box from "components/box";
import Flex from "components/flex";

import RingLogo from "components/icons/ring-logo";

const StyledLoadingIndicator = styled(Box)`
  border: 3px solid;
  border-radius: 50%;
  border-top-color: transparent;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  ${({ center }) => center && `margin: 32px auto;`}
  ${color}
  ${layout}
  ${space}

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingIndicator = ({
  bg = "transparent",
  color = "primary500",
  ...props
}) => (
  <Flex
    alignItems={"center"}
    bg={"transparent"}
    justifyContent={"center"}
    flexDirection={"column"}
    height={"100%"}
    flexGrow={1}
    width={1}
  >
    <RingLogo />
    <StyledLoadingIndicator color={color} bg={bg} {...props} />
  </Flex>
);

export default LoadingIndicator;
