import { lensProp, pipe, view, set } from "ramda";
import { combineActions, handleActions } from "redux-actions";

import {
  agencyDetailsDataFailure,
  agencyDetailsDataFetch,
  agencyMetricsDataFailure,
  agencyMetricsDataFetch,
  agencyMetricsDataSuccess,
  agencyPostsDataFailure,
  agencyPostsDataFetch,
  agencyPostsDataSuccess,
  onFeedRequestNextPage,
  setIsMobileScreen
} from "actions";

const initialState = {
  isMobileScreen: true,
  isLoadingAgency: false,
  isLoadingAgencyPosts: false,
  isLoadingAgencyMetrics: false
};

const isMobileScreenLens = lensProp("isMobileScreen");
const isLoadingAgencyLens = lensProp("isLoadingAgency");
const isLoadingAgencyPostsLens = lensProp("isLoadingAgencyPosts");
const isLoadingAgencyMetricsLens = lensProp("isLoadingAgencyMetrics");

export const getIsMobileScreen = view(isMobileScreenLens);
export const getIsLoadingAgency = view(isLoadingAgencyLens);
export const getIsLoadingAgencyPosts = view(isLoadingAgencyPostsLens);
export const getIsLoadingAgencyMetrics = view(isLoadingAgencyMetricsLens);

export default handleActions(
  {
    [setIsMobileScreen]: (state, { payload }) =>
      set(isMobileScreenLens, payload)(state),
    [agencyDetailsDataFetch]: set(isLoadingAgencyLens, true),
    [agencyPostsDataFetch]: set(isLoadingAgencyPostsLens, true),
    [agencyMetricsDataFetch]: set(isLoadingAgencyMetricsLens, true),
    [agencyDetailsDataFailure]: set(isLoadingAgencyLens, false),
    [combineActions(agencyPostsDataFailure, agencyPostsDataSuccess)]: set(
      isLoadingAgencyPostsLens,
      false
    ),
    [combineActions(agencyMetricsDataFailure, agencyMetricsDataSuccess)]:
      state =>
        pipe(
          set(isLoadingAgencyLens, false),
          set(isLoadingAgencyMetricsLens, false)
        )(state),
    [onFeedRequestNextPage]: set(isLoadingAgencyPostsLens, true)
  },
  initialState
);
