import "react-image-gallery/styles/css/image-gallery.css";

import classnames from "classnames";
import React from "react";
import ReactImageGallery, { ReactImageGalleryProps } from "react-image-gallery";

import { EventCardMedia, EventMediaType } from "../EventCard";
import {
  EventMediaGalleryProvider,
  ThumbnailType,
  useMediaGalleryState,
  useMediaGalleryUpdate
} from "./context";
import { EventGalleryFullscreenButton } from "./EventGalleryFullscreenButton";
import { EventGalleryMediaThumbnail } from "./EventGalleryMediaThumbnail";
import { ImageGalleryWrapper } from "./EventMediaGallery.styled";
import {
  EventGalleryMediaItem,
  EventGalleryMediaItemProps
} from "./EventMediaGalleryItem";
import { EventGalleryLeftNav } from "./EventMediaGalleryLeftNav";
import { EventGalleryRightNav } from "./EventMediaGalleryRightNav";
import { Media, useMediaObjects } from "./hooks";
import { resetMedia } from "./utils";

export interface EventMediaGalleryProps
  extends Omit<ReactImageGalleryProps, "items">,
    Pick<EventGalleryMediaItemProps, "borderRadius"> {
  eventId: string;
  media: Media[];
  thumbnailType?: ThumbnailType;
}

const EventMediaGallery = ({
  eventId,
  media,
  showIndex,
  showThumbnails = true,
  thumbnailType = "visible",
  borderRadius,
  ...props
}: EventMediaGalleryProps) => {
  const paths = useMediaObjects(media);

  const usesDashes = thumbnailType === "dashes";
  const showDashes = showThumbnails && usesDashes;

  const className = classnames(
    `image-gallery-${eventId}`,
    { "show-index": showIndex },
    { "show-dashes": showDashes }
  );

  const resetMediaWithId = () => resetMedia(eventId);

  return (
    <ImageGalleryWrapper
      textStyle={"body-small-regular"}
      overflow={"hidden"}
      mb={!usesDashes ? 3 : undefined}
      borderRadius={borderRadius}
    >
      <ReactImageGallery
        {...props}
        additionalClass={className}
        items={paths}
        onScreenChange={resetMediaWithId}
        onSlide={resetMediaWithId}
        onThumbnailClick={resetMediaWithId}
        renderFullscreenButton={EventGalleryFullscreenButton}
        renderItem={props => (
          <EventGalleryMediaItem {...props} borderRadius={borderRadius} />
        )}
        renderLeftNav={EventGalleryLeftNav}
        renderRightNav={EventGalleryRightNav}
        renderThumbInner={EventGalleryMediaThumbnail}
        showBullets={false}
        showIndex={showIndex}
        showNav={true}
        showPlayButton={false}
        showThumbnails={showThumbnails}
        slideDuration={100}
      />
    </ImageGalleryWrapper>
  );
};

const EventMediaGalleryWrapper = ({
  borderRadius,
  showIndex,
  media,
  ...props
}: EventMediaGalleryProps) => {
  if (!media || media.length === 0) {
    return null;
  }

  if (media.length === 1) {
    const { url, type } = media[0];

    return (
      <EventCardMedia
        src={url}
        type={type as EventMediaType}
        borderRadius={borderRadius}
        mb={borderRadius === "regular" ? undefined : 3}
      />
    );
  }

  return (
    <EventMediaGalleryProvider>
      <EventMediaGallery
        {...props}
        media={media}
        showIndex={showIndex}
        borderRadius={borderRadius}
      />
    </EventMediaGalleryProvider>
  );
};

export { EventMediaGalleryWrapper as EventMediaGallery };
