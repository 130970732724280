import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Country = ({
  fill = "none",
  height = "28",
  viewBox = "0 0 28 28",
  width = "28",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M14.0001 0.666992C6.66675 0.666992 0.666748 6.66699 0.666748 14.0003C0.666748 21.3337 6.66675 27.3337 14.0001 27.3337C21.3334 27.3337 27.3334 21.3337 27.3334 14.0003C27.3334 6.66699 21.3334 0.666992 14.0001 0.666992ZM14.6667 7.33366V2.13366C16.2667 2.53366 18.0001 4.40033 18.9334 7.33366H14.6667ZM9.06675 7.33366C10.1334 4.40033 11.7334 2.53366 13.3334 2.13366V7.33366H9.06675ZM20.4001 7.33366C19.7334 5.46699 18.9334 3.73366 17.8667 2.66699C20.4001 3.46699 22.5334 5.20033 24.0001 7.33366H20.4001ZM4.00008 7.33366C5.46675 5.20033 7.60008 3.46699 10.1334 2.66699C9.20008 3.73366 8.26675 5.46699 7.60008 7.33366H4.00008ZM21.3334 13.3337C21.3334 11.7337 21.0667 10.1337 20.8001 8.66699H24.8001C25.4667 10.1337 26.0001 11.6003 26.0001 13.3337H21.3334ZM14.6667 13.3337V8.66699H19.3334C19.7334 10.0003 19.8667 11.6003 20.0001 13.3337H14.6667ZM8.00008 13.3337C8.00008 11.6003 8.26675 10.0003 8.66675 8.66699H13.3334V13.3337H8.00008ZM2.00008 13.3337C2.13341 11.6003 2.53341 10.1337 3.20008 8.66699H7.20008C6.80008 10.1337 6.66675 11.7337 6.66675 13.3337H2.00008ZM20.6667 19.3337C21.0667 17.867 21.2001 16.267 21.2001 14.667H26.0001C25.8667 16.4003 25.4667 17.867 24.8001 19.3337H20.6667ZM14.6667 19.3337V14.667H20.0001C20.0001 16.4003 19.7334 18.0003 19.3334 19.3337H14.6667ZM8.66675 19.3337C8.26675 18.0003 8.13341 16.4003 8.00008 14.667H13.3334V19.3337H8.66675ZM3.20008 19.3337C2.53341 17.867 2.00008 16.4003 2.00008 14.667H6.66675C6.66675 16.267 6.93341 17.867 7.20008 19.3337H3.20008ZM17.8667 25.3337C18.9334 24.1337 19.7334 22.5337 20.4001 20.667H24.0001C22.5334 22.8003 20.4001 24.5337 17.8667 25.3337ZM14.6667 25.867V20.667H18.9334C18.0001 23.6003 16.2667 25.467 14.6667 25.867ZM13.3334 25.867C11.7334 25.467 10.0001 23.6003 9.06675 20.667H13.3334V25.867ZM10.1334 25.3337C7.60008 24.5337 5.46675 22.8003 4.00008 20.667H7.60008C8.26675 22.5337 9.20008 24.267 10.1334 25.3337Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Country;
