import React, { useState } from "react";
import moment from "moment";
import Flex from "components/flex";
import Text from "components/text";
import theme from "src/theme";
import { Box } from "nhweb-shared/components/Box";

const QuarterlySummaryPeriod = ({ startMonth, endMonth, year }) => (
  <Text color={"neutral300"} fontSize={"12px"} lineHeight={"22px"} mb={1}>
    {`${startMonth} - ${endMonth} ${year}`}
  </Text>
);

const QuarterlySummaryItem = ({ item }) => (
  <Box textTransform={"capitalize"} mb={1}>
    <Text color={"neutral300"} fontSize={"14px"} lineHeight={"22px"} pl={0}>
      <li style={{ paddingLeft: "10px" }}>{`${item.name}: ${item.value}`}</li>
    </Text>
  </Box>
);

const QuarterlySummary = ({
  startCurrentQuarterOfTheYear,
  endCurrentQuarterOfTheYear,
  currentYear,
  quarterlySummary
}) => {
  const [togglePostSummary, setTogglePostSummary] = useState(false);

  return (
    <Flex
      backgroundColor={"white"}
      borderRadius={"8px"}
      aspectRatio={"16/9"}
      width={"100%"}
      border={theme.borders}
      flexDirection={"column"}
      padding={"25px 25px"}
      fontSize={2}
      height={togglePostSummary ? "auto" : "100px"}
      onClick={() => setTogglePostSummary(!togglePostSummary)}
    >
      {"Quarterly Post Summary"}
      <Flex
        width={"100%"}
        flexDirection={"row"}
        borderBottom={togglePostSummary ? theme.borders : "none"}
        pb={"10px"}
        pt={"10px"}
      >
        <QuarterlySummaryPeriod
          startMonth={startCurrentQuarterOfTheYear}
          endMonth={endCurrentQuarterOfTheYear}
          year={currentYear}
        />
      </Flex>
      {togglePostSummary && (
        <ul style={{ paddingLeft: "15px" }}>
          {Object.keys(quarterlySummary).map(categoryId => {
            return (
              <QuarterlySummaryItem
                key={categoryId}
                item={quarterlySummary[categoryId]}
              />
            );
          })}
        </ul>
      )}
    </Flex>
  );
};

const AgencyQuarterlyReport = ({
  quarterlySummary,
  quarterlySummaryPeriod
}) => {
  const startCurrentQuarterOfTheYearMoment = moment(
    quarterlySummaryPeriod.start_date
  )
    .utc()
    .startOf("quarter");
  const endCurrentQuarterOfTheYearMoment = moment(
    quarterlySummaryPeriod.end_date
  )
    .utc()
    .endOf("quarter");

  const startCurrentQuarterOfTheYear =
    startCurrentQuarterOfTheYearMoment.format("MMMM");
  const endCurrentQuarterOfTheYear =
    endCurrentQuarterOfTheYearMoment.format("MMMM");

  const currentYear = startCurrentQuarterOfTheYearMoment.format("YYYY");

  return (
    <QuarterlySummary
      startCurrentQuarterOfTheYear={startCurrentQuarterOfTheYear}
      endCurrentQuarterOfTheYear={endCurrentQuarterOfTheYear}
      currentYear={currentYear}
      quarterlySummary={quarterlySummary}
    />
  );
};

export default AgencyQuarterlyReport;
