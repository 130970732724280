import { noop } from "ramda-adjunct";
import React, { ReactNode, useMemo } from "react";
import { useToggle } from "react-use";
import { createContext } from "src/nhweb-shared/utils";

export type ThumbnailType = "dashes" | "visible";

export interface UpdateStateValue {
  isPlaying: boolean;
}

export const [StateProvider, useMediaGalleryState] =
  createContext<UpdateStateValue>({
    displayName: "MediaGalleyStateContext",
    initialValue: {
      isPlaying: false
    }
  });

interface UpdateContextValue {
  toggleIsPlaying: (nextValue?: any) => void;
}

export const [UpdateProvider, useMediaGalleryUpdate] =
  createContext<UpdateContextValue>({
    displayName: "MediaGalleyUpdateContext",
    initialValue: {
      toggleIsPlaying: noop
    }
  });

export interface EventMediaGalleryProviderProps {
  children: ReactNode;
}

export const EventMediaGalleryProvider = ({
  children
}: EventMediaGalleryProviderProps) => {
  const [isHover] = useToggle(false);
  const [isPlaying, toggleIsPlaying] = useToggle(false);

  const stateValue = useMemo(
    () => ({
      isHover,
      isPlaying
    }),
    [isHover, isPlaying]
  );

  const updateValue = useMemo(() => ({ toggleIsPlaying }), [toggleIsPlaying]);

  return (
    <StateProvider value={stateValue}>
      <UpdateProvider value={updateValue}>{children}</UpdateProvider>
    </StateProvider>
  );
};
