import React, { useLayoutEffect, useState } from "react";
import Box from "components/box";
import Flex from "components/flex";
import Close from "components/icons/close";
import Expand from "components/icons/expand";
import GpsCenter from "components/icons/gps-center";
import Plus from "components/icons/plus";
import Subtract from "components/icons/subtract";
import { MapPolygon } from "src/nhweb-shared/components/Map";
import styled from "styled-components";
import { centerOf, getBounds } from "src/nhweb-shared/utils";
import { Map } from "react-map-gl";

const MapButton = styled.button`
  align-items: center;
  border-radius: 50px;
  border: 50px;
  color: white;
  background-color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  outline: none;
  width: 40px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
`;

const AgencyProfileMap = ({
  height = "100%",
  width = "100%",
  polygon,
  isMobileScreen,
  config
}) => {
  const center = centerOf(polygon);
  const bounds = getBounds(polygon);
  const [expanded, setExpanded] = useState(false);
  const [map, setMap] = useState(null);

  const onLoad = event => {
    setMap(event.target);
    setTimeout(() => {
      event.target.fitBounds(bounds, { padding: 25, linear: false });
    }, 200);
  };

  const onReCenterClick = () => {
    if (map) {
      map.fitBounds(bounds, { padding: 25 });
    }
  };
  const onZoomInClick = () => {
    if (map) {
      map.zoomIn();
    }
  };
  const onZoomOutClick = () => {
    if (map) {
      map.zoomOut();
    }
  };
  const onCloseClick = () => {
    if (!map) return;

    setExpanded(false);
    setTimeout(() => {
      map.resize();
      map.fitBounds(bounds, { padding: 25 });
    }, 200);
  };
  const onExpandClick = () => {
    if (!map) return;

    setExpanded(true);
    setTimeout(() => {
      map.resize();
      map.fitBounds(bounds, { padding: 25 });
    }, 200);
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      if (map) {
        map.resize();
        map.fitBounds(bounds, { padding: 25 });
      }
    };

    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [map]);

  return (
    <Flex
      flexDirection={"column"}
      height={height}
      width={width}
      position={expanded ? "absolute" : null}
      top={expanded ? "0" : null}
      bottom={expanded ? "0" : null}
      left={expanded ? "0" : null}
      right={expanded ? "0" : null}
      zIndex={expanded ? "9999" : null}
    >
      {expanded ? (
        <>
          <Flex
            width={"100%"}
            padding={"20px 40px 20px 80px"}
            alignItems={"flex-start"}
            rowGap={"16px"}
            columnGap={"16px"}
            boxShadow={"0px -1px 0px 0px #DEE5EC inset"}
          >
            <Flex
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              flex={"1 0 0"}
              textAlign={"center"}
              fontSize={"14px"}
              fontWeight={"500"}
              lineHeight={"24px"}
            >
              {"Agency Jurisdiction"}
            </Flex>
            <Flex width={"24px"} height={"24px"} flexShrink={"0"}>
              <Close onClick={onCloseClick} />
            </Flex>
          </Flex>
        </>
      ) : null}
      {config && config.MAPBOX_TOKEN ? (
        <Map
          mapboxAccessToken={config.MAPBOX_TOKEN}
          mapStyle={"mapbox://styles/mapbox/streets-v10"}
          center={center}
          onLoad={onLoad}
          containerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "8px 8px 0 0"
          }}
        >
          <MapPolygon polygon={polygon} />
          {expanded ? (
            // Map Zoom Controls
            <Flex
              flexDirection={"column"}
              backgroundColor={"unset"}
              position={"absolute"}
              top={isMobileScreen ? "12px" : null}
              right={"12px"}
              bottom={!isMobileScreen ? "40px" : null}
              zIndex={"10"}
              width={"40px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box backgroundColor={"unset"} paddingBottom={"20px"}>
                <MapButton>
                  <GpsCenter
                    width={"24px"}
                    height={"24px"}
                    onClick={onReCenterClick}
                  />
                </MapButton>
              </Box>
              <Box backgroundColor={"unset"} paddingBottom={"20px"}>
                <MapButton>
                  <Plus
                    width={"24px"}
                    height={"24px"}
                    onClick={onZoomInClick}
                  />
                </MapButton>
              </Box>
              <Box backgroundColor={"unset"} paddingBottom={"20px"}>
                <MapButton>
                  <Subtract
                    width={"24px"}
                    height={"24px"}
                    onClick={onZoomOutClick}
                  />
                </MapButton>
              </Box>
            </Flex>
          ) : null}
          {!expanded ? (
            // Map Expand Controls
            <Flex
              backgroundColor={"unset"}
              position={"absolute"}
              top={"12px"}
              right={"12px"}
              zIndex={"10"}
              width={"40px"}
              height={"40px"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <MapButton onClick={onExpandClick}>
                <Expand width={"24px"} height={"24px"} />
              </MapButton>
            </Flex>
          ) : null}
        </Map>
      ) : null}
    </Flex>
  );
};

export default AgencyProfileMap;
