import React from "react";
import copy from "copy-to-clipboard";

import Box from "components/box";
import Text from "components/text";
import Flex from "components/flex";
// import { Link } from "src/nhweb-shared/components/Link";
import Button from "components/button";
import ShareArrow from "components/icons/share-arrow";

const ProfileTitle = ({ name, setDisplayMessageBar }) => {
  const handleShare = () => {
    copy(window.location);
    setDisplayMessageBar(true);
  };

  return (
    <Box
      backgroundColor={"#fff"}
      width={"100%"}
      paddingTop={{ sm: "32px", lg: "40px" }}
      paddingBottom={{ sm: 0, lg: "32px" }}
    >
      <Flex
        flexDirection={{ sm: "column", lg: "row" }}
        justifyContent={{ sm: "left", lg: "center" }}
        alignItems={"start"}
        maxWidth={"1080px"}
        width={"100%"}
        paddingLeft={{ sm: "24px", lg: "32px" }}
        paddingRight={"15px"}
        marginLeft={"auto"}
        marginRight={"auto"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          paddingBottom={{ sm: "16px", lg: 0 }}
        >
          <Text
            fontSize={"24px"}
            lineHeight={"36px"}
            paddingBottom={"4px"}
            fontWeight={"500"}
          >
            {name}
          </Text>
          {/* TODO: P1 - POPO-5351  */}
          {/* <Link
            fontSize={"16px"}
            lineHeight={"24px"}
            textDecoration={"underline"}
            color={"primary400"}
            fontWeight={"500"}
          >
            {"Add Description"}
          </Link> */}
        </Flex>
        <Flex
          height={"36px"}
          flexDirection={"column"}
          justifyContent={{ sm: "start", lg: "center" }}
          alignItems={{ sm: "start", lg: "end" }}
          flexGrow={{ sm: 0, lg: 1 }}
          marginBottom={{ sm: "24px", lg: 0 }}
        >
          <Button
            size={"sm"}
            textStyle={"body-small-light"}
            variant={"primary"}
            width={"99px"}
            height={"32px"}
            borderRadius={"25px"}
            onClick={handleShare}
          >
            <Box backgroundColor={"unset"} marginLeft={"2px"}>
              <ShareArrow width={"24px"} height={"24px"} fill={"#fff"} />
            </Box>
            <Text
              color={"white"}
              fontSize={"18px"}
              lineHeight={"28px"}
              marginLeft={"6px"}
              marginRight={"6px"}
              fontWeight={"500"}
            >
              {"Share"}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProfileTitle;
