import shouldForwardProp from "@styled-system/should-forward-prop";
import {
  ComponentPropsWithoutRef,
  NamedExoticComponent,
  ReactElement,
  ReactNode
} from "react";
import { isDevelopmentBuild } from "src/utils";
import styled, { IntrinsicElementsKeys } from "styled-components";

import type {
  CSSProps,
  ExtraProps,
  PseudoProps,
  StyledSystemProps,
  SxProps,
  TextStyleProps,
  VariantProps
} from "./Props";
import {
  css,
  defaults,
  pseudos,
  styledSystem,
  sx,
  textStyle,
  variant
} from "./Props";

export interface BoxOwnProps
  extends CSSProps,
    SxProps,
    ExtraProps,
    PseudoProps,
    StyledSystemProps,
    VariantProps,
    TextStyleProps {}

export type BoxProps<T extends IntrinsicElementsKeys = "div"> = Omit<
  ComponentPropsWithoutRef<T>,
  keyof BoxOwnProps
> &
  BoxOwnProps & {
    children?: ReactNode;
    as?: T;
  };

export type BoxBasedComponentProps<
  // the intrinsic element the component ends up rendering
  T extends IntrinsicElementsKeys = "div",
  // the additional props the component accepts
  P extends {} = {},
  // additional props the component doesn't accept
  K extends keyof BoxProps<T> | "" = ""
> = Omit<BoxProps<T>, K | keyof P> & P;

export interface ForwardRefBoxComponent<
  T extends IntrinsicElementsKeys = IntrinsicElementsKeys
> extends Pick<NamedExoticComponent, "displayName"> {
  <K extends T>(
    props: BoxProps<K> & Pick<JSX.IntrinsicElements[T], "ref">
  ): ReactElement | null;
}

export const Box = styled.div.withConfig<BoxOwnProps>({ shouldForwardProp })(
  defaults,
  css,
  styledSystem,
  variant,
  pseudos,
  textStyle,
  sx
) as ForwardRefBoxComponent;

if (isDevelopmentBuild) {
  Box.displayName = "Box";
}
