import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const PublicAgency = ({
  fill = "none",
  height = "28",
  viewBox = "0 0 28 28",
  width = "28",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M22.6537 12.6667H27.3337L14.0003 0.666656L0.666992 12.6667H5.32033V23.3333H2.00033V24.6667H26.0004V23.3333H22.6537V12.6667ZM4.147 11.3333L14.0003 2.46666L23.8404 11.3333H4.147ZM7.987 23.3333H6.65367V12.6667H7.987V23.3333ZM11.987 23.3333H9.32034V12.6667H11.987V23.3333ZM14.6537 23.3333H13.3203V12.6667H14.6537V23.3333ZM18.6537 23.3333H15.987V12.6667H18.6537V23.3333ZM21.3204 23.3333H19.987V12.6667H21.3204V23.3333Z"
      }
      fill={"#1998D5"}
    />
    <path d={"M25.987 26H2.00033V27.3333H25.987V26Z"} fill={"#1998D5"} />
  </StyledSvg>
);

export default PublicAgency;
