import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

interface BaseProps<T> {
  onSelect?: (value: T) => void;
}

interface SingleProps<T> extends BaseProps<T> {
  initialState?: T;
  multi?: false;
}

interface MultiProps<T> extends BaseProps<T[]> {
  initialState?: T[];
  multi: true;
}

type DispatchSetStateAction<T> = Dispatch<SetStateAction<T>>;

export function useSelectableItems<T>(
  props: MultiProps<T>
): [T[], (value: T) => void, DispatchSetStateAction<T[]>, () => void];

export function useSelectableItems<T>(
  props: SingleProps<T>
): [
  T | undefined,
  (value: T) => void,
  DispatchSetStateAction<T | undefined>,
  () => void
];

export function useSelectableItems<T>({
  multi = false,
  initialState = multi ? [] : undefined,
  onSelect
}: SingleProps<T> | MultiProps<T>) {
  const [selected, setSelected] = useState(initialState);

  const select = useMemo(
    () =>
      multi
        ? (value: T) => {
            (setSelected as DispatchSetStateAction<T[]>)(prevItems =>
              !prevItems.includes(value)
                ? [...prevItems, value]
                : prevItems.filter(x => x !== value)
            );
          }
        : (value: T) => {
            (setSelected as DispatchSetStateAction<T | undefined>)(selected =>
              value !== selected ? value : undefined
            );
          },
    [multi]
  );

  const clear = useCallback(() => {
    setSelected(initialState);
  }, [initialState]);

  useEffect(() => {
    onSelect?.(selected as any);
  }, [onSelect, selected]);

  return [selected, select, setSelected, clear];
}
