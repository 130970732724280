import AgencyUsageReports from "components/agency-usage-reports";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyReports, getAgency, getConfigVars } from "reducer";
import { agencyReportsDataFetch } from "src/actions";

const AgencyUsageReportsContainer = () => {
  const dispatch = useDispatch();
  const reports = useSelector(getAgencyReports);
  const agency = useSelector(getAgency);
  const configVars = useSelector(getConfigVars);

  useEffect(() => {
    dispatch(agencyReportsDataFetch());
  }, []);

  return (
    <AgencyUsageReports
      configVars={configVars}
      agency={agency}
      reports={reports}
    />
  );
};

export default AgencyUsageReportsContainer;
