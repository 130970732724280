import { setIsMobileScreen } from "actions";
import mediaQuery from "./media-query";

export default dispatch => {
  const dispatchSetIsMobileScreen = bool => dispatch(setIsMobileScreen(bool));

  const observer = mediaQuery();
  observer.onBreakpoint(dispatchSetIsMobileScreen);

  window.addEventListener("load", observer.onInit(dispatchSetIsMobileScreen));
};
