import React from "react";
import { Box, BoxBasedComponentProps, BoxProps } from "../Box";

export type MediaType = "img" | "video";

export type CardMediaProps<
  T extends MediaType,
  P extends {} = {},
  K extends keyof BoxProps<T> | "" = ""
> = BoxBasedComponentProps<
  T,
  P,
  K | "display" | "height" | "maxHeight" | "objectFit" | "width" | "sx"
>;

export const CardMedia = <T extends MediaType>(props: CardMediaProps<T>) => (
  <Box
    {...props}
    my={"0"}
    mx={"auto"}
    display={"block"}
    height={"100%"}
    maxHeight={"inherit"}
    objectFit={"contain"}
    width={"100%"}
    sx={{
      "&:focus": {
        outline: "none"
      },
      "&::-webkit-media-controls-fullscreen-button, &::-webkit-media-controls-overflow-button":
        {
          display: "none"
        },
      "&:not(:only-child)": {
        "&:first-child": {
          borderTopLeftRadius: "rounder",
          borderTopRightRadius: "rounder"
        },
        "&:last-child": {
          borderBottomLeftRadius: "rounder",
          borderBottomRightRadius: "rounder"
        }
      }
    }}
  />
);
