import "styled-components";

import { buttons } from "./buttons";
import { colors } from "./colors";
import { radii } from "./radii";
import { shadows } from "./shadows";
import { theme } from "./theme";

export type { Breakpoint, BreakpointValue } from "./breakpoints";

export type Theme = typeof theme;

declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}

export type Button = keyof typeof buttons;
export type Color = keyof typeof colors;
export type Shadow = keyof typeof shadows;
export type Radii = keyof typeof radii;

export interface ThemeProps {
  theme: Theme;
}
