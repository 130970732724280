import css, { SystemStyleObject } from "@styled-system/css";
import type { ThemeProps } from "src/nhweb-shared/theme";
import type { Falsy } from "utility-types";

export interface SxProps {
  sx?: (SystemStyleObject | Falsy)[] | SystemStyleObject | Falsy;
}

export const sx = ({ sx, theme }: SxProps & ThemeProps) => {
  if (!sx) {
    return;
  }

  const finalSx = Array.isArray(sx)
    ? (sx
        .flat(Infinity)
        .filter(Boolean)
        .map(style => (typeof style === "function" ? style(theme) : style))
        .reduce((acc, val) => ({ ...acc, ...val }), {}) as SystemStyleObject)
    : sx;

  return css(finalSx)(theme);
};
