import React from "react";
import styled from "styled-components";
import { display, height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${({ onClick }) => onClick && "cursor: pointer;"}
  ${display}
  ${height}
  ${width}
`;

const StyledPath = styled.path`
  fill: ${({ fill, theme }) => theme.colors[fill]};
`;

const Close = ({
  fill = "black",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
  ...props
}) => (
  <StyledSvg height={height} viewBox={viewBox} width={width} {...props}>
    <StyledPath
      fill={fill}
      fillRule={"evenodd"}
      d={
        "M10.08 11.92L2 3.906 3.92 2 12 10.016 20.08 2 22 3.905l-8.08 8.016L14 12l-.08.08L22 20.094 20.08 22 12 13.984 3.92 22 2 20.095l8.08-8.016L10 12l.08-.08z"
      }
      clipRule={"evenodd"}
    />
  </StyledSvg>
);

export default Close;
