import React from "react";

import { Icon, IconProps } from "../Icon";

type DropdownIndicatorProps = Omit<IconProps, "name">;

export const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <Icon
    name={"more"}
    color={"tertiary.700"}
    cursor={"pointer"}
    size={24}
    {...props}
  />
);
