import qs from "qs";

export enum ViewMode {
  PUBLIC = 0,
  ADMIN = 1
}

export const getViewModeFromSearchParams = (): ViewMode => {
  const params = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  if (params["view"] === "admin") {
    return ViewMode.ADMIN;
  }
  return ViewMode.PUBLIC;
};
