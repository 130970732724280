import { onFeedRequestNextPage } from "actions";
import AgencyFeed from "components/agency-feed";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getAgency,
  getIsLoadingAgencyPosts,
  getLastAgencyPostReached,
  getNextAgencyPostCursor,
  getConfigVars
} from "reducer";

import { getCategories } from "../../domain";

const AgencyFeedContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const config = useSelector(getConfigVars);
  const agency = useSelector(getAgency);

  const isLoadingPosts = useSelector(getIsLoadingAgencyPosts);
  const nextAgencyPostCursor = useSelector(getNextAgencyPostCursor);
  const lastAgencyPostReached = useSelector(getLastAgencyPostReached);

  const categories = Object.values(getCategories(config));

  const handleRequestPage = () => {
    if (!isLoadingPosts && !lastAgencyPostReached) {
      dispatch(
        onFeedRequestNextPage({
          id,
          nextCursor: nextAgencyPostCursor
        })
      );
    }
  };

  return (
    <AgencyFeed
      posts={agency.posts}
      categories={categories}
      isLoadingPosts={isLoadingPosts}
      onRequestPage={handleRequestPage}
      config={config}
    />
  );
};

export default AgencyFeedContainer;
