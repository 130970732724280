import React from "react";

import { Box, BoxProps } from "../Box";

export const getRel = (rel: string | undefined, target: string | undefined) => {
  return target === "_blank" ? "noreferrer" : rel;
};

export type LinkProps = Omit<BoxProps<"a">, "as">;

export const Link = ({ target, rel, ...props }: LinkProps) => {
  const finalRel = getRel(rel, target);

  return (
    <Box {...linkStyles} {...props} as={"a"} target={target} rel={finalRel} />
  );
};

export const linkStyles = {
  display: "inline",
  color: "primary.500",
  textDecoration: "none",
  cursor: "pointer"
} as LinkProps;
