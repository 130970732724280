import React, { useState } from "react";

import Box from "components/box";
import Button from "components/button";
import Flex from "components/flex";
import Text from "components/text";
import Close from "components/icons/close";

const MessageBar = ({
  bg = "transparent",
  active,
  backgroundColor = "#F0F8F0",
  closeAction,
  color = "#178019",
  height = 24,
  message,
  width = 24,
  toastTime = 3000
}) => {
  const [toast, setToast] = useState(false);

  if (active && !toast) {
    setToast(
      setTimeout(() => {
        clearAction();
      }, toastTime)
    );
  }

  const clearAction = () => {
    closeAction();
    clearTimeout(toast);
    setToast(false);
  };

  return (
    <Box display={active ? "unset" : "none"}>
      <Flex
        bg={bg}
        flexDirection={"row"}
        justifyContent={"center"}
        height={"56px"}
        alignItems={"center"}
        padding={"16 40 16 40"}
        width={1}
        zIndex={3}
        position={"fixed"}
        backgroundColor={backgroundColor}
        visibility={"hidden"}
        marginLeft={"auto"}
        marginRight={"auto"}
        textAlign={"center"}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor={backgroundColor}
          pl={4}
        >
          <Text
            color={color}
            fontSize={"14px"}
            fontWeight={"500"}
            height={height}
          >
            {message}
          </Text>
        </Flex>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"end"}
          backgroundColor={backgroundColor}
          flexGrow={1}
          pr={4}
        >
          <Button
            size={"sm"}
            onClick={clearAction}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
          >
            <Close height={height} width={width} mb={0} fill={color} />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default MessageBar;
