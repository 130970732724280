import React from "react";

import { BoxProps } from "../Box";
import { CardTitle } from "../Card";
import { useEventCardState } from "./context";

export type EventCardTitleProps = Omit<BoxProps, "children">;

export const EventCardTitle = (props: EventCardTitleProps) => {
  const {
    event: { title }
  } = useEventCardState();

  if (!title) {
    return null;
  }

  return (
    <CardTitle mb={2} wordBreak={"break-word"} {...props}>
      {title}
    </CardTitle>
  );
};
