import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { omit, map } from "ramda";
import { bindSelectorState } from "utils";

import ui, * as fromUi from "./ui";
import data, * as fromData from "./data";
import config, * as fromConfig from "./config";

export const {
  getIsLoadingAgency,
  getIsLoadingAgencyPosts,
  getIsLoadingAgencyMetrics,
  getIsMobileScreen
} = map(bindSelectorState("ui"), omit("default", fromUi));

export const {
  getAgency,
  getQuarterlySummary,
  getQuarterlySummaryPeriod,
  getNextAgencyPostCursor,
  getLastAgencyPostReached,
  getAgencyReports,
  getCurrentUser,
  getViewMode
} = map(bindSelectorState("data"), omit("default", fromData));

export const { getConfigVars } = map(
  bindSelectorState("config"),
  omit("default", fromConfig)
);

export default history =>
  combineReducers({
    router: connectRouter(history),
    ui,
    data,
    config
  });
