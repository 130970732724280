import {
  Context,
  Provider,
  createContext as createCtx,
  useContext as useCtx
} from "react";

export interface CreateContextOptions<T> {
  /**
   * If `true`, React will throw an Error if context is `undefined`
   * In some cases, you might want to support nested contexts, so you can set it to `false`
   */
  strict?: boolean;
  /**
   * Error message to throw if the context is `undefined`
   */
  errorMessage?: string;
  /**
   * The context initial value
   */
  initialValue?: T;
  /**
   * The display name of the context
   */
  displayName?: string;
}

type CreateContextReturn<T> = [Provider<T>, () => T, Context<T>];

/**
 * Creates a named context, provider, and hook.
 *
 * @param options create context options
 */
export function createContext<T>(options: CreateContextOptions<T> = {}) {
  const {
    displayName,
    errorMessage = "useContext must be inside a Provider with a value",
    initialValue,
    strict = true
  } = options;

  const Context = createCtx(initialValue);

  Context.displayName = displayName;

  const useContext = () => {
    const context = useCtx(Context);

    if (context === undefined && strict) {
      throw new Error(errorMessage);
    }

    return context;
  };

  return [Context.Provider, useContext, Context] as CreateContextReturn<T>;
}
