import { border, transitions } from "polished";

import { Color } from "./types";

const transitionDuration = ".15s";

const transition = transitions(
  `color ${transitionDuration} ease-in-out`,
  `background-color ${transitionDuration} ease-in-out`,
  `border-color ${transitionDuration} ease-in-out`,
  `box-shadow ${transitionDuration} ease-in-out`
);

const base = (backgroundColor: Color) => ({
  ...transition,
  ...border("1px", "solid", "transparent"),
  backgroundColor: `${backgroundColor}.500`,
  color: "white",
  "&:hover": {
    backgroundColor: `${backgroundColor}.hover`
  }
});

const inverted = (color: Color) => ({
  ...transition,
  ...border("1px", "solid", "transparent"),
  backgroundColor: "white",
  color: `${color}.500`
});

const outline = (color: Color) => ({
  ...transition,
  ...border("1px", "solid", `${color}.500`),
  backgroundColor: "transparent",
  color: `${color}.500`,
  "&:hover": {
    backgroundColor: `${color}.500`,
    color: "white"
  }
});

const colors = ["primary", "secondary", "positive", "negative"] as const;

const extras = {
  empty: {
    backgroundColor: "transparent",
    border: "none",
    color: "primary.500"
  }
} as const;

type ButtonColor = typeof colors[number];
type InvertedColor = `inverted-${ButtonColor}`; // eslint-disable-line
type OutlineColor = `outline-${ButtonColor}`; // eslint-disable-line

type BaseReturnType = ReturnType<typeof base>;
type InvertedReturnType = ReturnType<typeof inverted>;
type OutlineReturnType = ReturnType<typeof outline>;

type ColorVariants = {
  [_ in ButtonColor]: BaseReturnType;
} &
  { [_ in InvertedColor]: InvertedReturnType } &
  { [_ in OutlineColor]: OutlineReturnType };

const variants = colors.reduce((acc, c) => {
  acc[c] = base(c);
  acc[`inverted-${c}` as InvertedColor] = inverted(c);
  acc[`outline-${c}` as OutlineColor] = outline(c);

  return acc;
}, {} as ColorVariants);

export const buttons = {
  ...variants,
  ...extras
} as const;
