import React from "react";

import Flex from "components/flex";
import Text from "components/text";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledSectionLink = styled(NavLink)`
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  border: ${props => props.theme.borders[1]};
  border-radius: 4px;
  &:hover,
  &.section-active {
    background: ${props => props.theme.colors.primary100};
    border: 2px solid ${props => props.theme.colors.primary500};
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const PanelSection = ({ icon, title, to, content, mb = 2, pt = "24px" }) => {
  return (
    <Flex mb={mb} width={1} pt={pt} flexDirection={"column"}>
      <Flex
        bg={"transparent"}
        borderBottom={{ sm: 1, md: 0 }}
        px={{ sm: "24px", md: 0 }}
      >
        <Text color={"neutral300"} fontSize={1} fontWeight={2} mb={2}>
          {title}
        </Text>
      </Flex>
      <StyledSectionLink
        width={1}
        to={{
          pathname: to,
          search: location.search
        }}
        activeClassName={"section-active"}
        exact={true}
      >
        <Flex px={"24px"} py={"20px"} bg={"transparent"} alignItems={"center"}>
          <Flex
            justifyContent={"center"}
            width={"32px"}
            height={"32px"}
            bg={"transparent"}
            alignItems={"center"}
          >
            {icon}
          </Flex>
          <Flex ml={3} bg={"transparent"}>
            <Text fontSize={2} fontWeight={2}>
              {content}
            </Text>
          </Flex>
        </Flex>
      </StyledSectionLink>
    </Flex>
  );
};

export default PanelSection;
