import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Subtract = ({
  fill = "none",
  viewBox = "0 0 24 24",
  height = "24",
  width = "24",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      d={
        "M21.5 12.5H2.5C2.2 12.5 2 12.3 2 12C2 11.7 2.2 11.5 2.5 11.5H21.5C21.8 11.5 22 11.7 22 12C22 12.3 21.8 12.5 21.5 12.5Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Subtract;
