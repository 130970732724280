import React from "react";

import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";

export { toast } from "react-toastify";

interface CloseToastButtonProps extends ButtonProps {
  closeToast: () => void;
}

export const CloseToastButton = ({
  closeToast,
  ...props
}: CloseToastButtonProps) => (
  <Button {...props} onClick={closeToast} p={0} variant={"empty"}>
    <Icon name={"close"} color={"neutral.300"} size={14} />
  </Button>
);
