import { Event } from "src/utils/gql-schema";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState
} from "react";
import { useCounter } from "react-use";
import { createContext } from "src/nhweb-shared/utils";

import { NormalizedEvent, useNormalizedEvent } from "./hooks";

interface EventCardStateContextValue {
  event: NormalizedEvent;
  isDetail?: boolean;
  eventAreaCenter: [number, number]; // TODO: provide at a higher level
}

const [EventCardStateProvider, useEventCardState] =
  createContext<EventCardStateContextValue>({
    displayName: "EventCardStateContext"
  });

interface EventCardUpdateContextValue {
  incrementVoteCount: () => void;
  decrementVoteCount: () => void;
  setHasUpvoted: Dispatch<SetStateAction<boolean>>;
}

const [EventCardUpdateProvider, useEventCardUpdate] =
  createContext<EventCardUpdateContextValue>({
    displayName: "EventCardUpdateContext"
  });

export interface EventCardProviderProps
  extends Omit<EventCardStateContextValue, "event"> {
  event: Event;
  children: ReactNode;
}

export const EventCardProvider = ({
  event,
  isDetail,
  eventAreaCenter,
  children
}: EventCardProviderProps) => {
  const normalized = useNormalizedEvent(event);

  const [hasUpvoted, setHasUpvoted] = useState(normalized.has_upvoted);

  const [upvoteCount, { inc: incrementVoteCount, dec: decrementVoteCount }] =
    useCounter(normalized.upvote_count);

  const finalEvent = useMemo(
    () => ({
      ...normalized,
      has_upvoted: hasUpvoted,
      upvote_count: upvoteCount
    }),
    [normalized, hasUpvoted, upvoteCount]
  );

  const stateValue = useMemo(
    () => ({ event: finalEvent, isDetail, eventAreaCenter }),
    [finalEvent, isDetail, eventAreaCenter]
  );

  const updateValue = useMemo(
    () => ({
      incrementVoteCount,
      decrementVoteCount,
      setHasUpvoted
    }),
    [incrementVoteCount, decrementVoteCount]
  );

  return (
    <EventCardStateProvider value={stateValue}>
      <EventCardUpdateProvider value={updateValue}>
        {children}
      </EventCardUpdateProvider>
    </EventCardStateProvider>
  );
};

export { useEventCardState, useEventCardUpdate };
