import { createContext } from "src/nhweb-shared/utils";
import { ModalProps } from "./Modal";
import { UseModalReturn } from "./useModal";

type ModalContext = UseModalReturn &
  Pick<ModalProps, "isCentered" | "scrollBehavior" | "size">;

const [ModalContextProvider, useModalContext] = createContext<ModalContext>({
  strict: true,
  displayName: "ModalContext"
});

export { ModalContextProvider, useModalContext };
