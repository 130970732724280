import type { EventCategory } from "src/utils/gql-schema";
import { createContext } from "../utils";

export const [EventCategoriesProvider, useEventCategories] = createContext<
  readonly EventCategory[]
>({
  displayName: "EventCategoriesContext"
});

export const useEventCategory = (category: string) => {
  const categories = useEventCategories();
  const target = categories.find(({ id }) => id === category);

  return target!;
};
