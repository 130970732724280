import type { CSSSelectorObject, SystemStyleObject } from "@styled-system/css";
import { css } from "@styled-system/css";

type PseudoPropsKeys =
  | "_active"
  | "_after"
  | "_before"
  | "_checked"
  | "_disabled"
  | "_even"
  | "_expanded"
  | "_first"
  | "_focus"
  | "_focusWithin"
  | "_grabbed"
  | "_groupHover"
  | "_hover"
  | "_invalid"
  | "_last"
  | "_mixed"
  | "_notFirst"
  | "_notLast"
  | "_odd"
  | "_placeholder"
  | "_pressed"
  | "_readOnly"
  | "_selected"
  | "_visited";

export type PseudoProps = {
  [_ in PseudoPropsKeys]?: SystemStyleObject;
};

export const pseudos = ({
  _active,
  _after,
  _before,
  _checked,
  _disabled,
  _even,
  _expanded,
  _first,
  _focus,
  _focusWithin,
  _grabbed,
  _groupHover,
  _hover,
  _invalid,
  _last,
  _mixed,
  _notFirst,
  _notLast,
  _odd,
  _placeholder,
  _pressed,
  _readOnly,
  _selected,
  _visited
}: PseudoProps) => {
  const object = {
    [active]: _active,
    [after]: _after,
    [before]: _before,
    [checked]: _checked,
    [disabled]: _disabled,
    [even]: _even,
    [expanded]: _expanded,
    [first]: _first,
    [focus]: _focus,
    [focusWithin]: _focusWithin,
    [grabbed]: _grabbed,
    [groupHover]: _groupHover,
    "@media (hover: hover)": {
      [hover]: _hover
    },
    [invalid]: _invalid,
    [last]: _last,
    [mixed]: _mixed,
    [notFirst]: _notFirst,
    [notLast]: _notLast,
    [odd]: _odd,
    [placeholder]: _placeholder,
    [pressed]: _pressed,
    [readOnly]: _readOnly,
    [selected]: _selected,
    [visited]: _visited
  } as CSSSelectorObject;

  return css(object);
};

const active = "&:active, &[data-active=true]";
const after = "&:after";
const before = "&:before";
const checked = "&[aria-checked=true]";
const disabled =
  "&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover";
const even = "&:nth-of-type(even)";
const expanded = "&[aria-expanded=true]";
const first = "&:first-of-type";
const focus = "&:focus";
const focusWithin = "&:focus-within";
const grabbed = "&[aria-grabbed=true]";
const groupHover = "[role=group]:hover &";
const hover = "&:hover";
const invalid = "&[aria-invalid=true]";
const last = "&:last-of-type";
const mixed = "&[aria-checked=mixed]";
const notFirst = "&:not(:first-of-type)";
const notLast = "&:not(:last-of-type)";
const odd = "&:nth-of-type(odd)";
const placeholder = "&::placeholder";
const pressed = "&[aria-pressed=true]";
const readOnly = "&[aria-readonly=true], &[readonly]";
const selected = "&[aria-selected=true]";
const visited = "&:visited";
