import React from "react";

const RingLogo = () => (
  <svg
    width={"78"}
    height={"49"}
    viewBox={"0 0 78 49"}
    fill={"none"}
    xmlns={"http://www.w3.org/2000/svg"}
  >
    <path
      d={
        "M22.204 5.30684C23.6694 5.30684 24.8574 4.11886 24.8574 2.65342C24.8574 1.18798 23.6694 0 22.204 0C20.7385 0 19.5505 1.18798 19.5505 2.65342C19.5505 4.11886 20.7385 5.30684 22.204 5.30684Z"
      }
      fill={"#1998D5"}
    />
    <path
      d={
        "M15.9955 12.6937C15.9971 13.1052 15.8282 13.4989 15.5289 13.7812C15.2279 14.0829 14.816 14.2474 14.39 14.236C13.9815 14.2238 13.5928 14.057 13.3025 13.7693L13.2511 13.7179C13.2206 13.687 13.186 13.6604 13.1483 13.6388C13.1085 13.6187 13.0724 13.592 13.0415 13.5598C11.9208 12.6399 10.5091 12.1493 9.05944 12.1757C7.19559 12.1757 5.73772 12.8137 4.68585 14.0896C3.63397 15.3656 3.10672 17.1767 3.10408 19.523V36.357C3.13639 36.8019 2.95417 37.2354 2.61373 37.5236C2.31661 37.7736 1.94223 37.9133 1.55395 37.919C1.16566 37.9133 0.791285 37.7736 0.49416 37.5236C0.15319 37.2358 -0.0291535 36.802 0.0038111 36.357V19.523C-0.00665429 18.1487 0.185214 16.7803 0.573248 15.4618C0.924457 14.2589 1.5045 13.1351 2.28156 12.152C3.04038 11.1956 4.0038 10.4214 5.10106 9.8861C6.33808 9.31009 7.69132 9.02727 9.05549 9.05962C10.2252 9.07979 11.382 9.30741 12.4721 9.73188C13.5952 10.1291 14.619 10.7644 15.4735 11.5944C15.7912 11.8735 15.98 12.2711 15.9955 12.6937Z"
      }
      fill={"#535556"}
    />
    <path
      d={
        "M23.754 36.357C23.7863 36.8019 23.6041 37.2354 23.2637 37.5236C22.6512 38.0433 21.7526 38.0433 21.1402 37.5236C20.7992 37.2358 20.6168 36.802 20.6498 36.357V11.1397C20.6175 10.6948 20.7997 10.2613 21.1402 9.97312C21.7532 9.45508 22.6506 9.45508 23.2637 9.97312C23.6036 10.2617 23.7857 10.6949 23.754 11.1397V36.357Z"
      }
      fill={"#535556"}
    />
    <path
      d={
        "M50.6679 36.357C50.6992 36.8025 50.5155 37.2362 50.1736 37.5235C49.5618 38.041 48.6658 38.041 48.054 37.5235C47.713 37.2358 47.5307 36.802 47.5637 36.357V19.1632C47.5701 18.2297 47.3843 17.305 47.0179 16.4465C46.3063 14.7687 44.9706 13.4331 43.2929 12.7214C41.5541 11.9973 39.5983 11.9973 37.8595 12.7214C36.182 13.4334 34.8465 14.769 34.1344 16.4465C33.7702 17.3055 33.5858 18.2301 33.5927 19.1632V36.357C33.6256 36.802 33.4433 37.2358 33.1023 37.5235C32.4899 38.0432 31.5913 38.0432 30.9788 37.5235C30.6378 37.2358 30.4555 36.802 30.4885 36.357V19.1632C30.4784 17.8111 30.7476 16.4716 31.2793 15.2285C31.7831 14.0307 32.512 12.9406 33.4266 12.0175C34.3376 11.1103 35.4106 10.3819 36.5901 9.87027C39.1171 8.7965 41.972 8.7965 44.499 9.87027C46.9212 10.8774 48.8462 12.8024 49.8533 15.2246C50.3843 16.4679 50.6536 17.8073 50.6442 19.1592L50.6679 36.357Z"
      }
      fill={"#535556"}
    />
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M76.2648 42.1225C76.8 40.8736 77.0694 39.5269 77.0557 38.1681H77.0399V19.1592C77.0493 17.8073 76.7801 16.4679 76.249 15.2246C75.2423 12.8021 73.3172 10.877 70.8947 9.87027C68.3678 8.7965 65.5128 8.7965 62.9859 9.87027C61.8064 10.3819 60.7333 11.1103 59.8224 12.0175C58.9072 12.9402 58.1782 14.0303 57.6751 15.2285C57.1434 16.4716 56.8741 17.8111 56.8842 19.1632V27.329C56.8698 28.6879 57.1392 30.0347 57.6751 31.2835C58.6949 33.6834 60.6058 35.5942 63.0057 36.614C64.2545 37.1495 65.6013 37.4189 66.9601 37.4049C68.2732 37.4103 69.5743 37.155 70.788 36.6536C71.9618 36.1774 73.0352 35.4838 73.9515 34.6091V38.1681C73.9499 39.0976 73.7574 40.0169 73.386 40.869C72.6744 42.5467 71.3387 43.8824 69.661 44.5941C68.8024 44.9602 67.8777 45.146 66.9443 45.1398C65.9162 45.1347 64.8953 44.9679 63.9191 44.6455C62.9354 44.3397 62.0509 43.7773 61.3567 43.0162L61.151 42.8067C61.1206 42.7808 61.102 42.7437 61.0996 42.7038C60.9654 42.4626 60.7556 42.2722 60.5025 42.1621C60.2977 42.0788 60.0791 42.0346 59.8579 42.0316C59.4446 42.0318 59.0469 42.1903 58.7468 42.4745C58.4251 42.7527 58.2452 43.1605 58.2564 43.5857C58.2463 43.8995 58.3363 44.2083 58.5134 44.4675C58.9523 45.1134 59.5043 45.6747 60.1427 46.1244C60.8156 46.6063 61.5446 47.0047 62.3136 47.3107C63.0805 47.6189 63.875 47.8533 64.6863 48.0107C65.4362 48.1617 66.199 48.2398 66.964 48.244C68.316 48.2532 69.6553 47.984 70.8987 47.4531C73.3165 46.4476 75.2433 44.5336 76.2648 42.1225ZM73.4058 16.4465C73.7722 17.305 73.958 18.2298 73.9515 19.1632V27.2856C73.9571 28.2265 73.7672 29.1583 73.394 30.022C72.6823 31.6998 71.3467 33.0354 69.6689 33.7471C67.9301 34.4712 65.9743 34.4712 64.2355 33.7471C63.4033 33.3964 62.6472 32.8874 62.0092 32.2484C61.3701 31.6095 60.8599 30.8537 60.5065 30.022C60.1401 29.1567 59.9557 28.2252 59.9647 27.2856V19.1592C59.9585 18.2262 60.1429 17.3018 60.5065 16.4425C61.2235 14.7638 62.5649 13.4295 64.2474 12.7214C65.9861 11.9973 67.942 11.9973 69.6808 12.7214C71.3585 13.4331 72.6942 14.7687 73.4058 16.4465Z"
      }
      fill={"#535556"}
    />
  </svg>
);

export default RingLogo;
