import React from "react";
import { Link } from "react-router-dom";
import { oneOfType, string, object, func } from "prop-types";
import styled, { css } from "styled-components";
import { color, display, fontSize, fontWeight, width } from "styled-system";

const commonStyles = css`
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
  ${color}
  ${display}
  ${fontSize}
  ${fontWeight}
  ${width}
`;

const StyledNativeLink = styled.a`
  ${commonStyles}
`;

const StyledLink = styled(Link)`
  ${commonStyles}
`;

const isLocalRoute = to => to.charAt(0) === "/";

const propTypes = {
  to: oneOfType([string, object, func]).isRequired
};

const LinkRouter = ({
  color = "primary500",
  display,
  fontSize = 2,
  fontWeight = 2,
  to,
  width,
  ...props
}) => {
  const LinkComponent = isLocalRoute(to) ? StyledLink : StyledNativeLink;
  return (
    <LinkComponent
      color={color}
      display={display}
      fontSize={fontSize}
      fontWeight={fontWeight}
      href={to}
      to={to}
      width={width}
      {...props}
    />
  );
};

LinkRouter.propTypes = propTypes;

export default LinkRouter;
