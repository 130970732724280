import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const GpsCenter = ({
  fill = "none",
  viewBox = "0 0 24 24",
  height = "1em",
  width = "1em",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path fill={"none"} d={"M0 0h24v24H0z"} />
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      fill={"#1998D5"}
      d={
        "M21.5 11.5H20C19.747 7.464 16.536 4.253 12.523 4.001L12.5 4V2.5C12.5 2.224 12.276 2 12 2C11.724 2 11.5 2.224 11.5 2.5V4C7.464 4.253 4.253 7.464 4.001 11.477L4 11.5H2.5C2.224 11.5 2 11.724 2 12C2 12.276 2.224 12.5 2.5 12.5H4C4.253 16.536 7.464 19.747 11.477 19.999L11.5 20V21.5C11.5 21.776 11.724 22 12 22C12.276 22 12.5 21.776 12.5 21.5V20C16.536 19.747 19.747 16.536 19.999 12.523L20 12.5H21.5C21.776 12.5 22 12.276 22 12C22 11.724 21.776 11.5 21.5 11.5ZM12.5 19V15.5C12.5 15.224 12.276 15 12 15C11.724 15 11.5 15.224 11.5 15.5V19C8.015 18.751 5.249 15.985 5.001 12.522L5 12.5H8.5C8.776 12.5 9 12.276 9 12C9 11.724 8.776 11.5 8.5 11.5H5C5.249 8.015 8.015 5.249 11.478 5.001L11.5 5V8.5C11.5 8.776 11.724 9 12 9C12.276 9 12.5 8.776 12.5 8.5V5C15.985 5.249 18.751 8.015 18.999 11.478L19 11.5H15.5C15.224 11.5 15 11.724 15 12C15 12.276 15.224 12.5 15.5 12.5H19C18.751 15.985 15.985 18.751 12.522 18.999L12.5 19ZM13.5 11.5H12.5V10.5C12.5 10.224 12.276 10 12 10C11.724 10 11.5 10.224 11.5 10.5V11.5H10.5C10.224 11.5 10 11.724 10 12C10 12.276 10.224 12.5 10.5 12.5H11.5V13.5C11.5 13.776 11.724 14 12 14C12.276 14 12.5 13.776 12.5 13.5V12.5H13.5C13.776 12.5 14 12.276 14 12C14 11.724 13.776 11.5 13.5 11.5Z"
      }
    />
  </StyledSvg>
);

export default GpsCenter;
