const logger = {
  init() {
    return;
  },

  info(message, data) {
    this._log({ level: "info", message, data });
  },

  error(message, data) {
    this._log({ level: "error", message, data });
  },

  _log({ level, message, data }) {
    console[level](message, data);
  }
};

export default logger;
