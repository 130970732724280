export enum Platform {
  WEB = 0,
  ANDROID = 1,
  IOS = 2
}

export enum ExecutionMode {
  EMBEDDED,
  STANDALONE
}

const isInApp = () =>
  typeof __NATIVE__BRIDGE__ === "object" || typeof window.ringapp === "object";

const isInIOS = () =>
  /iphone|ipod|ipad/.test(window.navigator.userAgent.toLowerCase());

const getPlatform = () => {
  if (isInApp()) {
    if (isInIOS()) {
      return Platform.IOS;
    } else {
      return Platform.ANDROID;
    }
  }
  return Platform.WEB;
};

export const getExecutionMode = () =>
  window.location !== window.parent.location
    ? ExecutionMode.EMBEDDED
    : ExecutionMode.STANDALONE;

export default getPlatform;
