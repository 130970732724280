import React from "react";

import Flex from "components/flex";
import Tab from "../tab";
import styled from "styled-components";
import { height, padding } from "styled-system";
import AgencyType from "src/constants/agency-type";

const StyledMobileTabs = styled(Flex)`
  align-items: center;
  border-bottom: ${props => props.theme.borders[1]};
  display: flex;
  flex-shrink: 0;
  ${height}
  ${padding}
`;

const MobileTabs = ({ agencyUuid, agencyType, showUsageReportNavigation }) => (
  <StyledMobileTabs width={1} flexDirection={"row"} height={"56px"}>
    <Tab title={"Information"} to={`/agencies/${agencyUuid}`} />
    {agencyType != AgencyType.NEWS_TEAM ? (
      <Tab title={"Posts"} to={`/agencies/${agencyUuid}/posts`} />
    ) : null}
    {showUsageReportNavigation && (
      <Tab title={"Reports"} to={`/agencies/${agencyUuid}/reports`} />
    )}
  </StyledMobileTabs>
);

export default MobileTabs;
