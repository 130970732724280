import React from "react";

import { OwnerHeaderBase, OwnerHeaderBaseProps } from "./OwnerHeaderBase";
import { getEventOwnerHeaderDisplayUsername } from "./utils";
import { Link } from "../Link";

export interface EventOwnerHeaderProps extends OwnerHeaderBaseProps {
  ownerUsername: string;
  publisherUsername: string | undefined;
}

export const EventOwnerHeader = ({
  agencyId,
  avatarUrl,
  description,
  logoUrl,
  isOwned,
  ownerUsername,
  publisherUsername,
  ...props
}: EventOwnerHeaderProps) => {
  const displayUsername = getEventOwnerHeaderDisplayUsername({
    isOwned,
    ownerUsername
  });

  return (
    <OwnerHeaderBase
      {...props}
      agencyId={agencyId}
      avatarUrl={avatarUrl}
      description={description}
      logoUrl={logoUrl}
      isOwned={isOwned}
    >
      {publisherUsername != undefined ? (
        <Link href={`/agencies/${agencyId}`} target={"_blank"}>
          {publisherUsername}
        </Link>
      ) : (
        displayUsername
      )}
    </OwnerHeaderBase>
  );
};
