import { border } from "polished";
import React from "react";

import { Flex, FlexProps } from "../Flex";

export type CardHeaderProps = FlexProps;

export const CardHeader = (props: CardHeaderProps) => (
  <Flex {...borderStyle} p={3} {...props} />
);

const borderStyle = border("bottom", "1px", "solid", "borders");
