import React from "react";
import Link from "components/link";

const TelLinkReplacer = ({ phone, extension, ...props }) => {
  return (
    <Link to={`tel:${phoneLink(phone, extension)}`} {...props}>
      {phoneText(phone, extension)}
    </Link>
  );
};

const phoneLink = (phone, extension) => {
  if (extension) {
    return phone + "p" + extension;
  } else {
    return phone;
  }
};

const phoneText = (phone, extension) => {
  if (extension) {
    return phone + ` ext. ${extension}`;
  } else {
    return phone;
  }
};

export default TelLinkReplacer;
