import distance from "@turf/distance";
import React from "react";
import { equalsDefaultCoordsTuple } from "src/nhweb-shared/utils";

import { Box, BoxBasedComponentProps } from "../Box";

export type DistanceProps = BoxBasedComponentProps<
  "div",
  {
    from: [number, number];
    to: [number, number];
  }
>;

export const Distance = ({ from, to, ...props }: DistanceProps) => {
  if (equalsDefaultCoordsTuple(to)) {
    return null;
  }

  const miles = distance(from, to, { units: "miles" });
  const rounded = Math.round(miles * 10) / 10;

  return (
    <Box {...props}>
      {rounded}
      {"mi"}
    </Box>
  );
};
