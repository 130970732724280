import type {
  AppearanceProperty,
  BackfaceVisibilityProperty,
  CursorProperty,
  FontWeightProperty,
  ObjectFitProperty,
  OutlineProperty,
  ResizeProperty,
  TextDecorationProperty,
  TextTransformProperty,
  TransformProperty,
  TransitionProperty,
  UserSelectProperty,
  WhiteSpaceProperty,
  WordBreakProperty,
  WordWrapProperty
} from "csstype";
import type {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ResponsiveValue,
  ShadowProps,
  SpaceProps,
  TypographyProps
} from "styled-system";
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  system,
  typography
} from "styled-system";

export interface ExtraProps {
  appearance?: AppearanceProperty;
  backfaceVisibility?: BackfaceVisibilityProperty;
  cursor?: CursorProperty;
  objectFit?: ObjectFitProperty;
  outline?: OutlineProperty<string>;
  resize?: ResizeProperty;
  textDecoration?: TextDecorationProperty<string>;
  textTransform?: TextTransformProperty;
  transform?: TransformProperty;
  transition?: TransitionProperty;
  userSelect?: UserSelectProperty;
  whiteSpace?: WhiteSpaceProperty;
  wordBreak?: WordBreakProperty;
  wordWrap?: WordWrapProperty;
}

const extras = system({
  appearance: true,
  backfaceVisibility: true,
  cursor: true,
  objectFit: true,
  outline: true,
  resize: true,
  textDecoration: true,
  textTransform: true,
  transform: true,
  transition: true,
  userSelect: true,
  whiteSpace: true,
  wordBreak: true,
  wordWrap: true
});

interface FontWeightProps {
  fontWeight?: ResponsiveValue<FontWeightProperty | string>;
}

export type StyledSystemProps = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  Omit<TypographyProps, "fontWeight"> &
  FontWeightProps;

export const styledSystem = compose(
  background,
  border,
  color,
  extras,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  shadow,
  space,
  typography
);
