import { Category } from "./categories";
import { Metric } from "./metrics";

export interface QuarterlySummaryItem {
  readonly name: string;
  readonly value: number;
}

export type QuearterlySummary = Record<string, QuarterlySummaryItem>;

export const getDefaultQuarterlySummary = (
  categories: Record<string, Category>
): QuearterlySummary => {
  const defaults = {};

  Object.values(categories)
    .filter(category => !category.deleted)
    .forEach(category => {
      defaults[category.id] = {
        name: category.title,
        value: 0
      };
    });

  return defaults;
};

export const createQuarterlySummaryFromMetrics = (
  metrics: Metric[],
  categories: Record<string, Category>
): QuearterlySummary => {
  const quarterlySummary = getDefaultQuarterlySummary(categories);

  const postCountMetrics = metrics
    .filter(m => m.name === "PostCount")
    .filter(m => m.dimensions.some(d => d.name === "Category"));

  postCountMetrics.forEach(m => {
    const categoryId = m.dimensions.find(d => d.name === "Category")!.value;
    const category = categories[categoryId];

    if (!category) {
      return;
    }

    quarterlySummary[category.id] = {
      name: category.title,
      value: m.value
    };
  });

  return quarterlySummary;
};
