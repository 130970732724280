import { MutableRefObject, useEffect } from "react";

/**
 * Use Intersection Observer to run a callback when an element enters the viewport.
 */
export const useIntersectionEffect = (
  callback: IntersectionObserverCallback,
  ref: MutableRefObject<null | Element>
) => {
  useEffect(() => {
    const observer = new IntersectionObserver(callback);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [callback, ref]);
};
