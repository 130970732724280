import { Ref, useEffect } from "react";

class ModalManager {
  private modals: Ref<HTMLDivElement>[];

  constructor() {
    this.modals = [];
  }

  add(modal: Ref<HTMLDivElement>) {
    this.modals.push(modal);
  }

  remove(modal: Ref<HTMLDivElement>) {
    this.modals = this.modals.filter(_modal => _modal !== modal);
  }

  isTopModal(modal: Ref<HTMLDivElement>) {
    const top = this.modals[this.modals.length - 1];
    return top === modal;
  }
}

export const manager = new ModalManager();

export const useModalManager = (ref: Ref<HTMLDivElement>, isOpen?: boolean) => {
  useEffect(() => {
    if (isOpen) {
      manager.add(ref);
    }

    return () => {
      manager.remove(ref);
    };
  }, [isOpen, ref]);
};
