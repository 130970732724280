import React from "react";
import Linkify from "linkifyjs/react";
import theme from "theme";

import Text from "components/text";

const TextLinksReplacer = ({
  children,
  linkColor = "primary400",
  linkFontWeight = 2,
  ...props
}) => {
  const linkProps = {
    style: {
      color: theme.colors[linkColor],
      fontWeight: theme.fontWeights[linkFontWeight],
      textDecoration: "none"
    }
  };
  return (
    <Text {...props}>
      <Linkify options={{ attributes: linkProps }}>{children}</Linkify>
    </Text>
  );
};

export default TextLinksReplacer;
