import { css, get } from "@styled-system/css";
import type { ThemeProps } from "src/nhweb-shared/theme";
import type { ResponsiveValue } from "styled-system";

export interface VariantProps {
  variant?: ResponsiveValue<string>;
  tx?: string;
}

export const variant = ({
  theme,
  variant,
  tx = "variants"
}: ThemeProps & VariantProps) => {
  const defaultValue = get(theme, variant as string | string[]);

  return css(get(theme, `${tx}.${variant}`, defaultValue))(theme);
};
