import classnames from "classnames";
import React, { MouseEventHandler } from "react";

import { Box } from "../Box";
import { Icon } from "../Icon";

export const EventGalleryFullscreenButton = (
  onClick: MouseEventHandler<HTMLDivElement>,
  isFullscreen: boolean
) => {
  const className = classnames("image-gallery-fullscreen-button", {
    active: isFullscreen
  });

  return (
    <Box
      className={className}
      onClick={onClick}
      position={"absolute"}
      right={0}
      top={0}
      bottom={"auto"}
      cursor={"pointer"}
    >
      <Icon
        color={"whiteAlpha.900"}
        ml={1}
        name={isFullscreen ? "close" : "fullscreen"}
        size={32}
        filter={"drop-shadow(0 2px 2px black)"}
      />
    </Box>
  );
};
