import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const PhoneOn = ({
  fill = "none",
  height = "32",
  viewBox = "0 0 32 32",
  width = "32",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M10.5314 5.33333C10.9314 5.6 11.8647 6.4 12.3981 7.46667C12.9314 8.26667 13.3314 9.33333 13.3314 9.73333C13.3314 10 12.9314 10.6667 12.6647 11.0667C11.9981 12.1333 11.4647 13.2 11.5981 14.2667C11.7314 14.8 12.2647 16.4 14.1314 18.2667C15.9981 20.1333 17.5981 20.6667 18.1314 20.8C18.2647 20.8 18.3981 20.8 18.5314 20.8C19.4647 20.8 20.3981 20.2667 21.3314 19.7333C21.7314 19.4667 22.3981 19.0667 22.6647 19.0667C23.1981 19.0667 24.2647 19.6 24.9314 20C25.8647 20.6667 26.7981 21.4667 27.0647 21.8667C26.7981 22.6667 24.3981 25.0667 22.3981 25.6C22.1314 25.7333 21.8647 25.7333 21.5981 25.7333C18.9314 25.7333 14.9314 22.8 12.2647 20C9.33139 17.2 5.99806 12.6667 6.79806 10C7.33139 8 9.73139 5.6 10.5314 5.33333ZM10.6647 4C9.19806 4 6.26473 7.2 5.46473 9.6C4.39806 13.3333 9.06473 18.8 11.3314 21.0667C13.4647 23.2 17.9981 27.2 21.5981 27.2C21.9981 27.2 22.3981 27.2 22.7981 27.0667C25.3314 26.2667 28.7981 23.0667 28.3981 21.7333C27.9981 20.5333 25.8647 19.0667 25.7314 18.9333C25.5981 18.8 23.8647 17.7333 22.7981 17.7333C22.6647 17.7333 22.6647 17.7333 22.5314 17.7333C21.4647 17.8667 19.5981 19.4667 18.6647 19.4667H18.5314C17.8647 19.3333 16.5314 18.8 15.1981 17.3333C13.7314 15.8667 13.1981 14.6667 13.0647 14C12.7981 13.0667 14.6647 11.0667 14.7981 10C14.9314 8.93333 13.7314 7.06667 13.5981 6.8C13.4647 6.53333 12.1314 4.4 10.7981 4.13333C10.7981 4 10.6647 4 10.6647 4Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default PhoneOn;
