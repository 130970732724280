import React, { useCallback } from "react";
import { useCopyLinkToClipboard } from "src/nhweb-shared/hooks";

import { CardFooter, CardFooterProps } from "../Card";
import { useEventCardState, useEventCardUpdate } from "./context";
import {
  EventCardFooterCommentAction,
  EventCardFooterShareAction,
  EventCardFooterUpvoteAction
} from "./EventCardFooterAction";

export interface EventCardFooterProps extends CardFooterProps {
  onUpvote?: () => void;
  onDownvote?: () => void;
  onComment?: () => void;
}

export const EventCardFooter = ({
  onUpvote,
  onDownvote,
  onComment
}: EventCardFooterProps) => {
  const {
    event: { has_upvoted, share_url, upvote_count, comment_count }
  } = useEventCardState();

  const { decrementVoteCount, incrementVoteCount, setHasUpvoted } =
    useEventCardUpdate();

  const handleShare = useCopyLinkToClipboard(share_url);

  const handleUpvote = useCallback(() => {
    if (has_upvoted) {
      setHasUpvoted(false);
      decrementVoteCount();
      onDownvote?.();
    } else {
      setHasUpvoted(true);
      incrementVoteCount();
      onUpvote?.();
    }
  }, [
    has_upvoted,
    setHasUpvoted,
    incrementVoteCount,
    decrementVoteCount,
    onUpvote,
    onDownvote
  ]);

  return (
    <CardFooter px={4} py={3}>
      <EventCardFooterUpvoteAction
        onClick={handleUpvote}
        isActive={has_upvoted}
      >
        {upvote_count}
      </EventCardFooterUpvoteAction>
      <EventCardFooterCommentAction onClick={onComment}>
        {comment_count}
      </EventCardFooterCommentAction>
      <EventCardFooterShareAction onClick={handleShare} />
    </CardFooter>
  );
};
