import { equals } from "ramda";
import React, { Ref, forwardRef, memo } from "react";
import { EventMediaGallery } from "src/nhweb-shared/components/EventMediaGallery";

import { hoverStyles } from "../Card";
import { EventCard, EventCardFooterProps, EventCardProps } from "../EventCard";
import { FeedEventCardBody } from "./FeedEventCardBody";
import { FeedEventCardHeader } from "./FeedEventCardHeader";
import { EventCardEventInfoProps } from "../EventCard/EventCardEventInfo";

export interface FeedEventCardProps
  extends Omit<EventCardProps, "children">,
    EventCardFooterProps {
  isActive?: boolean;
  eventCardEventInfoProps?: EventCardEventInfoProps;
  readMoreDisabled?: boolean;
}

const FeedEventCard = (
  {
    event,
    onClick,
    isActive,
    onUpvote,
    onDownvote,
    onComment,
    ...props
  }: FeedEventCardProps,
  ref: Ref<HTMLDivElement>
) => {
  const { id, is_owned, owner, details } = event;
  const { agency_id, publisher_data, user_name, type } = owner!;
  const { media } = details!;

  const ownerHeaderProps = {
    agencyId: agency_id,
    avatarUrl: publisher_data?.avatar_url,
    description: publisher_data?.description,
    logoUrl: publisher_data?.logo_url,
    isOwned: is_owned,
    ownerUsername: user_name,
    ownerType: type,
    publisherUsername: publisher_data?.name
  } as const;

  const { eventCardEventInfoProps, readMoreDisabled } = props;

  return (
    <EventCard
      {...props}
      ref={ref}
      event={event}
      color={"neutral.500"}
      cursor={"pointer"}
      hoverable
      sx={isActive && hoverStyles}
      mb={4}
    >
      {({ isDropdownOpen, dropdownOptions, toggleIsDropdownOpen }) => (
        <>
          <FeedEventCardHeader
            dropdownIsOpen={isDropdownOpen}
            dropdownOptions={dropdownOptions}
            ownerHeaderProps={ownerHeaderProps}
            onDropdownClick={toggleIsDropdownOpen}
            onDropdownClickOutside={toggleIsDropdownOpen}
          />
          <EventMediaGallery
            eventId={id}
            media={media!}
            borderRadius={"regular"}
            thumbnailType={"dashes"}
            showIndex
          />
          <FeedEventCardBody
            onClick={onClick}
            /* NPSS Manual Change, this should be parameterized */
            infoProps={eventCardEventInfoProps}
            /* NPSS Manual Change, disabled read more support to allow anchor tag usage upstream. */
            readMoreDisabled={readMoreDisabled}
          />
          {/* NPSS Manual Change, this should be parameterized */}
          {/* <EventCardFooter
            onUpvote={onUpvote}
            onDownvote={onDownvote}
            onComment={onComment}
          /> */}
        </>
      )}
    </EventCard>
  );
};

const MemoizedRefForwardingFeedEventCard = memo(
  forwardRef(FeedEventCard),
  equals
);

export { MemoizedRefForwardingFeedEventCard as FeedEventCard };
