import React, { useState } from "react";
import { Spring } from "react-spring/renderprops";
import styled from "styled-components";

import Close from "components/icons/close";
import Flex from "components/flex";
import Hamburger from "components/icons/hamburger";
import NpssLogoIcon from "components/icons/neighbors";
import Ring from "components/icons/ring";
import QuestionMark from "components/icons/question-mark";
import Text from "components/text";

const DrawerContainer = styled(Flex)`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 50px;
  width: 100%;
  z-index: 99;
  > div {
    box-sizing: border-box;
  }
`;

const Item = styled(Flex)`
  align-items: center;
  cursor: pointer;
  padding: 16px 32px;
  width: 100%;
  &:hover {
    div {
      color: ${({ theme }) => theme.colors.blue};
    }
    .paintable {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
`;

const ItemText = styled(Text)`
  font-size: 16px;
  margin-left: 16px;
`;

const MobileSubmenu = ({ handleOpenLinkNewTab }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleClick = () => setIsDrawerOpen(!isDrawerOpen);
  const invisible = {
    transform: "translate3d(-100%, 0, 0)",
    overlayColor: "rgba(0,0,0,0)"
  };
  const visible = {
    transform: "translate3d(0%, 0, 0)",
    overlayColor: "rgba(0,0,0,0.2)"
  };

  return (
    <Flex alignItems={"center"} onClick={handleClick}>
      <Flex
        alignItens={"center"}
        justifyContent={"center"}
        mx={"26px"}
        width={"30px"}
      >
        {isDrawerOpen ? (
          <Close width={"12px"} height={"12px"} />
        ) : (
          <Hamburger />
        )}
      </Flex>
      <NpssLogoIcon />
      {isDrawerOpen !== undefined && (
        <Spring
          from={isDrawerOpen ? invisible : visible}
          to={isDrawerOpen ? visible : invisible}
          onRest={() => !isDrawerOpen && setIsDrawerOpen(undefined)}
        >
          {({ overlayColor, transform }) => (
            <DrawerContainer style={{ backgroundColor: overlayColor }}>
              <Flex
                flexDirection={"column"}
                height={"100%"}
                justifyContent={"space-between"}
                minWidth={"260px"}
                pb={3}
                style={{ transform }}
                width={"auto"}
              >
                <Flex flexDirection={"column"} mt={3} width={1}>
                  <Item pb={"24px"}>
                    <Ring />
                    <ItemText onClick={handleOpenLinkNewTab("http://ring.com")}>
                      {"Ring.com"}
                    </ItemText>
                  </Item>
                  <Item pb={"24px"}>
                    <QuestionMark />
                    <ItemText
                      onClick={handleOpenLinkNewTab(
                        "https://help.publicsafety.ring.com/"
                      )}
                    >
                      {"Help"}
                    </ItemText>
                  </Item>
                </Flex>
              </Flex>
            </DrawerContainer>
          )}
        </Spring>
      )}
    </Flex>
  );
};

export default MobileSubmenu;
