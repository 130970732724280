import React from "react";

import { Flex } from "../Flex";
import { Loading, LoadingProps } from "./Loading";

export const CenteredLoading = (props: LoadingProps) => (
  <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
    <Loading {...props} />
  </Flex>
);
