import React from "react";

import { CardTextProps } from "../Card";
import { useEventCardState } from "./context";
import { Link } from "../Link";
import Text from "components/text";
import theme from "src/theme";
import { Button } from "../Button";

export type EventCardLinkProps = Omit<CardTextProps, "children">;

export const EventCardLink = () => {
  const {
    event: { details_url, actions }
  } = useEventCardState();

  if (!details_url) {
    return null;
  }

  if (!actions || actions.length == 0) {
    return (
      <Link target={details_url} textDecoration={"underline"}>
        <Text
          color={theme.colors.primary400}
          underlined={true}
          fontWeight={300}
          mt={2}
        >
          {"View on Twitter"}
        </Text>
      </Link>
    );
  }
  return (
    <div>
      {actions.map(action => (
        <Button
          key={action.url}
          as={"a"}
          href={action.url}
          target={"_blank"}
          lineHeight={"32px"}
          minHeight={"45px"}
          width={1 / 1}
          mb={5}
        >
          {action.text}
        </Button>
      ))}
    </div>
  );
};
