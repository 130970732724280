import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  alignItems,
  backgroundImage,
  backgroundSize,
  borders,
  bottom,
  boxShadow,
  color,
  fontSize,
  height,
  justifyContent,
  minHeight,
  left,
  position,
  right,
  space,
  system,
  textAlign,
  top,
  width,
  zIndex,
  layout
} from "styled-system";

const StyledBox = styled.div`
  ${alignItems}
  ${backgroundImage}
  ${backgroundSize}
  ${borders}
  ${bottom}
  ${boxShadow}
  ${color}
  ${fontSize}
  ${height}
  ${justifyContent}
  ${minHeight}
  ${left}
  ${position}
  ${right}
  ${space}
  ${system({
    aspectRatio: true
  })}
  ${top}
  ${textAlign}
  ${width}
  ${zIndex}
  ${layout}
`;

const Box = forwardRef(
  (
    {
      alignItems,
      aspectRatio,
      backgroundImage,
      backgroundSize,
      bg = "white",
      border,
      borderBottom,
      boxShadow,
      borderRadius,
      bottom,
      color = "neutral500",
      fontSize = 0,
      minHeight,
      height,
      justifyContent,
      left,
      position,
      right,
      space,
      textAlign,
      top,
      width,
      zIndex,
      overflow,
      ...props
    },
    ref
  ) => (
    <StyledBox
      alignItems={alignItems}
      aspectRatio={aspectRatio}
      backgroundImage={backgroundImage}
      backgroundSize={backgroundSize}
      bg={bg}
      border={border}
      borderBottom={borderBottom}
      borderRadius={borderRadius}
      bottom={bottom}
      boxShadow={boxShadow}
      color={color}
      fontSize={fontSize}
      forwardedRef={ref}
      height={height}
      justifyContent={justifyContent}
      left={left}
      minHeight={minHeight}
      overflow={overflow}
      position={position}
      right={right}
      ref={ref}
      space={space}
      textAlign={textAlign}
      top={top}
      width={width}
      zIndex={zIndex}
      {...props}
    />
  )
);

export default Box;
