import React from "react";
import { WrapIf } from "src/nhweb-shared/utils";

import { Box, BoxBasedComponentProps } from "../Box";

export type HelperTextProps = BoxBasedComponentProps<
  "div",
  {
    children?: string | null;
    label?: string | null;
  }
>;

export const HelperText = ({
  color = "neutral.300",
  children,
  label,
  ...props
}: HelperTextProps) => {
  if (!children) {
    return null;
  }

  return (
    <WrapIf condition={label} wrapper={children => <>{children}</>}>
      <Box
        color={color}
        {...props}
        data-tip={label}
        data-for={"timeagoTooltip"}
      >
        {children}
      </Box>
    </WrapIf>
  );
};
