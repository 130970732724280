import React, { forwardRef } from "react";
import { isDevelopmentBuild } from "src/utils";

import { BoxProps } from "../Box";
import { Flex } from "../Flex";
import { getBorder, px, py } from "./utils";

type ModalFooterProps = Omit<BoxProps, "footer">;

export const ModalFooter = forwardRef<HTMLElement, ModalFooterProps>(
  (props, ref) => (
    <Flex
      alignItems={"center"}
      py={py}
      px={px}
      justifyContent={"flex-end"}
      flex={"0 0 auto"}
      {...getBorder("top")}
      {...props}
      as={"footer"}
      ref={ref}
    />
  )
);

if (isDevelopmentBuild) {
  ModalFooter.displayName = "ModalFooter";
}
