import "./polyfills";
import "regenerator-runtime/runtime";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ThemeProvider } from "styled-components";

import Root from "containers/root";
import { initEventHandlers } from "utils";
import configureStore, { history } from "./store";
import GlobalStyle from "global-style";
import theme from "./theme";

const store = configureStore();
initEventHandlers(store.dispatch);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {
        //@ts-ignore
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Root />
          </>
        </ThemeProvider>
      }
    </ConnectedRouter>
  </Provider>,
  document.getElementById("index")
);
