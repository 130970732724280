import React, { forwardRef, useMemo } from "react";
import { combineHandlers } from "src/nhweb-shared/utils";
import { isDevelopmentBuild } from "src/utils";

import { Button, ButtonProps } from "../Button";
import { Icon } from "../Icon";
import { useModalContext } from "./ModalContext";

type ModalCloseButtonProps = Omit<ButtonProps, "type" | "aria-label">;

export const ModalCloseButton = forwardRef<
  HTMLButtonElement,
  ModalCloseButtonProps
>(({ onClick, children, ...props }, ref) => {
  const { onClose } = useModalContext();

  const handleClick = useMemo(
    () => combineHandlers(onClick, onClose),
    [onClick, onClose]
  );

  return (
    <Button
      right={3}
      top={3}
      size={24}
      {...props}
      aria-label={"Close"}
      p={0}
      position={"absolute"}
      ref={ref}
      type={"button"}
      variant={"empty"}
      onClick={handleClick}
    >
      {children ?? close}
    </Button>
  );
});

if (isDevelopmentBuild) {
  ModalCloseButton.displayName = "ModalCloseButton";
}

const close = <Icon name={"close"} size={14} />;
