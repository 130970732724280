import { useMemo } from "react";

export interface Media {
  type: string;
  url: string;
}

export const useMediaObjects = (items: Media[]) => {
  const objects = useMemo(
    () =>
      items.map(({ url, type }) => ({
        original: url,
        thumbnail: url,
        description: type
      })),
    [items]
  );

  return objects;
};
