import React from "react";
import { Switch, Route } from "react-router-dom";

import Navigation from "containers/navigation";
import NotFound from "components/not-found";
import Agency, { AgencyMe } from "containers/agency";
import getPlatform, {
  getExecutionMode,
  ExecutionMode,
  Platform
} from "utils/get-platform";
import Flex from "../flex";

const App = () => (
  <Flex height={"100%"} flexDirection={"column"}>
    {getPlatform() === Platform.WEB &&
    getExecutionMode() === ExecutionMode.STANDALONE ? (
      <Navigation />
    ) : null}
    <Switch>
      <Route path={"/agencies/me/*"} component={AgencyMe} />
      <Route path={"/agencies/:id"} component={Agency} />
      <Route path={"*"} component={NotFound} />
    </Switch>
  </Flex>
);

export default App;
