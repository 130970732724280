import React, { forwardRef } from "react";
import styled from "styled-components";
import {
  alignItems,
  alignContent,
  alignSelf,
  flex,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  flexWrap,
  justifyContent,
  justifySelf,
  order
} from "styled-system";

import Box from "../box";

const StyledFlex = styled(Box)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  ${({ pointer }) => pointer && "cursor: pointer;"}
  ${alignItems}
  ${alignContent}
  ${alignSelf}
  ${flex}
  ${flexBasis}
  ${flexGrow}
  ${flexShrink}
  ${flexDirection}
  ${flexWrap}
  ${justifyContent}
  ${justifySelf}
  ${order}
`;

const Flex = forwardRef(
  (
    {
      alignItems = "flex-start",
      flexDirection = "row",
      justifyContent = "flex-start",
      ...props
    },
    ref
  ) => (
    <StyledFlex
      alignItems={alignItems}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      ref={ref}
      {...props}
    />
  )
);

export default Flex;
