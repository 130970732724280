import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const InfoOutline = ({
  fill = "none",
  height = "28",
  viewBox = "0 0 28 28",
  width = "28",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M14.0001 2.00033C16.3735 2.00033 18.6935 2.70411 20.6669 4.02269C22.6403 5.34127 24.1784 7.21541 25.0866 9.40812C25.9949 11.6008 26.2325 14.0136 25.7695 16.3414C25.3065 18.6692 24.1636 20.8074 22.4854 22.4856C20.8071 24.1638 18.6689 25.3067 16.3412 25.7698C14.0134 26.2328 11.6006 25.9951 9.40789 25.0869C7.21517 24.1786 5.34103 22.6406 4.02245 20.6672C2.70388 18.6938 2.00009 16.3737 2.00009 14.0003C2.00009 10.8177 3.26437 7.76548 5.51481 5.51504C7.76524 3.26461 10.8175 2.00033 14.0001 2.00033ZM14.0001 0.666992C11.363 0.666992 8.78514 1.44898 6.59248 2.91406C4.39983 4.37915 2.69086 6.46153 1.68169 8.89788C0.672525 11.3342 0.408481 14.0151 0.92295 16.6015C1.43742 19.1879 2.7073 21.5637 4.572 23.4284C6.4367 25.2931 8.81247 26.563 11.3989 27.0775C13.9853 27.5919 16.6662 27.3279 19.1025 26.3187C21.5389 25.3096 23.6213 23.6006 25.0863 21.4079C26.5514 19.2153 27.3334 16.6374 27.3334 14.0003C27.3334 12.2494 26.9885 10.5156 26.3185 8.89788C25.6484 7.2802 24.6663 5.81035 23.4282 4.57224C22.1901 3.33412 20.7202 2.35199 19.1025 1.68193C17.4849 1.01187 15.751 0.666992 14.0001 0.666992ZM15.6667 23.3337V11.3337H12.3334V23.3337H15.6667ZM15.6667 7.33366C15.6667 8.25413 14.9206 9.00033 14.0001 9.00033C13.0796 9.00033 12.3334 8.25413 12.3334 7.33366C12.3334 6.41318 13.0796 5.66699 14.0001 5.66699C14.9206 5.66699 15.6667 6.41318 15.6667 7.33366Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default InfoOutline;
