import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Location = ({
  fill = "none",
  height = "32",
  viewBox = "0 0 32 32",
  width = "32",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      d={
        "M15.9987 13.3332C17.1033 13.3332 17.9987 12.4377 17.9987 11.3332C17.9987 10.2286 17.1033 9.33317 15.9987 9.33317C14.8941 9.33317 13.9987 10.2286 13.9987 11.3332C13.9987 12.4377 14.8941 13.3332 15.9987 13.3332Z"
      }
      fill={"#1998D5"}
    />
    <path
      d={
        "M15.9987 3.99984C17.9425 4.00336 19.8058 4.77711 21.1803 6.15161C22.5548 7.52612 23.3285 9.38933 23.332 11.3332C23.332 14.4532 18.7454 22.4532 15.9987 26.8532C13.252 22.4532 8.66536 14.4532 8.66536 11.3332C8.66889 9.38933 9.44264 7.52612 10.8171 6.15161C12.1916 4.77711 14.0549 4.00336 15.9987 3.99984ZM15.9987 2.6665C13.7002 2.6665 11.4958 3.5796 9.87044 5.20491C8.24512 6.83023 7.33203 9.03463 7.33203 11.3332C7.33203 16.1198 15.9987 29.3332 15.9987 29.3332C15.9987 29.3332 24.6654 16.1198 24.6654 11.3332C24.6654 9.03463 23.7523 6.83023 22.127 5.20491C20.5016 3.5796 18.2972 2.6665 15.9987 2.6665Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Location;
