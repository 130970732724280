import { Box } from "src/nhweb-shared/components/Box";
import { Radii } from "src/nhweb-shared/theme";
import styled from "styled-components";

export const ImageGalleryWrapper = styled(Box)`
  position: relative;

  .image-gallery-content.fullscreen .image-galery-slide {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-gallery-index {
    display: none;
  }

  .image-gallery.show-index .image-gallery-index {
    display: block;
    text-align: center;
    right: ${({ theme }) => theme.space[6]}px;
    top: ${({ theme }) => theme.space[4]}px;
    right: ${({ theme }) => theme.space[6]}px;
    padding: 6px;
    top: 16px;
    border-radius: ${({ theme }) => theme.radii.round}px;
  }

  .image-gallery-content.fullscreen .image-gallery-index {
    display: block;
    width: 100%;
    padding: 20px 0px;
    text-align: center;
  }

  .image-gallery-content.fullscreen .image-gallery-bullets,
  .image-gallery.show-index .image-gallery-fullscreen-button {
    display: none;
  }

  .image-gallery-content.fullscreen .image-video-slide {
    max-height: 100vh;
    height: 100vh;
    padding-bottom: 0;
  }

  .image-gallery-thumbnails-container {
    text-align: left;

    ${({ theme }) => theme.mediaQueries.m} {
      display: flex;
      flex-direction: row;
      flex: 1;
    }
  }

  .image-gallery-thumbnail {
    position: relative;
    border: none;
    border-radius: ${({ theme }) => theme.radii.rounder}px;
    margin-right: ${({ theme }) => theme.space[2]}px;
    flex-basis: 20%;

    ${({ theme }) => theme.mediaQueries.m} {
      display: inline-block;
      width: 120px;
    }
  }

  .image-gallery-thumbnail:last-child {
    margin-right: 0;
  }

  .show-dashes .image-gallery-thumbnail > div {
    display: none;
  }

  .image-gallery-content:not(.fullscreen) .image-gallery-slide-wrapper {
    border-radius: ${({ theme, borderRadius }) =>
      theme.radii[borderRadius as Radii]}px;
    overflow: hidden;
    margin-bottom: ${({ theme }) => theme.space[3]}px;
  }

  .image-gallery-fullscreen-button.active {
    z-index: 5;
    padding: 10px;
  }

  .image-gallery-bullets {
    bottom: 14px;
  }

  .image-gallery-bullet {
    border: none;
    background: ${({ theme }) => theme.colors.whiteAlpha[700]};
    box-shadow: none;
    filter: drop-shadow(black 0 2px 2px);
    padding: ${({ theme }) => theme.space[1]}px;
    margin: 0 ${({ theme }) => theme.space[1]}px;
  }

  .image-gallery-bullet.active {
    background: ${({ theme }) => theme.colors.primary[500]};
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    border: none;
    transition: none;
  }

  .image-gallery-thumbnails {
    padding: 0;
  }

  .image-gallery-thumbnail::after {
    content: "";
    width: 100%;
    position: absolute;
    height: 4px;
    left: 0;
    bottom: 0;
    border-radius: ${({ theme }) => theme.radii.rounder}px;
  }

  .image-gallery-icon {
    opacity: 0.8;
    filter: drop-shadow(0 0px 0px #1a1a1a);
  }

  .show-dashes .image-gallery-thumbnails {
    padding: 5px 12px;
  }

  .image-gallery-thumbnail.active::after {
    ${({ theme }) => theme.mediaQueries.m} {
      background: ${({ theme }) => theme.colors.primary[500]};
    }
  }

  .show-dashes .image-gallery-thumbnail::after {
    background: ${({ theme }) => theme.colors.tertiary[500]};
  }

  .image-gallery-thumbnail.active::after {
    background: ${({ theme }) => theme.colors.primary[500]};
  }
`;
