import React, { useMemo } from "react";
import { combineHandlers } from "src/nhweb-shared/utils";
import { Button } from "../Button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps
} from "../Modal";

export interface ConfirmationModalProps extends ModalProps {
  cancelText?: string;
  confirmationText?: string;
  onSubmit: (...args: any[]) => void;
  submitText?: string;
}

export const ConfirmationModal = ({
  cancelText = "Cancel",
  confirmationText = "Click OK to confirm.",
  onClose,
  onSubmit,
  submitText = "OK",
  ...props
}: ConfirmationModalProps) => {
  const handleSubmit = useMemo(
    () => combineHandlers(onSubmit, onClose),
    [onSubmit, onClose]
  );

  return (
    <Modal {...props} onClose={onClose} isCentered size={"sm"}>
      <ModalOverlay>
        <ModalContent p={3}>
          <ModalBody p={0} mb={3} textAlign={"center"}>
            {confirmationText}
          </ModalBody>
          <ModalFooter border={"none"} p={0}>
            <Button
              variant={"inverted-primary"}
              mr={3}
              onClick={onClose}
              width={"50%"}
            >
              {cancelText}
            </Button>
            <Button variant={"primary"} onClick={handleSubmit} width={"50%"}>
              {submitText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
