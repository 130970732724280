import { ReactElement, ReactNode } from "react";

interface WrapIfProps {
  condition: any;
  wrapper: (children: ReactNode) => ReactNode;
  children: ReactNode | ((wrapped: boolean) => ReactNode);
}

export const WrapIf = ({ condition, wrapper, children }: WrapIfProps) => {
  const wrap = Boolean(condition);
  const newChildren =
    typeof children === "function" ? children(wrap) : children;
  const result = wrap ? wrapper(newChildren) : newChildren;

  return result as ReactElement<WrapIfProps>;
};
