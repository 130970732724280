import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Hamburger = ({
  fill = "none",
  height = "20",
  viewBox = "0 0 24 20",
  width = "24",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <mask
      id={"hamburger-mask0"}
      style={{ maskType: "alpha" }}
      maskUnits={"userSpaceOnUse"}
      x={"0"}
      y={"0"}
      width={"24"}
      height={"20"}
    >
      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M23.4 0H0.6C0.2688 0 0 0.289477 0 0.646154C0 1.00283 0.2688 1.29231 0.6 1.29231H23.4C23.7312 1.29231 24 1.00283 24 0.646154C24 0.289477 23.7312 0 23.4 0ZM23.4 9.04614H0.6C0.2688 9.04614 0 9.33562 0 9.6923C0 10.049 0.2688 10.3385 0.6 10.3385H23.4C23.7312 10.3385 24 10.049 24 9.6923C24 9.33562 23.7312 9.04614 23.4 9.04614ZM0.6 18.0923H23.4C23.7312 18.0923 24 18.3818 24 18.7385C24 19.0951 23.7312 19.3846 23.4 19.3846H0.6C0.2688 19.3846 0 19.0951 0 18.7385C0 18.3818 0.2688 18.0923 0.6 18.0923Z"
        }
        fill={"white"}
      />
    </mask>
    <g mask={"url(#hamburger-mask0)"}>
      <rect
        x={"-2.39966"}
        y={"-6.46191"}
        width={"28.8"}
        height={"31.0154"}
        fill={"#424242"}
      />
    </g>
  </StyledSvg>
);

export default Hamburger;
