import React from "react";

import { CardText, CardTextProps } from "../Card";
import { useEventCardState } from "./context";
import Text from "components/text";

export type EventCardDescriptionProps = Omit<CardTextProps, "children">;

export const EventCardDescription = (props: EventCardDescriptionProps) => {
  const {
    event: { description, disable_content_ellipsis }
  } = useEventCardState();
  if (!description) {
    return null;
  }

  return disable_content_ellipsis ? (
    <Text fontSize={14} fontWeight={300}>
      {description}
    </Text>
  ) : (
    <CardText textStyle={"body-normal-light"} {...props}>
      {description}
    </CardText>
  );
};
