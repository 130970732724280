import { border } from "polished";
import React, { useRef } from "react";
import useOnClickOutside from "use-onclickoutside";

import { Box, BoxBasedComponentProps } from "../Box";

export type DropdownProps = BoxBasedComponentProps<
  "div",
  {
    onClickOutside?: Parameters<typeof useOnClickOutside>[1];
    isOpen: boolean;
  }
>;

export const Dropdown = ({
  children,
  isOpen,
  onClickOutside = null,
  ...props
}: DropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, onClickOutside);

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      {...border("1px", "solid", "rgba(66, 66, 66, 0.35)")}
      position={"absolute"}
      right={0}
      userSelect={"none"}
      borderRadius={"round"}
      backgroundColor={"white"}
      whiteSpace={"nowrap"}
      boxShadow={"dropdown"}
      minWidth={160}
      zIndex={99}
      {...props}
      ref={ref}
    >
      <Box py={3}>{children}</Box>
    </Box>
  );
};
