import moment from "moment";

import getPlatform, { Platform } from "./get-platform";

export default function convertAlertToNHWebEvent(p) {
  const createdAt: moment.Moment = moment(p.attributes?.created_at);
  const timeSinceCreated: string | null = createdAt.isValid()
    ? createdAt.fromNow()
    : null;

  return {
    __typename: "Event",
    id: p.id,
    details: {
      __typename: "EventDetails",
      title: p.attributes.title,
      description: p.attributes.description,
      disable_content_ellipsis: p.attributes.disable_content_ellipsis,
      category: p.attributes.category,
      hide_category: p.attributes.hide_category,
      date_info: {
        __typename: "DateInfo",
        created_at: p.attributes.created_at,
        time_since_created: timeSinceCreated
      },
      share: {
        __typename: "Share",
        url: p.attributes.share_url_v2
      },
      media:
        p.attributes.media_assets?.length > 0
          ? p.attributes.media_assets
          : null,
      details_url: p.attributes.details_url
    },
    activity: {
      __typename: "EventActivity",
      comment_count: p.attributes.comment_count,
      view_count: p.attributes.play_count,
      upvote_count: p.attributes.vote_count
    },
    owner: {
      agency_id: p.attributes.agency_id,
      type: p.attributes.user_type,
      user_name: p.attributes.user_name,
      publisher_data: p.attributes.publisher_data
    },

    // Extra prop not specified by NHWeb, for us to use within this app
    alertUrl: getAlertUrl(p.id, p.attributes.share_url_v2),
    actions: p.attributes.actions?.length > 0 ? p.attributes.actions : []
  };
}

const getAlertUrl = (alertId, shareUrl) => {
  const links = {
    [Platform.WEB]: shareUrl,
    [Platform.ANDROID]: `neighborhoods://open/area/1/alert/${alertId}`,
    [Platform.IOS]: `neighborhoods://area/1/alert/${alertId}`
  };
  return links[getPlatform()];
};
