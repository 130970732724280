import React, { forwardRef } from "react";
import { Theme } from "src/nhweb-shared/theme";

import { BoxProps } from "../Box";
import { Flex } from "../Flex";
import { ModalProps } from "./Modal";
import { useModalContext } from "./ModalContext";

export const ModalContent = forwardRef<HTMLDivElement, BoxProps>(
  (props, ref) => {
    const { getContentProps, size, scrollBehavior } = useModalContext();
    const contentProps = getContentProps({ ...props, ref });
    const maxWidth = getMaxWidthBySize(size);

    return (
      <Flex
        {...contentProps}
        as={"section"}
        bg={"white"}
        borderRadius={"rounder"}
        color={"inherit"}
        flexDirection={"column"}
        maxHeight={
          scrollBehavior === "inside" ? "calc(100vh - 7.5rem)" : undefined
        }
        maxWidth={maxWidth}
        my={"3.75rem"}
        overflow={"hidden"}
        position={"relative"}
        width={"100%"}
        _focus={{ outline: 0 }}
        sx={(theme: Theme) => ({
          boxShadow: `0px 8px 12px ${theme.colors.blackAlpha[300]}`
        })}
      />
    );
  }
);

ModalContent.displayName = "ModalContent";

const getMaxWidthBySize = (size: ModalProps["size"]) => {
  switch (size) {
    case "sm":
      return "20rem";
    case "md":
      return "28rem";
    case "lg":
      return "32rem";
    case "xlg":
      return "42rem";
    case "full":
      return "100%";
    default:
      return undefined;
  }
};
