import center from "@turf/center";
import bbox from "@turf/bbox";
import { AllGeoJSON } from "@turf/helpers";

export function centerOf(geo: AllGeoJSON): [number, number] {
  const coords = center(geo).geometry.coordinates;
  return [coords[0], coords[1]];
}

export function getBounds(geo: AllGeoJSON): [number, number, number, number] {
  const bb = bbox(geo);

  return [bb[0], bb[1], bb[2], bb[3]];
}
