import React from "react";
import Box from "components/box";

import AgencyType from "src/constants/agency-type";
import LawEnforcementProfileIcon from "components/icons/law_enforcement_profile";
import FireDepartmentProfileIcon from "components/icons/fire_department_profile";
import LocalGovernmentProfileIcon from "components/icons/local_government_profile";

const ProfileHeader = ({ agencyType }) => {
  let agency_profile_icon;

  if (agencyType === AgencyType.LAW_ENFORCEMENT) {
    agency_profile_icon = (
      <LawEnforcementProfileIcon height={"100%"} width={"100%"} />
    );
  } else if (agencyType === AgencyType.FIRE_DEPARTMENT) {
    agency_profile_icon = (
      <FireDepartmentProfileIcon height={"100%"} width={"100%"} />
    );
  } else {
    // Default type type === AgencyType.LOCAL_GOVERNMENT
    agency_profile_icon = (
      <LocalGovernmentProfileIcon height={"100%"} width={"100%"} />
    );
  }

  return (
    <Box
      display={"block"}
      position={"relative"}
      width={"100%"}
      aspectRatio={{ sm: "16/9", lg: "3/1" }}
      margin={"0 auto"}
      marginLeft={"auto"}
      marginRight={"auto"}
      maxWidth={"1080px"}
    >
      <Box
        backgroundImage={"url('../../images/default_profile_cover.png')"}
        backgroundSize={"100% 100%"}
        display={"block"}
        position={"relative"}
        overflow={"hidden"}
        zIndex={1}
        width={"100%"}
        height={"100%"}
        borderRadius={{ md: 0, lg: "0 0 7px 7px" }}
      />
      <Box
        alignItems={"center"}
        display={"flex"}
        position={"relative"}
        border={"white solid 4px"}
        backgroundColor={"primary200"}
        borderRadius={"100px"}
        justifyContent={"center"}
        width={{ sm: "80px", lg: "120px" }}
        height={{ sm: "80px", lg: "120px" }}
        margin={{ sm: "-44px 0 10px 24px", lg: "-92px 0 10px 32px" }}
        zIndex={2}
      >
        <Box
          backgroundColor={"unset"}
          height={{ sm: "64px", lg: "96px" }}
          width={{ sm: "64px", lg: "96px" }}
        >
          {agency_profile_icon}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileHeader;
