import React, { ReactElement } from "react";

import { CardHeader, CardHeaderProps } from "../Card";
import { Dropdown, DropdownIndicator, DropdownProps } from "../Dropdown";
import { Flex } from "../Flex";
import { EventOwnerHeader, EventOwnerHeaderProps } from "../OwnerHeader";

export interface EventCardHeaderProps extends CardHeaderProps {
  ownerHeaderProps: EventOwnerHeaderProps;
  dropdownIsOpen: boolean;
  dropdownOptions: ReactElement[];
  onDropdownClick?: () => void;
  onDropdownClickOutside?: DropdownProps["onClickOutside"];
}

export const EventCardHeader = ({
  ownerHeaderProps,
  dropdownIsOpen,
  dropdownOptions,
  onDropdownClick,
  onDropdownClickOutside,
  ...props
}: EventCardHeaderProps) => (
  <CardHeader {...props}>
    <EventOwnerHeader {...ownerHeaderProps} />
    {/* NPSS Manual Change, this should be parametrized */}
    {/* <Flex ml={"auto"} position={"relative"} alignItems={"center"}>
      <DropdownIndicator onClick={onDropdownClick} />
      <Dropdown
        isOpen={dropdownIsOpen}
        onClickOutside={onDropdownClickOutside}
        top={35}
        width={200}
      >
        {dropdownOptions}
      </Dropdown>
    </Flex> */}
  </CardHeader>
);
