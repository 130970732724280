import type { EventCategory } from "src/utils/gql-schema";
import { border } from "polished";
import React from "react";
import { useEventCategories } from "src/nhweb-shared/context";

import { Box, BoxBasedComponentProps } from "../Box";
import { EventCategoryDot } from "../EventCategoryDot";
import { Flex } from "../Flex";

export type IncidentCategoriesProps = BoxBasedComponentProps<
  "div",
  {
    activeCategory?: EventCategory;
    categoryBorderType?: CategoryProps["borderType"];
    categoryDotSize?: CategoryProps["dotSize"];
    onCategoryClick?: CategoryProps["onClick"];
  }
>;

export const IncidentCategories = ({
  categoryBorderType,
  categoryDotSize,
  onCategoryClick,
  activeCategory,
  ...props
}: IncidentCategoriesProps) => {
  const categories = useEventCategories();

  return (
    <Flex {...props} flexDirection={"column"}>
      {categories.map(category => (
        <Category
          key={category.id}
          borderType={categoryBorderType}
          category={category}
          dotSize={categoryDotSize}
          isActive={category.id === activeCategory?.id}
          onClick={onCategoryClick}
        />
      ))}
    </Flex>
  );
};

interface CategoryProps {
  borderType?: "bottom" | "none";
  category: EventCategory;
  dotSize?: number;
  isActive?: boolean;
  onClick?: (category: EventCategory) => void;
}

const Category = ({ dotSize = 16, ...rest }: CategoryProps) => {
  const {
    category: { id, text }
  } = rest;

  const styles = getCategoryStyles(rest);

  return (
    <EventCategoryDot category={id} size={dotSize} dotMr={3}>
      {({ dot, title, offset }) => (
        <Flex {...styles}>
          <Flex alignItems={"center"}>
            {dot}
            {title}
          </Flex>
          <Box fontSize={1} fontWeight={200} ml={offset}>
            {text}
          </Box>
        </Flex>
      )}
    </EventCategoryDot>
  );
};

const getCategoryStyles = ({
  category,
  isActive,
  onClick,
  borderType
}: Omit<CategoryProps, "dotSize">) => {
  const border = getBorder(borderType);

  const base = {
    ...border,
    bg: "white",
    flexDirection: "column",
    p: 3,
    transition: "border-color 200ms ease-in-out"
  };

  return [
    base,
    borderType !== "none" && {
      _notLast: {
        mb: 3
      }
    },
    isActive && {
      borderColor: "primary.500",
      borderWidth: "2px"
    },
    onClick && {
      cursor: "pointer",
      _hover: { borderColor: "primary.hover", borderWidth: "2px" },
      onClick: () => onClick(category)
    }
  ]
    .filter(Boolean)
    .reduce((acc, s) => ({ ...acc, ...s }), {});
};

const getBorder = (borderType?: CategoryProps["borderType"]) => {
  if (borderType === "none") {
    return { border: "none" };
  }

  const radius = {
    borderRadius: "round"
  };

  if (borderType === "bottom") {
    return { ...border("bottom", "1px", "solid", "borders"), ...radius };
  }

  return { ...border("1px", "solid", "borders"), ...radius };
};
