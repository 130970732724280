import type { Event } from "src/utils/gql-schema";
import React, { ReactNode, forwardRef, useCallback } from "react";
import { useToggle } from "react-use";

import { Card, CardProps } from "../Card";
import { ConfirmationModal } from "../ConfirmationModal";
import { IncidentCategoriesModal } from "../IncidentCategories";
import { EventCardProvider, useEventCardState } from "./context";
import { useEventDropdownOptions } from "./hooks";

interface EventCardBaseChildrenProps {
  isDropdownOpen: boolean;
  dropdownOptions: ReturnType<typeof useEventDropdownOptions>;
  toggleIsDropdownOpen: () => void;
  toggleCTAModalIsOpen?: () => void;
}

interface EventCardBaseProps extends CardProps {
  children: (props: EventCardBaseChildrenProps) => ReactNode;
  onClickCategoryDefinitions: () => void;
  onClickHide: () => void;
  onClickDelete: () => void;
  onClickReport: () => void;
}

const EventCardBase = forwardRef<HTMLDivElement, EventCardBaseProps>(
  (
    {
      children,
      onClickCategoryDefinitions,
      onClickReport,
      onClickHide,
      onClickDelete,
      ...props
    },
    ref
  ) => {
    const {
      event: { is_announcement, is_owned }
    } = useEventCardState();

    const [isDropdownOpen, toggleIsDropdownOpen] = useToggle(false);

    const dropdownOptions = useEventDropdownOptions({
      is_owned,
      is_announcement,
      onClickCategoryDefinitions,
      onClickHide,
      onClickDelete,
      onClickReport
    });

    return (
      <Card {...props} ref={ref}>
        {children({ isDropdownOpen, dropdownOptions, toggleIsDropdownOpen })}
      </Card>
    );
  }
);

EventCardBase.displayName = "EventCardBase";

interface EventCardCommonProps {
  event: Event;
  eventAreaCenter: [number, number];
}

export interface EventCardProps
  extends Omit<
      EventCardBaseProps,
      | "onClickCategoryDefinitions"
      | "onClickHide"
      | "onClickDelete"
      | "onClickReport"
    >,
    EventCardCommonProps {
  onDelete: () => void;
  onHide: () => void;
  openReportModal: () => void;
}

export const EventCard = forwardRef<HTMLDivElement, EventCardProps>(
  (
    {
      event,
      eventAreaCenter,
      children,
      onHide,
      onDelete,
      openReportModal,
      ...props
    },
    ref
  ) => {
    const [hideModalIsOpen, toggleHideModalIsOpen] = useToggle(false);
    const [deleteModalIsOpen, toggleDeleteModalIsOpen] = useToggle(false);
    const [categoryModalIsOpen, toggleCategoryModalIsOpen] = useToggle(false);

    const handleHide = useCallback(() => {
      toggleHideModalIsOpen();
      onHide();
    }, [toggleHideModalIsOpen, onHide]);

    const handleDelete = useCallback(() => {
      toggleDeleteModalIsOpen();
      onDelete();
    }, [toggleDeleteModalIsOpen, onDelete]);

    return (
      <EventCardProvider event={event} eventAreaCenter={eventAreaCenter}>
        <EventCardBase
          {...props}
          ref={ref}
          onClickCategoryDefinitions={toggleCategoryModalIsOpen}
          onClickHide={toggleHideModalIsOpen}
          onClickDelete={toggleDeleteModalIsOpen}
          onClickReport={openReportModal}
        >
          {children}
        </EventCardBase>

        <ConfirmationModal
          isOpen={hideModalIsOpen}
          confirmationText={"Are you sure you want to hide this post?"}
          onClose={toggleHideModalIsOpen}
          onSubmit={handleHide}
        />

        <ConfirmationModal
          isOpen={deleteModalIsOpen}
          confirmationText={"Are you sure you want to delete this post?"}
          onClose={toggleDeleteModalIsOpen}
          onSubmit={handleDelete}
        />

        <IncidentCategoriesModal
          isOpen={categoryModalIsOpen}
          onClose={toggleCategoryModalIsOpen}
        />
      </EventCardProvider>
    );
  }
);

EventCard.displayName = "EventCard";

export interface EventCardOpenProps
  extends Omit<
      EventCardBaseProps,
      | "onClickCategoryDefinitions"
      | "onClickHide"
      | "onClickDelete"
      | "onClickReport"
    >,
    EventCardCommonProps {
  openCTAModal: () => void;
}

export const EventCardOpen = forwardRef<HTMLDivElement, EventCardOpenProps>(
  ({ event, eventAreaCenter, children, openCTAModal, ...props }, ref) => (
    <EventCardProvider event={event} eventAreaCenter={eventAreaCenter}>
      <EventCardBase
        {...props}
        onClickCategoryDefinitions={openCTAModal}
        onClickHide={openCTAModal}
        onClickDelete={openCTAModal}
        onClickReport={openCTAModal}
        ref={ref}
      >
        {children}
      </EventCardBase>
    </EventCardProvider>
  )
);

EventCardOpen.displayName = "EventCardOpen";
