import { breakpoints } from "./breakpoints";
import { buttons } from "./buttons";
import { colors } from "./colors";
import { fonts } from "./fonts";
import { mediaQueries } from "./mediaQueries";
import { radii } from "./radii";
import { shadows } from "./shadows";
import { space } from "./space";

export const theme = {
  ...fonts,
  breakpoints,
  buttons,
  colors,
  mediaQueries,
  radii,
  shadows,
  space
} as const;
