import React, { ReactNode } from "react";
import { isDevelopmentBuild } from "src/utils";

import { FocusLock, FocusLockProps } from "../FocusLock";
import { Portal, PortalProps } from "../Portal";
import { ModalContextProvider } from "./ModalContext";
import { UseModalProps, useModal } from "./useModal";

export interface ModalProps
  extends UseModalProps,
    Pick<
      FocusLockProps,
      "initialFocusRef" | "finalFocusRef" | "autoFocus" | "returnFocus"
    >,
    Pick<PortalProps, "container"> {
  children?: ReactNode;
  /**
   *  If `true`, the modal will be centered on screen.
   * @default false
   */
  isCentered?: boolean;
  /**
   * Where scroll behaviour should originate.
   * - If set to `inside`, scroll only occurs within the `ModalBody`.
   * - If set to `outside`, the entire `ModalContent` will scroll within the viewport.
   *
   * @default "outside"
   */
  scrollBehavior?: "inside" | "outside";
  /**
   * If `false`, focus lock will be disabled completely.
   *
   * This is useful in situations where you still need to interact with
   * other surrounding elements.
   *
   * 🚨Warning: We don't recommend doing this because it hurts the
   * accessbility of the modal, based on WAI-ARIA specifications.
   *
   * @default true
   */
  trapFocus?: boolean;
  /**
   * The modal size
   */
  size?: "sm" | "md" | "lg" | "xlg" | "full";
}

/**
 * Modal
 *
 * React component that provides context, theming, and accessbility properties
 * to all other modal components.
 */
export const Modal = (props: ModalProps) => {
  const {
    autoFocus = true,
    children,
    container,
    finalFocusRef,
    initialFocusRef,
    isCentered,
    isOpen,
    returnFocus = true,
    scrollBehavior = "outside",
    size = "md",
    trapFocus = true
  } = props;

  const context = {
    ...useModal(props),
    scrollBehavior,
    isCentered,
    size
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContextProvider value={context}>
      <Portal container={container}>
        <FocusLock
          autoFocus={autoFocus}
          disabled={!trapFocus}
          initialFocusRef={initialFocusRef}
          finalFocusRef={finalFocusRef}
          returnFocus={returnFocus}
          contentRef={context.dialogRef}
        >
          {children}
        </FocusLock>
      </Portal>
    </ModalContextProvider>
  );
};

if (isDevelopmentBuild) {
  Modal.displayName = "Modal";
}
