import { Event, EventDetails } from "src/utils/gql-schema";
import React, { useMemo } from "react";
import { getCoordinatesTuple } from "src/nhweb-shared/utils";

import { DropdownItem } from "../Dropdown";
import { EventMediaType } from "./EventCardMedia";

const getInitialMedia = (media: EventDetails["media"]) => {
  if (!media) {
    return {
      media_type: undefined,
      media_src: undefined
    };
  }

  const { type, url } = media[0];

  return {
    media_type: type as EventMediaType,
    media_src: url
  };
};

export const useNormalizedEvent = (event: Event) => {
  const normalized = useMemo(() => {
    const {
      activity,
      case_info,
      commentConnection,
      details,
      owner,
      resolution_info,
      user_activity,
      ...restEvent
    } = event;

    const { date_info, media, share, location, ...restDetails } = details!;

    const initialMedia = getInitialMedia(media);
    const coordinates = getCoordinatesTuple(location);

    const { comment_count, view_count, ...restActivity } = activity!;

    return {
      ...restEvent,
      ...restDetails,
      ...restActivity,
      ...case_info!,
      ...date_info!,
      ...commentConnection!,
      ...resolution_info!,
      ...user_activity!,
      ...initialMedia,
      location,
      owner,
      // activity
      comment_count: comment_count ?? 0,
      view_count: view_count ?? 0,
      // details
      share_url: share?.url ?? "",
      coordinates
    };
  }, [event]);

  return normalized;
};

export type NormalizedEvent = ReturnType<typeof useNormalizedEvent>;

export interface UseEventDropdownOptionsProps
  extends Pick<Event, "is_owned">,
    Pick<EventDetails, "is_announcement"> {
  onClickCategoryDefinitions: () => void;
  onClickHide: () => void;
  onClickDelete: () => void;
  onClickReport: () => void;
}

// TODO: deprecate this hook
export const useEventDropdownOptions = ({
  is_owned,
  is_announcement,
  onClickCategoryDefinitions,
  onClickHide,
  onClickDelete,
  onClickReport
}: UseEventDropdownOptionsProps) => {
  const options = useMemo(() => {
    const baseDropdownOptions = [
      <DropdownItem
        key={"categoryDefinitions"}
        onClick={onClickCategoryDefinitions}
      >
        {"Category Definitions"}
      </DropdownItem>
    ];

    const hidePostOptions = [
      <DropdownItem key={"hidePost"} onClick={onClickHide}>
        {"Hide Post"}
      </DropdownItem>
    ];

    if (is_announcement) {
      return hidePostOptions;
    }

    if (is_owned) {
      return [
        ...baseDropdownOptions,
        <DropdownItem key={"delete"} onClick={onClickDelete}>
          {"Delete"}
        </DropdownItem>
      ];
    }

    return [
      ...baseDropdownOptions,
      ...hidePostOptions,
      <DropdownItem key={"reportPost"} onClick={onClickReport}>
        {"Report Post"}
      </DropdownItem>
    ];
  }, [
    is_owned,
    is_announcement,
    onClickCategoryDefinitions,
    onClickHide,
    onClickDelete,
    onClickReport
  ]);

  return options;
};
