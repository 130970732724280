import { combineEpics, ofType } from "redux-observable";
import { of, from, combineLatest, EMPTY } from "rxjs";
import { path, fromPairs, props, map as rmap, compose } from "ramda";
import { catchError, flatMap, map, take } from "rxjs/operators";
import moment from "moment";

import {
  AGENCY_DETAILS_DATA_FETCH,
  AGENCY_DETAILS_DATA_SUCCESS,
  agencyDetailsDataSuccess,
  agencyDetailsDataFailure,
  agencyMetricsDataSuccess,
  agencyMetricsDataFailure,
  currentUserDataSuccess,
  currentUserDataFailure,
  VIEW_MODE_DATA_SUCCESS,
  viewModeDataSuccess,
  AGENCY_POSTS_DATA_FETCH,
  agencyPostsDataSuccess,
  agencyPostsDataFailure,
  AGENCY_REPORTS_DATA_FETCH,
  agencyReportsDataSuccess,
  agencyReportsDataFailure,
  LOAD_CONFIG_VARIABLES_FETCH,
  LOAD_CONFIG_VARIABLES_SUCCESS,
  loadConfigVariablesSuccess,
  loadConfigVariablesFailure,
  ON_FEED_REQUEST_NEXT_PAGE
} from "actions";
import { alertToNHWebEvent } from "utils";
import { getViewModeFromSearchParams, ViewMode } from "utils/view-mode";

const fetchConfigVariables = api =>
  api.get(`/config/variables.json?t=${new Date().getTime()}`);
const fetchAgencyDetails = (api, id) => api.get(`/v1/agencies/${id}`);
const fetchAgencyMetrics = (api, agency) =>
  api.get(`/v1/metrics/${agency.uuid}`);
const fetchCurrentUser = (config, api) =>
  api.get(`/user`, {
    baseURL: config.NPSS_API_ORIGIN
  });
const fetchAgencyPosts = (config, api, id, cursor) => {
  return api.post(
    `/v2/alerts/agency`,
    {
      include_agencies: [id],
      end_date: moment().utc().toISOString(),
      limit: 20,
      cursor
    },
    {
      baseURL: config.NPSS_API_ORIGIN
    }
  );
};
const fetchReports = (config, api, agency) =>
  api.get(`/v2/agencies/${agency.uuid}/reports`, {
    baseURL: config.NPSS_API_ORIGIN
  });

const loadConfigurationVariablesEpic = (action$, _, { api }) =>
  action$.pipe(
    ofType(LOAD_CONFIG_VARIABLES_FETCH),
    flatMap(() =>
      from(fetchConfigVariables(api)).pipe(
        map(response =>
          compose(fromPairs, rmap(props(["Name", "Value"])))(response.data)
        ),
        map(loadConfigVariablesSuccess)
      )
    ),
    catchError(error => {
      console.error(error);
      return of(loadConfigVariablesFailure());
    })
  );

const agencyDetailsDataFetchEpic = (action$, _, { api }) =>
  action$.pipe(
    ofType(AGENCY_DETAILS_DATA_FETCH),
    flatMap(({ payload }) =>
      from(fetchAgencyDetails(api, payload)).pipe(
        map(path(["data", "data"])),
        map(agencyDetailsDataSuccess),
        catchError(err => of(agencyDetailsDataFailure(err.message)))
      )
    )
  );

const fetchAgencyMetricsDataFetchEpic = (action$, _, { api }) =>
  combineLatest([
    action$.pipe(ofType(LOAD_CONFIG_VARIABLES_SUCCESS)),
    action$.pipe(ofType(AGENCY_DETAILS_DATA_SUCCESS))
  ]).pipe(
    flatMap(([{ payload: config }, { payload }]) => {
      return from(fetchAgencyMetrics(api, payload)).pipe(
        map(path(["data", "data"])),
        map(data =>
          agencyMetricsDataSuccess({
            data,
            config
          })
        ),
        catchError(err => of(agencyMetricsDataFailure(err.message)))
      );
    })
  );

const viewModeEpic = action$ =>
  action$.pipe(
    ofType(LOAD_CONFIG_VARIABLES_SUCCESS),
    map(({ payload: config }) =>
      viewModeDataSuccess({
        config,
        viewMode: getViewModeFromSearchParams()
      })
    )
  );

const fetchCurrentUserDataFetchEpic = (action$, _, { api }) =>
  action$.pipe(
    ofType(VIEW_MODE_DATA_SUCCESS),
    flatMap(({ payload: { config, viewMode } }) => {
      if (viewMode !== ViewMode.ADMIN) {
        return EMPTY;
      }

      return from(fetchCurrentUser(config, api)).pipe(
        map(path(["data"])),
        map(currentUserDataSuccess),
        catchError(err => of(currentUserDataFailure(err.message)))
      );
    })
  );

const agencyPostsDataFetchEpic = (action$, _, { api }) =>
  combineLatest(
    action$.pipe(ofType(LOAD_CONFIG_VARIABLES_SUCCESS), take(1)),
    action$.pipe(ofType(AGENCY_POSTS_DATA_FETCH, ON_FEED_REQUEST_NEXT_PAGE))
  ).pipe(
    flatMap(
      ([
        { payload: config },
        {
          payload: { id, nextCursor }
        }
      ]) => {
        return from(fetchAgencyPosts(config, api, id, nextCursor)).pipe(
          map(path(["data"])),
          map(payload => {
            return {
              posts: payload.data.map(alertToNHWebEvent),
              nextAgencyPostCursor: payload.meta.paging.cursor
            };
          }),
          map(agencyPostsDataSuccess),
          catchError(err => of(agencyPostsDataFailure(err.message)))
        );
      }
    )
  );

const fetchReportsDataFetchEpic = (action$, _, { api }) =>
  combineLatest(
    action$.pipe(ofType(LOAD_CONFIG_VARIABLES_SUCCESS), take(1)),
    action$.pipe(ofType(AGENCY_DETAILS_DATA_SUCCESS)),
    action$.pipe(ofType(AGENCY_REPORTS_DATA_FETCH))
  ).pipe(
    flatMap(([{ payload: config }, { payload: agency }]) =>
      from(fetchReports(config, api, agency)).pipe(
        map(path(["data", "data"])),
        map(agencyReportsDataSuccess),
        catchError(err => of(agencyReportsDataFailure(err.message)))
      )
    )
  );

export default combineEpics(
  agencyDetailsDataFetchEpic,
  fetchAgencyMetricsDataFetchEpic,
  fetchCurrentUserDataFetchEpic,
  agencyPostsDataFetchEpic,
  fetchReportsDataFetchEpic,
  viewModeEpic,
  loadConfigurationVariablesEpic
);
