import copy from "copy-to-clipboard";
import { useCallback } from "react";
import { toast as showToast } from "src/nhweb-shared/components/Toast";

interface UseCopyToClipboardProps {
  value: string;
  toast: false | { successMessage: string; errorMessage: string };
}

export const useCopyToClipboard = ({
  value,
  toast
}: UseCopyToClipboardProps) => {
  return useCallback(() => {
    const success = copy(value);

    if (!toast) {
      return;
    }

    const { successMessage, errorMessage } = toast;

    const toastSemanticFunction = success ? showToast.success : showToast.error;
    const text = success ? successMessage : errorMessage;

    toastSemanticFunction(text);
  }, [value, toast]);
};

export const useCopyLinkToClipboard = (value: string) => {
  return useCopyToClipboard({
    value,
    toast: {
      successMessage: "Link copied successfully",
      errorMessage: "An error ocurred while copying the link"
    }
  });
};
