import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import qs from "qs";

import {
  getAgency,
  getQuarterlySummary,
  getQuarterlySummaryPeriod,
  getIsLoadingAgency,
  getIsMobileScreen,
  getCurrentUser,
  getViewMode,
  getConfigVars,
  getIsLoadingAgencyPosts,
  getIsLoadingAgencyMetrics,
  getLastAgencyPostReached,
  getNextAgencyPostCursor
} from "reducer";

import Agency from "components/agency";
import AgencyDetails from "components/agency-details";
import AgencyFeedContainer from "containers/agency-feed";
import AgencyUsageReportsContainer from "containers/agency-usage-reports";
import NotFound from "components/not-found";
import {
  agencyDetailsDataFetch,
  agencyPostsDataFetch,
  onFeedRequestNextPage
} from "actions";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

const AgencyContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const agency = useSelector(getAgency);
  const { path } = useRouteMatch();

  const quarterlySummary = useSelector(getQuarterlySummary);
  const quarterlySummaryPeriod = useSelector(getQuarterlySummaryPeriod);

  const isMobileScreen = useSelector(getIsMobileScreen);
  const isLoading = useSelector(getIsLoadingAgency);
  const isLoadingPosts = useSelector(getIsLoadingAgencyPosts);
  const isLoadingMetrics = useSelector(getIsLoadingAgencyMetrics);
  const nextAgencyPostCursor = useSelector(getNextAgencyPostCursor);
  const lastAgencyPostReached = useSelector(getLastAgencyPostReached);
  const currentUser = useSelector(getCurrentUser);
  const viewMode = useSelector(getViewMode);
  const config = useSelector(getConfigVars);

  useEffect(() => {
    document.title = "Neighbors" + (agency.name ? ` - ${agency.name}` : "");
  });

  const {
    agency_id: agencyId,
    agency_type: agencyType,
    billing_address_line_1: address1,
    billing_address_line_2: address2,
    billing_address_line_3: address3,
    billing_city: city,
    billing_state: state,
    billing_zip: zipCode,
    created_at: createdAt,
    description,
    name,
    non_emergency_email: email,
    non_emergency_extension: extension,
    non_emergency_phone: phoneNumber,
    report_a_crime_url: link,
    uuid,
    website,
    linked_accounts: linkedAccounts,
    geom_simple: geomSimple
  } = agency;

  const agencyData = {
    address1,
    address2,
    address3,
    agencyType,
    city,
    description,
    email,
    extension,
    id,
    link,
    phoneNumber,
    state,
    uuid,
    website,
    zipCode,
    linkedAccounts,
    geomSimple
  };

  useLayoutEffect(() => {
    dispatch(agencyDetailsDataFetch(id));
    dispatch(agencyPostsDataFetch({ id }));
  }, []);

  const handleRequestPage = () => {
    if (!isLoadingPosts && !lastAgencyPostReached) {
      dispatch(
        onFeedRequestNextPage({
          id,
          nextCursor: nextAgencyPostCursor
        })
      );
    }
  };

  return isLoading || (agencyId && config) ? (
    <Agency
      id={id}
      agencyData={agencyData}
      createdAt={createdAt}
      isLoading={isLoading || isLoadingMetrics}
      name={name}
      isMobileScreen={isMobileScreen}
      currentUser={currentUser}
      viewMode={viewMode}
      config={config}
      onHandleRequestPage={handleRequestPage}
      quarterlySummary={quarterlySummary}
      quarterlySummaryPeriod={quarterlySummaryPeriod}
    >
      {/* 
        Nested routes are delegated to this component.
        Refer to docs: https://reactrouter.com/web/guides/quick-start/2nd-example-nested-routing
      */}
      <Switch>
        <Route path={`${path}/posts`}>
          <AgencyFeedContainer id={id} />
        </Route>
        <Route path={`${path}/reports`}>
          <AgencyUsageReportsContainer />
        </Route>
        <Route path={`${path}`}>
          <AgencyDetails
            agencyData={agencyData}
            showAgencyHeader={!isMobileScreen}
            quarterlySummary={quarterlySummary}
            quarterlySummaryPeriod={quarterlySummaryPeriod}
          />
        </Route>
      </Switch>
    </Agency>
  ) : (
    <NotFound />
  );
};

export const AgencyMe = () => {
  const searchParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  const id = searchParams["id"];
  const wildcardMatch = useParams()[0];
  const queryParams = qs.stringify(searchParams);

  const redirectTo = `/agencies/${id}/${wildcardMatch}?${queryParams}`;

  return <Redirect to={redirectTo} />;
};

export default AgencyContainer;
