import type { DateInfo } from "src/utils/gql-schema";
import React from "react";

import { HelperText, HelperTextProps } from "./HelperText";

export interface DateHelperTextProps extends HelperTextProps {
  createdAt: DateInfo["created_at"];
  timeSinceCreated: DateInfo["time_since_created"];
  tooltip?: boolean;
}

export const DateHelperText = ({
  createdAt,
  timeSinceCreated,
  tooltip = true,
  ...props
}: DateHelperTextProps) => {
  if (!timeSinceCreated) {
    return null;
  }

  {
    /* NPSS Manual Change */
  }
  {
    /* Simplified to avoid double 'ago' suffix */
  }
  const suffix = "ago";
  const timeago = timeSinceCreated.includes(suffix)
    ? timeSinceCreated
    : `${timeSinceCreated} ${suffix}`;

  const label = tooltip && createdAt.length > 0 ? createdAt : undefined;

  return (
    <HelperText {...props} label={label}>
      {timeago}
    </HelperText>
  );
};
