import React, { forwardRef } from "react";
import { useIsomorphicLayoutEffect } from "react-use";

import { BoxProps } from "../Box";
import { Flex } from "../Flex";
import { useModalContext } from "./ModalContext";
import { getBorder, px, py } from "./utils";

type ModalHeaderProps = Omit<BoxProps, "as" | "id">;

export const ModalHeader = forwardRef<HTMLElement, ModalHeaderProps>(
  (props, ref) => {
    const { headerId, setHeaderMounted } = useModalContext();

    useIsomorphicLayoutEffect(() => {
      setHeaderMounted(true);
      return () => setHeaderMounted(false);
    }, []);

    return (
      <Flex
        flex={"0 0 auto"}
        fontSize={4}
        fontWeight={"medium"}
        py={py}
        px={px}
        {...getBorder("bottom")}
        {...props}
        ref={ref}
        as={"header"}
        id={headerId}
      />
    );
  }
);

ModalHeader.displayName = "ModalHeader";
