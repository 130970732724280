import React from "react";

import { Box, BoxBasedComponentProps } from "../Box";
import { Icon, IconName } from "../Icon";
import { getRel, linkStyles } from "../Link";

type ItemType = "a" | "div";

export type DropdownItemProps<T extends ItemType = "div"> =
  BoxBasedComponentProps<
    T,
    {
      icon?: IconName;
      label?: string;
    }
  >;

export const DropdownItem = <T extends ItemType = "div">({
  icon,
  label,
  children,
  ...props
}: DropdownItemProps<T>) => (
  <Box
    fontSize={2}
    height={"auto"}
    position={"relative"}
    px={3}
    py={2}
    textAlign={"left"}
    _hover={{
      color: "white",
      bg: "primary.500"
    }}
    {...props}
    color={"neutral.500"}
    cursor={"pointer"}
  >
    {icon && (
      <Icon
        name={icon}
        color={"primary.500"}
        mr={2}
        _hover={{ "& path": { fill: "white" } }}
      />
    )}
    {label}
    {children}
  </Box>
);

type DropdownLinkItemProps = Omit<DropdownItemProps<"a">, "as">;

export const DropdownLinkItem = ({
  target,
  rel,
  ...props
}: DropdownLinkItemProps) => {
  const finalRel = getRel(rel, target);

  return (
    <DropdownItem
      {...props}
      {...linkStyles}
      display={"block"}
      as={"a"}
      target={target}
      rel={finalRel}
    />
  );
};
