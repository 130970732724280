import React, { MouseEventHandler } from "react";
import { Box } from "../Box";
import { Icon } from "../Icon";

export const EventGalleryRightNav = (
  onClick: MouseEventHandler<HTMLDivElement>
) => (
  <Box
    className={"image-gallery-icon image-gallery-right-nav"}
    onClick={onClick}
    cursor={"pointer"}
    bg={"white"}
    borderRadius={"circle"}
    width={"auto"}
    right={"10px"}
    left={"auto"}
    p={0}
  >
    <Icon color={"neutral.500"} name={"chevron-right"} size={32} />
  </Box>
);
