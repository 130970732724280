import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Expand = ({
  fill = "none",
  viewBox = "0 0 20 20",
  height = "20",
  width = "20",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M16.5668 3.43351C16.6115 3.4782 16.6457 3.53229 16.6668 3.59185C16.6792 3.6439 16.6792 3.69813 16.6668 3.75018V9.58352C16.6668 9.69402 16.6229 9.80001 16.5448 9.87815C16.4666 9.95629 16.3606 10.0002 16.2501 10.0002C16.1396 10.0002 16.0337 9.95629 15.9555 9.87815C15.8774 9.80001 15.8335 9.69402 15.8335 9.58352V4.75852L11.5418 9.04185C11.5044 9.08171 11.4592 9.11348 11.4091 9.1352C11.3589 9.15691 11.3048 9.16812 11.2501 9.16812C11.1955 9.16812 11.1414 9.15691 11.0912 9.1352C11.0411 9.11348 10.9959 9.08171 10.9585 9.04185C10.9186 9.00444 10.8868 8.95926 10.8651 8.9091C10.8434 8.85893 10.8322 8.80485 10.8322 8.75018C10.8322 8.69552 10.8434 8.64144 10.8651 8.59127C10.8868 8.54111 10.9186 8.49593 10.9585 8.45852L15.2418 4.16685H10.4168C10.3063 4.16685 10.2003 4.12295 10.1222 4.04481C10.044 3.96667 10.0001 3.86069 10.0001 3.75018C10.0001 3.63968 10.044 3.5337 10.1222 3.45556C10.2003 3.37742 10.3063 3.33352 10.4168 3.33352H16.2501C16.3022 3.32112 16.3564 3.32112 16.4085 3.33352C16.468 3.35466 16.5221 3.38882 16.5668 3.43351ZM4.75852 15.8336H9.58352L9.55018 15.8002C9.66069 15.8002 9.76667 15.8441 9.84481 15.9223C9.92295 16.0004 9.96685 16.1064 9.96685 16.2169C9.96685 16.3274 9.92295 16.4334 9.84481 16.5115C9.76667 16.5897 9.66069 16.6336 9.55018 16.6336H3.71685C3.6648 16.646 3.61057 16.646 3.55852 16.6336C3.45721 16.5905 3.37655 16.5099 3.33352 16.4086C3.32112 16.3565 3.32112 16.3023 3.33352 16.2502V10.4169C3.33352 10.3064 3.37742 10.2004 3.45556 10.1223C3.5337 10.0441 3.63968 10.0002 3.75018 10.0002C3.86069 10.0002 3.96667 10.0441 4.04481 10.1223C4.12295 10.2004 4.16685 10.3064 4.16685 10.4169V15.2419L8.45852 10.9586C8.53587 10.8812 8.64079 10.8378 8.75018 10.8378C8.85958 10.8378 8.9645 10.8812 9.04185 10.9586C9.11921 11.0359 9.16266 11.1408 9.16266 11.2502C9.16266 11.3596 9.11921 11.4646 9.04185 11.5419L4.75852 15.8336Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Expand;
