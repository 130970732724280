import React from "react";
import { useEventCategory } from "src/nhweb-shared/context";

import { BoxProps } from "../Box";
import { Flex } from "../Flex";
import { Tag } from "../Tag";
import { useEventCardState } from "./context";

export const EventCardTags = (props: BoxProps) => {
  const {
    event: { category, hide_category, is_resolved }
  } = useEventCardState();

  let title = "";

  if (category !== "") {
    ({ title } = useEventCategory(category));
  }

  return (
    <Flex {...props}>
      {is_resolved && (
        <Tag bg={"positive.100"} mr={2}>
          {"Resolved"}
        </Tag>
      )}
      {!hide_category && <Tag>{title}</Tag>}
    </Flex>
  );
};
