import React from "react";
import { useSelector } from "react-redux";

import Navigation from "components/navigation";

import { getConfigVars, getIsMobileScreen } from "reducer";

export default () => {
  const isMobileScreen = useSelector(getIsMobileScreen);

  const handleOpenLinkNewTab = link => () => {
    window.open(link);
  };

  const configVars = useSelector(getConfigVars);

  const neighborsLogoUrl = () => {
    return configVars.NEIGHBORS_WEB_BASE_URL || "/";
  };

  return (
    <Navigation
      handleOpenLinkNewTab={handleOpenLinkNewTab}
      isMobileScreen={isMobileScreen}
      neighborsLogoUrl={neighborsLogoUrl}
    />
  );
};
