import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Equip';
    src: url('/fonts/Equip-Bold.woff2') format('woff2'),
        url('/fonts/Equip-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Equip';
    src: url('/fonts/Equip-Light.woff2') format('woff2'),
        url('/fonts/Equip-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Equip';
    src: url('/fonts/Equip-ExtraBold.woff2') format('woff2'),
        url('/fonts/Equip-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Equip';
    src: url('/fonts/Equip-Thin.woff2') format('woff2'),
        url('/fonts/Equip-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Equip';
    src: url('/fonts/Equip-Medium.woff2') format('woff2'),
        url('/fonts/Equip-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
  }

  html, body, #index {
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: "Equip";
    margin: 0;
    overscroll-behavior-y: contain;
  }
`;

export default GlobalStyle;
