import React from "react";

const XLogo = () => {
  return (
    <img
      src={"/images/x-logo.png"}
      style={{ width: "22px", height: "22px" }}
      alt={"logo"}
    />
  );
};

export default XLogo;
