import React from "react";

import { BoxBasedComponentProps } from "../Box";
import { Distance } from "../Distance";
import { Flex } from "../Flex";
import { DateHelperText, HelperTextProps } from "../HelperText";
import { Plural } from "../Plural";
import { useEventCardState } from "./context";

export interface TimeSinceCreatedProps extends HelperTextProps {}

export type EventCardEventInfoProps = BoxBasedComponentProps<
  "div",
  {
    isOpenEvent?: boolean;
    timeSinceCreatedProps?: TimeSinceCreatedProps;
  }
>;

export const EventCardEventInfo = ({
  isOpenEvent,
  ...props
}: EventCardEventInfoProps) => {
  const {
    event: {
      coordinates,
      created_at,
      is_announcement,
      is_regional,
      time_since_created,
      view_count
    },
    eventAreaCenter
  } = useEventCardState();

  const { timeSinceCreatedProps } = props;

  if (is_announcement) {
    return null;
  }

  const showDistance = !isOpenEvent && !is_regional;
  const showViews = view_count > 0;

  return (
    <Flex textStyle={"body-normal-light"} mb={4} {...props}>
      <DateHelperText
        mr={mx}
        color={"neutral.500"}
        createdAt={created_at}
        timeSinceCreated={time_since_created}
        {...timeSinceCreatedProps}
      />
      {showDistance && <Distance from={eventAreaCenter} to={coordinates} />}
      {showViews && (
        <Plural count={view_count} ml={mx}>
          {"view"}
        </Plural>
      )}
    </Flex>
  );
};

// TODO: add stack components, HStack, VStack, etc
const mx = 3;
