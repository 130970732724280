import { routerMiddleware } from "connected-react-router";
import epic from "epic";
import { createBrowserHistory } from "history";
import createRootReducer from "reducer";
import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { loadConfigVariablesFetch } from "src/actions";
import { createApiClient } from "utils";

const makeComposer = () =>
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
const composeEnhancers = makeComposer();
const configureStore = initialState => {
  const api = createApiClient();

  api.applyRequestResponseInterceptor();

  const epicMiddleware = createEpicMiddleware({
    dependencies: { api }
  });

  const middlewares = [routerMiddleware(history), epicMiddleware];

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  epicMiddleware.run(epic);
  store.dispatch(loadConfigVariablesFetch());
  return store;
};

export default configureStore;
