import React from "react";

import Flex from "components/flex";
import Text from "components/text";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledTabLink = styled(NavLink)`
  align-items: center;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 55px;
  border-bottom: 4px solid transparent;
  &.tab-active {
    div {
      font-weight: bold;
    }
    border-bottom-color: ${props => props.theme.colors.primary500};
  }
`;

const StyledTab = styled(Flex)`
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.neutral500};
  height: 51px;
`;

const Tab = ({ title, to }) => {
  return (
    <StyledTabLink
      to={to}
      width={1}
      activeClassName={"tab-active"}
      exact={true}
    >
      <StyledTab>
        <Text>{title}</Text>
      </StyledTab>
    </StyledTabLink>
  );
};

export default Tab;
