import React from "react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps
} from "../Modal";
import { IncidentCategories } from "./IncidentCategories";

export interface IncidentCategoriesModalProps extends ModalProps {}

export const IncidentCategoriesModal = (
  props: IncidentCategoriesModalProps
) => (
  <Modal {...props} isCentered>
    <ModalOverlay>
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={4}>
          <IncidentCategories categoryBorderType={"none"} />
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  </Modal>
);
