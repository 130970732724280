import React from "react";

import { Box, BoxProps } from "../Box";
import { Icon } from "../Icon";

export const EventCardSolved = (props: BoxProps) => {
  return (
    <Box
      bg={"positive.100"}
      color={"positive.500"}
      textStyle={"body-small-medium"}
      borderRadius={"rounder"}
      py={2}
      px={3}
      {...props}
    >
      <Icon name={"check"} size={14} mr={2} mb={1 / 2} />
      {"Resolved"}
    </Box>
  );
};
