import { useMemo } from "react";
import { useTheme } from "styled-components";

import { Theme } from "../theme";

export const useThemeStyles = <T>(getStyles: (theme: Theme) => T) => {
  const theme = useTheme();
  const result = useMemo(() => getStyles(theme), [getStyles, theme]);

  return result;
};
