import React from "react";
import MultiClamp, { ClampProps } from "react-multi-clamp";
import styled from "styled-components";
import shouldForwardProp from "@styled-system/should-forward-prop";

import {
  Box,
  BoxBasedComponentProps,
  BoxOwnProps,
  BoxProps,
  styledSystem,
  textStyle
} from "../Box";

type AllClampTypes = string | number | false;

type ClampChildren<T extends AllClampTypes> = {
  clamp?: T;
  children: string;
};

type MultiClampProps = BoxOwnProps &
  Omit<ClampProps, "clamp" | "splitByWords"> &
  ClampChildren<string | number>;

type BoxPropsWithClamp = BoxBasedComponentProps<"div", ClampChildren<false>>;

export type CardTextProps = MultiClampProps | BoxPropsWithClamp;

export const CardText = ({ clamp = false, ...props }: CardTextProps) => {
  if (clamp) {
    return (
      <StyledMultiClamp
        textStyle={"body-normal-medium"}
        clamp={clamp}
        {...(props as MultiClampProps)}
        splitByWords
      />
    );
  }

  return <Box textStyle={"body-normal-medium"} {...(props as BoxProps)} />;
};

// NPSS Manual Change, added shouldForwardProp to pass downstream only valid HTML tag props.
const StyledMultiClamp = styled(MultiClamp).withConfig<BoxOwnProps>({
  shouldForwardProp
})`
  ${styledSystem}
  ${textStyle}
`;
