import React, { ComponentProps, RefObject, useCallback } from "react";
import ReactFocusLock from "react-focus-lock";

type OriginalFocusLockProps = ComponentProps<typeof ReactFocusLock>;

export interface FocusLockProps extends OriginalFocusLockProps {
  /**
   * `ref` of the element to receive focus initially
   */
  initialFocusRef?: RefObject<HTMLElement>;
  /**
   * `ref` of the element to return focus to when `FocusLock`
   * unmounts
   */
  finalFocusRef?: RefObject<HTMLElement>;
  /**
   * The `ref` of the wrapper for which the focus-lock wraps
   */
  contentRef?: RefObject<HTMLElement>;
}

/**
 * React component to trap focus within an element or component.
 * Mostly used in Modals, Popovers, etc.
 */
export const FocusLock = ({
  initialFocusRef,
  finalFocusRef,
  contentRef,
  returnFocus,
  ...props
}: FocusLockProps) => {
  const finalReturnFocus = returnFocus && !finalFocusRef;

  const onActivation = useCallback(() => {
    if (initialFocusRef?.current) {
      initialFocusRef.current.focus();
      return;
    }

    contentRef?.current?.focus();
  }, [initialFocusRef, contentRef]);

  const onDeactivation = useCallback(() => {
    finalFocusRef?.current?.focus();
  }, [finalFocusRef]);

  return (
    <ReactFocusLock
      {...props}
      onActivation={onActivation}
      onDeactivation={onDeactivation}
      returnFocus={finalReturnFocus}
    />
  );
};
