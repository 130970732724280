import { border } from "polished";
import React from "react";

import { Flex, FlexProps } from "../Flex";

export type CardFooterProps = FlexProps;

export const CardFooter = (props: CardFooterProps) => (
  <Flex {...border("top", "1px", "solid", "borders")} p={3} {...props} />
);
