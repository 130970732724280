import "react-toastify/dist/ReactToastify.css";

import React from "react";
import {
  ToastContainer,
  ToastContainerProps,
  cssTransition
} from "react-toastify";
import { navHeight } from "src/nhweb-shared/theme";
import styled from "styled-components";

import { textStyle } from "../Box";
import { CloseToastButton } from "./CloseToastButton";

export { toast } from "react-toastify";
export { ToastContainerWrapper as ToastContainer };

export const ToastContainerWrapper = (props: ToastContainerProps) => (
  <StyledToastContainer
    {...props}
    autoClose={false}
    closeButton={CloseToastButton}
    closeOnClick={false}
    draggable={false}
    hideProgressBar={false}
    newestOnTop={false}
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    position={"top-center"}
    rtl={false}
    transition={zoom}
  />
);

const py = 4;
const px = 8;

const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container {
    padding: 0;
    position: fixed;
    top: ${navHeight};
    transition: all 2s ease;
    width: 100%;
    z-index: 11;
  }

  .Toastify__toast {
    border-radius: 0;
    box-shadow: ${({ theme }) => theme.shadows.sm};
    color: ${({ theme }) => theme.colors.neutral[500]};
    margin: 0;
    min-height: unset;
    max-height: unset;
    padding-left: ${({ theme }) => theme.space[px]}px;
    padding-right: ${({ theme }) => theme.space[px]}px;
    padding-top: ${({ theme }) => theme.space[py]}px;
    padding-bottom: ${({ theme }) => theme.space[py]}px;
  }

  .Toastify__toast-body {
    ${({ theme }) => textStyle({ textStyle: "body-normal-medium" })!(theme)}
  }

  .Toastify__progress-bar--default {
    background: ${({ theme }) => theme.colors.tertiary[500]};
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.positive[100]};
    color: ${({ theme }) => theme.colors.positive[500]};
  }

  .Toastify__toast--success button path {
    fill: ${({ theme }) => theme.colors.positive[500]};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.negative[100]};
    color: ${({ theme }) => theme.colors.negative[500]};
  }

  .Toastify__toast--error button path {
    fill: ${({ theme }) => theme.colors.negative[500]};
  }
`;

const zoom = cssTransition({
  enter: "Toastify__slide-enter--top-center",
  exit: "Toastify__slide-exit--top-center",
  duration: 200
});
