import css from "@styled-system/css";
import flatten from "flat";
import { fontWeights } from "src/nhweb-shared/theme";
import { FontSizeProps, FontWeightProps, LineHeightProps } from "styled-system";

const textStylesDefinitions = {
  heading: {
    large: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 6,
        lineHeight: "36px"
      }
    },
    normal: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 5,
        lineHeight: "32px"
      }
    },
    small: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 4,
        lineHeight: "28px"
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: 4,
        lineHeight: "28px"
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: 4,
        lineHeight: "28px"
      }
    }
  },
  body: {
    large: {
      bold: {
        fontWeight: fontWeights.bold,
        fontSize: 3,
        lineHeight: "24px"
      },
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 2,
        lineHeight: "24px"
      },
      regular: {
        fontWeights: fontWeights.regular,
        fontSize: 2,
        lineHeight: "24px"
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: 2,
        lineHeight: "24px"
      }
    },
    normal: {
      bold: {
        fontWeight: fontWeights.bold,
        fontSize: 1,
        lineHeight: "24px"
      },
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 1,
        lineHeight: "24px"
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: 1,
        lineHeight: "24px"
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: 1,
        lineHeight: "24px"
      }
    },
    small: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 0,
        lineHeight: "18px"
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: 0,
        lineHeight: "18px"
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: 0,
        lineHeight: "18px"
      }
    }
  },
  textlink: {
    large: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 2,
        lineHeight: "24px"
      }
    },
    normal: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 1,
        lineHeight: "24px"
      }
    },
    small: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: 0,
        lineHeight: "18px"
      }
    },
    xsmall: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: "11px",
        lineHeight: "18px"
      }
    }
  },
  footnote: {
    xsmall: {
      medium: {
        fontWeight: fontWeights.medium,
        fontSize: "11px",
        lineHeight: "18px"
      },
      regular: {
        fontWeight: fontWeights.regular,
        fontSize: "11px",
        lineHeight: "18px"
      },
      light: {
        fontWeight: fontWeights.light,
        fontSize: "11px",
        lineHeight: "18px"
      }
    }
  }
} as const;

type TextStyle =
  | "body-large-bold"
  | "body-large-medium"
  | "body-large-regular"
  | "body-large-light"
  // body-normal
  | "body-normal-bold"
  | "body-normal-medium"
  | "body-normal-regular"
  | "body-normal-light"
  // body-small
  | "body-small-medium"
  | "body-small-regular"
  | "body-small-light"
  // heading-large
  | "heading-large-medium"
  | "heading-large-regular"
  // heading-normal
  | "heading-normal-medium"
  | "heading-normal-regular"
  // heading-small
  | "heading-small-medium"
  | "heading-small-regular"
  | "heading-small-light"
  // footnote-xsmall
  | "footnote-xsmall-medium"
  | "footnote-xsmall-regular"
  | "footnote-xsmall-light"
  // textlink
  | "textlink-large-medium"
  | "textlink-normal-medium"
  | "textlink-small-medium"
  | "textlink-xsmall-medium";

export interface TextStyleProps {
  textStyle?: TextStyle | string;
}

type TextStyleTypographyProps = FontWeightProps &
  FontSizeProps &
  LineHeightProps;

const textStyles = flatten(textStylesDefinitions, {
  delimiter: "-",
  maxDepth: 3
}) as Record<TextStyle, TextStyleTypographyProps>;

export const textStyle = ({ textStyle }: TextStyleProps) => {
  if (!textStyle) return;

  const computedTextStyles = css(textStyles[textStyle]);

  return computedTextStyles;
};
