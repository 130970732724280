import { handleActions } from "redux-actions";

import { loadConfigVariablesSuccess } from "actions";

const initialState = {};

export const getConfigVars = state => state;

export default handleActions(
  {
    [loadConfigVariablesSuccess]: (state, { payload }) => ({
      ...state,
      ...payload
    })
  },
  initialState
);
