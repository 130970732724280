import { LOAD_CONFIG_VARIABLES_SUCCESS } from "actions";
import { combineEpics, ofType } from "redux-observable";
import { logger } from "utils";
import { ignoreElements, map } from "rxjs/operators";

const initLoggingEpic = action$ =>
  action$.pipe(ofType(LOAD_CONFIG_VARIABLES_SUCCESS)).pipe(
    map(() => {
      logger.init();
    }),
    ignoreElements()
  );

export default combineEpics(initLoggingEpic);
