import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export interface PortalProps {
  children?: ReactNode;
  container?: HTMLElement;
  node?: HTMLElement;
}

export const Portal = ({
  node,
  container = document.body,
  children
}: PortalProps) => {
  const element = useRef(node ?? document.createElement("div"));
  const dynamic = useRef(element.current.parentElement === null);

  useEffect(() => {
    const child = element.current;
    const isDynamic = dynamic.current;

    if (isDynamic) {
      container.appendChild(child);
    }

    return () => {
      if (isDynamic) {
        container.removeChild(child);
      }
    };
  }, [node, container]);

  return createPortal(children, element.current);
};
