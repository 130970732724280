import { border } from "polished";
import React, { forwardRef } from "react";
import { isDevelopmentBuild } from "src/utils";

import { BoxBasedComponentProps } from "../Box";
import { Flex } from "../Flex";

export type CardProps = BoxBasedComponentProps<
  "div",
  {
    hoverable?: boolean;
  }
>;

export const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ hoverable = true, _hover = hoverStyles, ...props }, ref) => (
    <Flex
      {...border("1px", "solid", "borders")}
      flexDirection={"column"}
      borderRadius={"rounder"}
      bg={"white"}
      ref={ref}
      wordWrap={"break-word"}
      {...props}
      _hover={hoverable ? _hover : undefined}
    />
  )
);

if (isDevelopmentBuild) {
  Card.displayName = "Card";
}

export const hoverStyles = {
  borderColor: "primary.500"
};
