import React from "react";
import styled from "styled-components";
import { height, padding } from "styled-system";

import Flex from "components/flex";
import Link from "components/link";
import NeighborsLogo from "components/icons/neighbors";
import Text from "components/text";

import MobileNav from "./mobile-nav";

const StyledNavigation = styled.nav`
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  border-bottom: ${props => props.theme.borders[1]};
  display: flex;
  ${height}
  ${padding}
`;

const NavItem = styled(Flex)`
  align-items: center;
  border-bottom: 4px solid transparent;
  border-top: 4px solid transparent;
  color: ${props => props.theme.colors.neutral500};
  cursor: pointer;
  height: 100%;
  &:hover {
    div {
      color: ${props => props.theme.colors.primary500};
    }
    border-bottom-color: ${props => props.theme.colors.primary500};
  }
`;

const Navigation = ({
  handleOpenLinkNewTab,
  isMobileScreen,
  neighborsLogoUrl
}) =>
  isMobileScreen ? (
    <StyledNavigation height={"50px"}>
      <MobileNav handleOpenLinkNewTab={handleOpenLinkNewTab} />
    </StyledNavigation>
  ) : (
    <StyledNavigation height={"64px"}>
      <Link to={neighborsLogoUrl()} data-cy={"logo"}>
        <Flex
          alignItems={"center"}
          pointer={true}
          px={"40px"}
          display={{ sm: "none", md: "flex" }}
        >
          <NeighborsLogo />
        </Flex>
      </Link>
      <NavItem onClick={handleOpenLinkNewTab("http://ring.com")}>
        <Text fontWeight={2} mx={1}>
          {"Ring.com"}
        </Text>
      </NavItem>
    </StyledNavigation>
  );

export default Navigation;
