import React from "react";
import type { ReactImageGalleryItem } from "react-image-gallery";
import { Box } from "src/nhweb-shared/components/Box";
import {
  EventCardMedia,
  EventMediaType
} from "src/nhweb-shared/components/EventCard";
import { Flex } from "src/nhweb-shared/components/Flex";
import { Icon } from "src/nhweb-shared/components/Icon";

export const EventGalleryMediaThumbnail = ({
  description,
  original
}: ReactImageGalleryItem) => {
  return (
    <Box mb={2} cursor={"pointer"} borderRadius={"rounder"} overflow={"hidden"}>
      {description === "video" && (
        <Flex
          height={"100%"}
          justifyContent={"center"}
          position={"absolute"}
          width={1}
          zIndex={1}
        >
          <Flex
            alignItems={"center"}
            alignSelf={"center"}
            bg={"white"}
            borderRadius={"circle"}
            justifyContent={"center"}
            size={24}
          >
            <Icon color={"primary.500"} name={"play"} ml={1} size={12} />
          </Flex>
        </Flex>
      )}
      <EventCardMedia
        src={original}
        controls={false}
        type={description as EventMediaType}
        objectFit={"cover"}
      />
    </Box>
  );
};
