import React from "react";
import { plural } from "src/nhweb-shared/utils";
import { IntrinsicElementsKeys } from "styled-components";

import { Box, BoxBasedComponentProps } from "../Box";

export type PluralProps<T extends IntrinsicElementsKeys> =
  BoxBasedComponentProps<
    T,
    {
      count: number;
      children: string;
      inclusive?: boolean;
    }
  >;

export const Plural = <T extends IntrinsicElementsKeys>({
  count,
  children,
  inclusive,
  ...props
}: PluralProps<T>) => {
  const text = plural(count, children, inclusive);

  return <Box {...props}>{text}</Box>;
};
