import React from "react";

import { EventOwnerHeaderProps } from "./EventOwnerHeader";
import { OwnerHeaderBase } from "./OwnerHeaderBase";
import {
  getCommentOwnerHeaderDisplayUsername,
  getEventOwnerHeaderDisplayUsername
} from "./utils";
import { Link } from "../Link";

export interface CommentOwnerHeaderProps extends EventOwnerHeaderProps {
  isOwnedEvent: boolean;
  publisherUsername: string | undefined;
}

export const CommentOwnerHeader = ({
  agencyId,
  avatarUrl,
  description,
  logoUrl,
  isOwned,
  isOwnedEvent,
  ownerUsername,
  publisherUsername,
  ...props
}: CommentOwnerHeaderProps) => {
  const getDisplayUsername = isOwnedEvent
    ? getCommentOwnerHeaderDisplayUsername
    : getEventOwnerHeaderDisplayUsername;

  const displayUsername = getDisplayUsername({ isOwned, ownerUsername });

  return (
    <OwnerHeaderBase
      {...props}
      agencyId={agencyId}
      avatarUrl={avatarUrl}
      description={description}
      logoUrl={logoUrl}
      isOwned={isOwned}
    >
      {publisherUsername ? (
        <Link href={`/agencies/${agencyId}`} target={"_blank"}>
          {publisherUsername}
        </Link>
      ) : (
        displayUsername
      )}
    </OwnerHeaderBase>
  );
};
