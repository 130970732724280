import React from "react";
import styled from "styled-components";
import { height, width } from "styled-system";

const StyledSvg = styled.svg`
  ${height}
  ${width}
`;

const Download = ({
  fill = "none",
  height = "28",
  viewBox = "0 0 16 17",
  width = "28",
  ...props
}) => (
  <StyledSvg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    {...props}
  >
    <path
      fillRule={"evenodd"}
      clipRule={"evenodd"}
      d={
        "M7.49998 1.41992C7.49998 1.14392 7.72398 0.919922 7.99998 0.919922C8.27598 0.919922 8.49998 1.14392 8.49998 1.41992V11.9199L12.32 9.00992C12.404 8.94592 12.51 8.90692 12.625 8.90692C12.903 8.90692 13.128 9.13192 13.128 9.40992C13.128 9.57292 13.051 9.71692 12.931 9.80892L12.93 9.79992L7.99998 13.5499L3.19998 9.80992C3.06698 9.71892 2.98098 9.56792 2.98098 9.39592C2.98098 9.11992 3.20498 8.89592 3.48098 8.89592C3.60698 8.89592 3.72298 8.94292 3.81098 9.01992L7.49998 11.9199V1.41992ZM14.58 15.9999H1.41998C1.14398 15.9999 0.919983 16.2239 0.919983 16.4999C0.919983 16.7759 1.14398 16.9999 1.41998 16.9999H14.58C14.856 16.9999 15.08 16.7759 15.08 16.4999C15.08 16.2239 14.856 15.9999 14.58 15.9999ZM7.49998 1.41992H8.49998C8.49998 1.14392 8.27598 0.919922 7.99998 0.919922C7.72398 0.919922 7.49998 1.14392 7.49998 1.41992Z"
      }
      fill={"#1998D5"}
    />
  </StyledSvg>
);

export default Download;
