import React, { useEffect, useRef, useState } from "react";

import Flex from "components/flex";
import Text from "components/text";

const Card = ({
  children,
  icon,
  title,
  showSeeMore,
  action,
  border,
  borderBottom,
  borderTop,
  borderRadius,
  mb,
  mbTitle
}) => {
  const ref = useRef(null);
  const [showMore, setShowMore] = useState(!showSeeMore);
  const [hasTextOverflown, setHasTextOverflown] = useState(false);

  const toggleSetShowMore = () => setShowMore(!showMore);

  useEffect(() => {
    if (ref.current) {
      setHasTextOverflown(ref.current.offsetHeight < ref.current.scrollHeight);
    }
  }, []);

  return children ? (
    <Flex
      border={border || { sm: 0, md: 1 }}
      borderBottom={borderBottom || { sm: 1 }}
      borderTop={borderTop || "none"}
      borderRadius={borderRadius || { sm: 0, md: 1 }}
      mb={mb || 2}
      px={"24px"}
      py={"20px"}
      width={1}
      flexShrink={0}
    >
      {icon ? (
        <Flex
          justifyContent={"center"}
          width={"32px"}
          height={"32px"}
          flexShrink={0}
        >
          {icon}
        </Flex>
      ) : null}
      <Flex flexDirection={"column"} ml={icon ? 3 : 0} width={"100%"}>
        <Flex width={"100%"}>
          <Flex flex={1} mb={mbTitle || 0}>
            <Text fontSize={2} fontWeight={2}>
              {title}
            </Text>
          </Flex>
          {action ? action : null}
        </Flex>
        <Flex
          flexDirection={"column"}
          maxHeight={showMore ? "none" : "44px"}
          overflow={"hidden"}
          mt={1}
          ref={ref}
        >
          {children}
        </Flex>
        {hasTextOverflown && showSeeMore && (
          <Text
            color={"primary500"}
            fontSize={1}
            fontWeight={2}
            mt={1}
            onClick={toggleSetShowMore}
            pointer
          >
            {showMore ? "See Less" : "See More"}
          </Text>
        )}
      </Flex>
    </Flex>
  ) : null;
};

export default Card;
