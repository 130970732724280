import { CommentOwnerHeaderProps } from "./CommentOwnerHeader";
import { EventOwnerHeaderProps } from "./EventOwnerHeader";

export const getCommentOwnerHeaderDisplayUsername = ({
  isOwned,
  ownerUsername
}: Pick<CommentOwnerHeaderProps, "isOwned" | "ownerUsername">) => {
  return isOwned ? `${ownerUsername} (Posted this Alert)` : ownerUsername;
};

export const getEventOwnerHeaderDisplayUsername = ({
  isOwned,
  ownerUsername
}: Pick<EventOwnerHeaderProps, "isOwned" | "ownerUsername">) => {
  return isOwned ? `Me (${ownerUsername})` : ownerUsername;
};
